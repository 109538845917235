.react-fine-uploader-total-progress-bar-container
  margin-top: 0.5rem
  border: none
  border-radius: pxToRem(2)
  height: 0.5rem
  background: $catskill-white

  .react-fine-uploader-total-progress-bar
    margin: 0 !important
    background: $ui !important
    height: 0.5rem
    border-radius: pxToRem(2)
