.audit-trail
  width: 42rem
  padding: 0


// header
.audit-trail__header
  display: flex
  background: $white
  justify-content: space-between
  position: sticky
  z-index: 10
  top: 0
  line-height: 1
  padding: 2rem 2rem 0

.audit-trail__header__text
  font-size: pxToRem(16)
  padding: 0

.audit-trail__header__link
  padding: 0


// item
.audit-trail__item
  padding: 0 2rem
  font-size: pxToRem(16)

  td
    border-color: $color

.audit-trail__item__collapse
  padding: 0

  svg
    position: absolute
    right: 2rem

.audit-trail__item__expand
  padding: 0

  td
    border-color: $border


// column
.audit-trail__column
  padding: 1rem 0
  word-break: break-all
  word-break: break-word
  white-space: pre-wrap

  // material override
  &:last-child
    padding-right: 0

.audit-trail__column__date,
.audit-trail__column__name
  width: 33%

.audit-trail__column__description
  width: 32%

.audit-trail__column__rejection
  width: 100%
