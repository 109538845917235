#no_search_results_message
  margin: 0 auto pxToRem(25)
  max-width: pxToRem(892)

#export_options_form
  .editableData-editLink-save
    margin-top: pxToRem(25)

  .export_options__xlsx
    width: pxToRem(115)

#display_options_form
  margin: 0 auto
  max-width: pxToRem(940)
  .editableData-editLink-save
    margin-bottom: pxToRem(25)

  .protocol-defaults__header
    display: inline-block
    max-width: 85%
    text-overflow: ellipsis
    overflow: hidden
    vertical-align: middle

// Saved searches
.savedSearchesOrCollections
  .dataTable
    .mainRow
      td, th
        padding-top: pxToRem(20)
        padding-bottom: pxToRem(20)
    .savedSearchesOrCollections-molecules
      width: pxToRem(120)
    .savedSearchesOrCollections-favorite
      width: pxToRem(16)
      padding: pxToRem(10) !important
      .actionIcon
        width: pxToRem(16)
        height: pxToRem(16)
      a
        display: inline-block
      a:hover .actionIcon img
        top: -pxToRem(16)
      .savedSearchesOrCollections-star-on
        display: none
      .savedSearchesOrCollections-star-off
        display: block
    .favorite .savedSearchesOrCollections-favorite
      .savedSearchesOrCollections-star-on
        display: block
      .savedSearchesOrCollections-star-off
        display: none
    #name
      max-width: pxToRem(400)
    #vault_name
      width: pxToRem(125)
    #project_name
      width: pxToRem(125)
    #owner_name
      width: pxToRem(125)
    #created_at
      width: pxToRem(100)
    .mainRow td.savedSearchesOrCollections-run
      width: pxToRem(80)
      text-align: left
      padding-top: pxToRem(10)
    .icon-8
      margin: 0 pxToRem(2) 0 -pxToRem(13)
    .icon-16
      vertical-align: -pxToRem(3)
    .simple
      margin: 0 0 0 pxToRem(36)
      td, th
        font-size: pxToRem(11)
        color: $dim-gray
      td
        padding: 0 0 pxToRem(7) 0
      th
        padding: 0 pxToRem(7) pxToRem(7) 0
      .structure
        display: block
        margin: 0 0 pxToRem(5) 0
      p.criterion, p.operator
        margin: 0 0 0 0
        line-height: 1.2rem
      .criteria_set
        margin: 0 0 0 pxToRem(10)
      p.operator
        font-style: italic
        font-weight: bold
        font-size: pxToRem(10)
        color: $dusty-gray
    .savedSearchesOrCollections-displayOptions
      margin: pxToRem(15) pxToRem(20) 0 pxToRem(20)
      border-top: pxToRem(1) dotted
      border-top-color: $mercury
      padding-top: pxToRem(15)
      h5
        margin: 0 0 pxToRem(15) 0
      .simple
        margin: 0 0 0 pxToRem(20)
        th
          white-space: normal
          // pxToRem(250) allows protocol names of 40 characters to be displayed in a single line: the vast majority are 40 characters or less, with plenty from 30 to 40, so this seems like a good cutoff
          width: $sidebar-width
      table.dataTable
        top: 0
      tr
        &:first-child
          th, td
            padding-top: pxToRem(20)
