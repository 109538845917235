.hitColor
  margin: 0 0 pxToRem(3) 0
  padding: pxToRem(5)
  color: $black
  font-size: pxToRem(11)
  background: $dim-gray
  white-space: nowrap

span.hitColor
  display: inline-block

// NOTE: these colors (green, lightGreen, etc.) are in the ruby code and the database, so don't change them here without changing them there

.hitColor-green
  background: $limeade_dark !important
  color: $white

.hitColor-lightGreen
  background: $la-rioja !important

.hitColor-yellow
  background: $turbo !important

.hitColor-orange
  background: $tango !important

.hitColor-red
  background: $red_dark !important
  color: $white
