.mappingSummary
  width: auto
  img
    margin: -pxToRem(2) 0 0 0
  tr
    td.arrow
      width: pxToRem(16)
    td.mappingDescription
      color: $dusty-gray
      font-weight: bold
      .options
        color: $dusty-gray
        font-weight: normal
  tr.mapped
    td.arrow
      background: transparent url("../images/cdd30/icons/arrow_right.png") no-repeat 50% 0.6rem
    td.mappingDescription
      color: $mine-shaft
