#mapper
  width: pxToRem(960)

.composeMixtures-outer
  display: inline-block
  position: static !important
  vertical-align: top

.composeMixtures-destination
  border: pxToRem(3) solid $lochmara

.composeMixtures-rowidx
  background-color: $alabaster
  border: pxToRem(1) solid $concrete
  height: pxToRem(20)
  text-align: center

.composeMixtures-column
  float: left
  width: pxToRem(150)
  cursor: pointer

.composeMixtures-header
  height: 100%

.composeMixtures-firstrow
  height: pxToRem(35) !important
  padding-top: pxToRem(9)
  font-size: pxToRem(11)
  font-weight: bold
  text-align: center
  background: $alabaster

.composeMixtures-secondrow
  color: $mine-shaft !important
  font-weight: bold
  background-color: $concrete

.composeMixtures-lastrow
  border-bottom: pxToRem(1) solid $concrete

.composeMixtures-selectedrow
  color: $tropical-blue !important
  background-color: $lochmara
  border-top: pxToRem(1) solid $curious-blue !important
  cursor: auto

.composeMixtures-descr
  font-weight: normal

.composeMixtures-dest-sel
  display: inline-block
  margin-left: 0.5rem
  padding: pxToRem(4)
  color: $white
  background-color: $lochmara

.composeMixtures-dest-unsel
  display: inline-block
  margin-left: 0.5rem
  padding: pxToRem(4)
  color: $mine-shaft
  background-color: $concrete
  cursor: pointer

.composeMixtures-destination-hbox
  display: flex
  gap: 3rem

.composeMixtures-destination-type
  flex-grow: 0
  align-self: flex-start

.composeMixtures-destination-dest
  flex-grow: 0
  align-self: flex-start
  text-align: center

.composeMixtures-destination-template
  flex-grow: 1
  align-self: center
  text-align: right

.composeMixtures-destination-grid
  display: grid
  margin-top: 0.5rem
  gap: 0.5rem

.composeMixtures-destination-otherwrap
  display: flex
  align-items: center
  padding: 0.5rem 0 0 0.5rem
  gap: 0.5rem

.composeMixtures-destination-otherinput
  width: calc(100% - 1.2rem)
  margin-left: 0.2rem
  flex-grow: 1

.composeMixtures-destination-otherinput-bad
  background-color: $sandy-beach !important

.composeMixtures-destination-otherhelp
  flex-grow: 0

.composeMixtures-destination-otherquestion
  display: inline-block
  width: 1.5rem
  height: 1.5rem
  padding-top: 0.3rem
  color: $regent-gray
  background-color: $black_10
  border-radius: 50%
.composeMixtures-destination-otherquestion:hover
  text-decoration: none

.composeMixtures-button
  padding: pxToRem(6) pxToRem(12)
  font-size: pxToRem(12)
  line-height: 1rem
  font-weight: bold
  color: $white
  background-color: $ui
  border-radius: pxToRem(2)
  border: none
  &:hover
    text-decoration: none
    color: $white
    background-color: $denim
  &:active
    background-color: $denim

.composeMixtures-thumbnail
  cursor: pointer
  background-color: transparent
  border: pxToRem(1) solid transparent
.composeMixtures-thumbnail:hover
  background-color: $concrete
.composeMixtures-thumbnail-fixed
  border: pxToRem(1) solid $cerulean !important

.composeMixtures-popup
  display: none
  position: fixed
  z-index: 9999
  background-color: $white
  border: pxToRem(1) solid $silver
  box-shadow: 0 pxToRem(2) pxToRem(4) $box-shadow

.composeMixtures-popcent
  display: flex
  width: 100%
  height: 100%
  justify-content: center
  align-items: center

.composeMixtures-continue-hbox
  display: flex
  margin-bottom: 2rem
  gap: 0.5rem
  justify-content: space-between
  align-items: center

.composeMixtures-preview-table
  border-collapse: collapse

.composeMixtures-preview-title
  background-color: $alto
  text-align: center
  vertical-align: middle
  font-weight: bold
  padding: 0.2rem
  border: pxToRem(1) solid $silver

.composeMixtures-preview-index
  background-color: $alto
  text-align: center
  vertical-align: middle
  font-weight: bold
  border: pxToRem(1) solid $silver
  padding: 0.5rem

.composeMixtures-preview-content
  background-color: $alabaster
  text-align: center
  vertical-align: middle
  border: pxToRem(1) solid $silver
  padding: 0.2rem

.composeMixtures-preview-fielditem
  margin: 0.1rem 0.2rem 0.1rem 0.2rem
  overflow-wrap: anywhere

.composeMixtures-progress1
  margin: 0.5rem 0 0.5rem 0
  background-color: $lochmara
  border-radius: pxToRem(5)
  text-align: left

.composeMixtures-progress2
  display: inline-block
  background-color: $white
  border-radius: pxToRem(2)

.composeMixtures-preview-grid
  display: grid

.composeMixtures-preview-under
  grid-area: 1 / 1

.composeMixtures-preview-over
  grid-area: 1 / 1
  justify-self: end
  align-self: start
  text-align: right
  pointer-events: none

.composeMixtures-preview-info
  grid-area: 1 / 1
  justify-self: end
  align-self: end
  text-align: right
  pointer-events: none
  font-weight: bold
  color: $denim

.composeMixtures-default-units
  margin-left: 0.5rem
  margin-bottom: -1rem

.composeMixtures-hiddendata
  display: none
