#messageList
  .messageList-subject
    .actionIcon
      margin: pxToRem(1) pxToRem(8) 0 -pxToRem(18)
  .icon-16
    margin: 0 0 0 pxToRem(2)
  .lastReply, .replyCount
    white-space: nowrap


// New message
#message-new, #new_reply
  .form th
    width: pxToRem(120)
  .wideForm
    .input-text, textarea
      width: 100%
  table.attachments
    margin: 0
    padding: 0
    width: pxToRem(425)
    border: 0
    top: pxToRem(4)
    td
      padding: pxToRem(5) 0
      background: $white
    .filePreview
      padding-bottom: pxToRem(5)


.notificationRecipients
  label
    margin: 0 0 pxToRem(8) 0
    float: left
    width: 33%


// Message
#messagesDashboard
  float: left
  width: 100%

.message
  padding: pxToRem(24) pxToRem(24) pxToRem(9) pxToRem(24)
  border: pxToRem(1) solid $gallery
  color: $shuttle-gray
  background: $alabaster
  border-radius: pxToRem(2)
  h2, h3, h4, h5, strong
    color: $shuttle-gray

  #message-content
    color: $amaranth


.message-close
  float: right
  position: relative
  top: -pxToRem(14)
  right: -pxToRem(14)

.container
  .message
    margin: 0 0 pxToRem(20) 0
    padding: pxToRem(17) pxToRem(24) pxToRem(17) pxToRem(20)
    p:last-child
      margin: 0
      padding: 0
  .message-close
    top: -pxToRem(4)


// Error, warning, success, global messages, system announcement
.errorMessage,
.warningMessage,
.successMessage
  margin: 0 0 pxToRem(20) 0
  padding: pxToRem(17) pxToRem(20) pxToRem(5) pxToRem(20)

.search-details .warningMessage
  margin: 0
  padding: pxToRem(17) pxToRem(20) pxToRem(17) pxToRem(20)

.errorMessage
  color: $guardsman-red
  background: $bg_warning

.warningMessage
  color: $burnt-orange
  background: $serenade
  &.searchSection
    padding: pxToRem(24) pxToRem(24) pxToRem(9) pxToRem(24)

.successMessage
  color: $limeade
  background: $rice-flower

.noDataMessage
  margin: 0 0 pxToRem(15) 0
  color: $regent-gray
  background: $aqua-haze
  border-radius: pxToRem(2)
  border-width: pxToRem(1) 0
  padding: pxToRem(15)
  font-size: pxToRem(14)
  text-align: center
  font-style: italic

.globalMessage
  margin: 0 0 pxToRem(10) 0
  padding: pxToRem(15) pxToRem(17)
  background: $alabaster
  color: $shuttle-gray
  border: pxToRem(1) solid $gallery
  border-radius: pxToRem(2)
  .separator
    color: $gallery
  ul
    margin-bottom: -pxToRem(5)

.globalMessage-success
  margin: 0 0 pxToRem(10) 0
  padding: pxToRem(15) pxToRem(17)
  color: $limeade
  background: $rice-flower
  border: pxToRem(1) solid $feijoa
  border-radius: pxToRem(2)
  .separator
    color: $sushi

.globalMessage-error
  margin: 0 0 pxToRem(10) 0
  padding: pxToRem(15) pxToRem(17)
  color: $guardsman-red
  background: $bg_warning
  border: pxToRem(1) solid $petite-orchid
  border-radius: pxToRem(2)
  .separator
    color: $roman

.formFieldInvalidInput
  color: $guardsman-red
