.EditProtocolReadoutDefinitionDialog
  .picklist-field
      position: relative
      .pick-list-edit
        margin-top: -.2rem
        margin-left: -4.5rem

  .formula-editor-legacy-wrapper
    width: 100%
    textarea
      width: calc(100% - 3rem) !important

  .dose-response-constraint-row
    margin-right: 1rem
    .element-typography:first-child
      margin-left: 2rem
    .element-typography
      max-width: 8rem
    .element-select
      max-width: 6rem
    .element-number
      max-width: 8rem
    .dsr-calc-label
      max-width: 14rem
    .label-to
      text-align: center
      max-width: 3rem

  .normalization-description
    text-align: right
    margin-right: 1rem

  .protocol_conditions-help-button
    img
      margin-top: .5rem
    p
      line-height: 1rem

  .add-icon
    img
      width: 1rem
      margin-right: .4rem
      vertical-align: -.1rem

  .editable-normalization-checkbox-row
    .element-text
      margin-right: 1rem

  .element-number
    max-width: 8rem

  .dose-response-select-axis
    margin-left: 1rem
    margin-rightt: 1rem
    max-width: 21rem

