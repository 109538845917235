.uploaded-file
  margin-bottom: -pxToRem(8)

  .office-icon
    margin-bottom: pxToRem(14)
    top: pxToRem(7)
    position: relative

  &:hover

    .uploaded-file--hover
      display: flex

    .uploaded-file--no-preview
      border-color: $color_brand

  canvas
    position: relative !important

.uploaded-file__link
  line-height: 0

.remove--wrapper
  top: 0
  right: 0
  position: absolute
  height: pxToRem(42)

.uploaded-file--actions-left
  left: pxToRem(1)
  border-bottom-right-radius: pxToRem(20)

.uploaded-file--actions
  right: pxToRem(1)
  border-bottom-left-radius: pxToRem(20)

.uploaded-file--preview ~ .uploaded-file--actions,
.uploaded-file--preview ~ .uploaded-file--actions-left
  background-color: $white

.uploaded-file--actions,
.uploaded-file--actions-left
  display: table
  top: pxToRem(1)
  position: absolute
  z-index: 3
  background-color: $alabaster

  > span:nth-of-type(1)
    .remove,
    .resize-option,
    .office-viewer--link,
    .uploaded-file--link,
    .download
      padding-left: pxToRem(12)

  > span
    display: table-cell
    position: relative

  .remove,
  .resize-option,
  .office-viewer--link,
  .uploaded-file--link,
  .download
    right: auto
    top: auto
    position: relative
    padding: pxToRem(12) pxToRem(6) pxToRem(12) pxToRem(6)
    font-size: pxToRem(16)
    color: $shuttle-gray
    border-radius: pxToRem(20)

  .office-viewer--link
    padding-bottom: pxToRem(11)

    img
      height: pxToRem(14)
      position: relative
      top: pxToRem(1.5)

  .office-viewer--link:hover img
    filter: drop-shadow(pxToRem(1) pxToRem(1) pxToRem(1) $ui_hover) drop-shadow(pxToRem(1) -pxToRem(1) pxToRem(1) $ui_hover) drop-shadow(-pxToRem(1) pxToRem(1) pxToRem(1) $ui_hover) drop-shadow(-pxToRem(1) -pxToRem(1) pxToRem(1) $ui_hover)

  .remove,
  .download
    padding-right: pxToRem(12)

.uploaded-structure-file--preview
  border-radius: pxToRem(2)
  min-width: pxToRem(880)

.uploaded-structure-file--structure
  padding: pxToRem(10)
  border-radius: pxToRem(2)
  border: pxToRem(1) solid transparent
  box-sizing: border-box
  object-fit: contain
  min-width: pxToRem(270)
  max-width: 100%
  position: relative
  overflow: hidden

  img, svg
    width: pxToRem(858) !important

  svg
    max-height: pxToRem(246)

  &:hover
    border: pxToRem(1) solid $color_brand

.uploaded-structure-file--border
  border: pxToRem(1) solid $border

.uploaded-structure-file--warning
  font-weight: bold
  display: block

.uploaded-structure-file--table
  margin: pxToRem(10)

  & td
    white-space: nowrap

.header__row
  border: none

  td
    border: none

.register-link-top-left
  left: pxToRem(1)
  top: pxToRem(4)
  position: absolute

.register
  left: 0
  padding: pxToRem(6)
  font-size: pxToRem(16)
  border-bottom-right-radius: pxToRem(10)

.register:not(.disabled)
  background-color: $alabaster

// resize-option
.size-small
  position: relative
  top: pxToRem(2)
  margin-top: pxToRem(8)
  margin-bottom: -pxToRem(6)

  .inline-container__pdb-viewer,
  canvas,
  iframe
    height: pxToRem(248) !important

  .inline-container__pdb-viewer,
  canvas,
  .uploaded-file--image,
  iframe
    width: pxToRem(248) !important

.size-half
  position: relative
  top: pxToRem(2)
  margin-top: pxToRem(8)
  margin-bottom: -pxToRem(6)

  .inline-container__pdb-viewer,
  canvas,
  iframe
    height: pxToRem(400) !important

  .inline-container__pdb-viewer,
  canvas,
  .uploaded-file--image,
  iframe
    width: pxToRem(400) !important

.size-large
  position: relative
  top: pxToRem(2)
  margin-top: pxToRem(8)
  margin-bottom: -pxToRem(6)

  .inline-container__pdb-viewer,
  canvas,
  iframe
    height: pxToRem(878) !important

  .inline-container__pdb-viewer,
  canvas,
  .uploaded-file--image,
  iframe
    width: pxToRem(878) !important
