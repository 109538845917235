.StructureEditorDialog
  .insertBar
    display: flex
    justify-content: flex-end
    margin-right: pxToRem(10)
    // to match MUI v4 dimensions
    min-height: pxToRem(28.27)
    .spinner
      position: absolute
      right: pxToRem(30)
      top: pxToRem(5)
      width: pxToRem(16)
      height: pxToRem(16)
    .material-input
      width: pxToRem(400)

    .not-loading
      .spinner
        visibility: hidden

  .buttons-react
    margin-bottom: 0

  .btn-expand-toggle
    margin-left: pxToRem(20)

  .ai-link
    margin-left: 1rem
    white-space: nowrap

  iframe
    width: 100%
    height: 100%
    border: 0

