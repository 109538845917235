.editableData

  .dataTable

    .actions
      vertical-align: middle

  .childWithTooltip
    display: inline-block! important

.editableData-editLink
  float: right

.editableData-editLink-save
  margin: pxToRem(10) 0
  float: right

  .orcancel
    padding-top: pxToRem(4)
