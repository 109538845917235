// Add Molecule to Collection

#molecule-newCollection-edit, #addToCollection-light-box
  margin: 0 0 pxToRem(25) 0
  table.simple
    margin: 0
    th
      width: pxToRem(60)
    td
      background: transparent
  #molecule-newCollection-new
    label
      margin-left: pxToRem(5)
      font-weight: bold
    .input-checkbox
      margin-left: pxToRem(3)
      vertical-align: text-bottom
