// TODO dedupe with EditProjectsDialog

.InventorySearchTable
  .vault-permission-label
    text-align: right
    padding-right: pxToRem(8)

  .no-padding
    padding: 0 !important

  .element-tree
    overflow-x: hidden
    li
      width: pxToRem(714)

  .element-tree-item
    height: 4rem

  .disabled-delete
    color: rgba(0,0,0,.5)

  .user-membership-row
    min-height: pxToRem(40)

  .element-tree-item
    pointer-events: none

  .depleted-row
    text-decoration: line-through
    font-style: italic
    cursor: default !important

  .sticky-header
    position: sticky
    top: 0
    background-color: rgba(255, 255, 255, 1)
    z-index: 1

  .inventory_search__molecule_structure__borderless
    border: none !important
    box-shadow: none
    background: rgb(255, 255, 255)
    padding-top: pxToRem(10)
    img
      max-width: pxToRem(125)
