.counter
  display: flex
  h3
    margin: 0 pxToRem(5)

.header-container
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  margin-bottom: pxToRem(10)
  .floatRight
    margin: auto 0

.edit-account-popup, .new-account-popup
  display: none
  background: url("../images/cdd30/layout/overlay-20.png") repeat top left
  padding: pxToRem(4)
  min-width: pxToRem(430)
  position: fixed
  left: 50%
  transform: translate(-50%, -50%)
  z-index: 10
  th
    padding: pxToRem(10) 0
  input
    margin: 0
    width: $sidebar-width
    font-size: pxToRem(12)
    border: pxToRem(1) solid $mercury
    background: $white
    padding: pxToRem(4)
    color: $shuttle-gray
    border-radius: pxToRem(2)
  #edit-account-content, #new-account-content
    background-color: $white
    padding: pxToRem(20)

    h2
      margin-top: pxToRem(0)
    .wideForm
      padding-bottom: pxToRem(40)
    form.access-form
      table
        padding: 0 pxToRem(20)
        th
          padding-right: pxToRem(20)

    .buttons-right
      margin-top: pxToRem(20)

    .orcancel
      padding-top: pxToRem(6)

.edit-account-popup
  top: 50%
.new-account-popup
  top: 20%
  min-width: pxToRem(500)
  transform: translate(-50%, -20%)

.admin
  #user-new
    .vault
      .account
        padding-top: .75rem
        label
          padding-right: .75rem
