.ProtocolReadoutDefinitionsView
  .createNewReadoutDefinition
    float: right
    padding-bottom: pxToRem(10)
    margin-top: -pxToRem(10)

  tr
    background-color: white!important

    &:hover
      background-color: $bg!important
