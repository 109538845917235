#shareDialog
  bottom: pxToRem(130)
  position: relative
  right: pxToRem(615)
  text-align: left
  white-space: normal
  color: $mine-shaft
  .shareDialog-link input
    width: 98%
  .popup-inner1
    width: pxToRem(700)
  .popup-inner2
    width: pxToRem(670)
    .section
      margin: 0
    .container
      border: pxToRem(1) solid $mercury
      .container-content
        padding: pxToRem(24)
        .container-content-inner
          margin: 0
    th
      width: pxToRem(45)
      white-space: normal
      padding-top: pxToRem(5)
    th, td
      font-size: pxToRem(12)
      color: $mine-shaft
    textarea
      width: 97%
  .notificationRecipients label
    margin: 0 0 pxToRem(8)
    float: left
    width: 33%
    font-weight: normal
