.associatedFileUploader

  .errorMessage
    padding-bottom: pxToRem(17)

  .filePreview img
    max-width: pxToRem(260)

  &.progress-supported .loading
    display: none

  // clickable area is too large otherwise
  .uploadButton input
    // necessary for IE
    width: 100%
    height: 100%
    // necessary for Chrome (Windows)
    font-size: inherit !important

  // necessary for FF (Windows)
  .uploadButton ~ a
    z-index: 3
    position: relative

.upload-file
  display: table-row

  &:hover
    .upload-file-upload-link
      color: $ui_hover-old
      text-decoration: underline

  .upload-file-preview
    display: table-cell

  .upload-file-text
    display: table-cell

  .upload-file-actions
    display: table-cell
    vertical-align: bottom

    .upload-file-cancel
      display: table-row
      padding-left: pxToRem(2)

.external-data-file
  position: relative

  .external-data-file-retry-alert
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    background: $alabaster_80
    border-color: $color_warning
    border-style: solid

    .external-data-file-retry-actions
      display: block
      position: relative
      top: 50%
      transform: translate(-50%, -50%)
      left: 50%
      width: fit-content

      a
        display: block

        br
          display: none

  .external-data-file-retry
    display: table-row
    padding-left: pxToRem(2)

    a
      display: table-row

    span
      display: table-cell
      vertical-align: middle

// File previews

.filePreview
  text-align: center

  .preview-thumb 
    img
      max-height: calc(100% - 1rem - pxToRem(2))
      max-width: calc(100% - 1rem - pxToRem(2))
      min-width: pxToRem(230)
      clear: both
      padding: 0.5rem
      border-radius: $border-radius
      background: $white
      border: pxToRem(1) solid $border

// WOPI Office Integration
body.office
  margin: 0
  padding: 0
  overflow: hidden
  -ms-content-zooming: none

.office_frame
  display: block
  margin: 0
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  border: none
