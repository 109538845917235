// settings

.container-sidebar
  margin: pxToRem(20) pxToRem(20) pxToRem(20) 0
  clear: both
  float: left
  width: pxToRem(199)
  border-left: pxToRem(1) solid $border

  a
    display: block
    color: $shuttle-gray
    padding: pxToRem(12)

    &:hover
      text-decoration: none
      border-left: pxToRem(2) solid $ui_focus
      padding-left: pxToRem(10)

    &.active
      font-weight: bold
      color: $color_brand
      border-left: pxToRem(2) solid $color_brand
      padding-left: pxToRem(10)

      .count
        color: $shuttle-gray

  .count
    background: $concrete
    color: $gray
    font-weight: bold
    padding: pxToRem(3) pxToRem(6)
    border-radius: 50%
    float: right
    margin-top: -pxToRem(3)

#email-subcontainer
  margin: pxToRem(5) 0 0 0
  #change-email
    padding: pxToRem(0)
  #changeEmail
    .collapsible-title
      padding: pxToRem(20) 0 0 0
    .subcontainer
      margin: pxToRem(10) 0 0 0
