.ProtocolReadoutDefinitionsTable
  td
    vertical-align: top

    > div
      max-height: 1.2rem
      max-width: 300px
      display: block
      min-height: 1.2rem

  .indent-first-child
    padding-left: 1rem

.ellipsisToolTip
  max-height: unset!important
  max-width: unset!important
