$mixtureTitleColor: $mine-shaft
$mixtureColor: $shuttle-gray
$mixtureIndent: 1rem
$proposalHeader: $ui

.mixture-button .disabled
  @extend %disabled-button

.mixture__container
  margin: pxToRem(10) 0
  min-height: pxToRem(100)
  border-style: solid
  border-color: $catskill-white
  border-width: pxToRem(1)
  padding: pxToRem(10)
  border-radius: pxToRem(2)

  &:hover
    border-color: $denim

%mixture-titles
  color: $mixtureTitleColor
  border-bottom-style: solid
  border-color: $catskill-white
  border-width: pxToRem(1)
  padding-bottom: pxToRem(8)

.mixture__title
  @extend %mixture-titles
  margin-bottom: pxToRem(12)
  padding-bottom: pxToRem(9)
  font-size: pxToRem(17.6)
  padding-top: pxToRem(2)
  border-color: $silver
  line-height: 1.25

.mixture__sectiontitle
  @extend %mixture-titles
  font-size: pxToRem(20)

.mixture__sectionblock
  display: flex
  margin-bottom: pxToRem(15)
  line-height: 1.125

  &:nth-last-of-type(1)
    margin-bottom: 0

.mixture__sectioncolumn
  flex-basis: 0
  flex-grow: 1

  + .mixture__sectioncolumn
    padding-left: pxToRem(10)

.mixture__assignmentLabel
  margin: pxToRem(10) 0 pxToRem(5)
  color: $mixtureColor

.mixture__assignmentTooltip
  @extend %tooltiptext-common
  max-width: pxToRem(500)

.mixture__annotationValue
  color: $mixtureColor
  list-style-type: none
  font-size: pxToRem(16)
  padding-left: $mixtureIndent

.mixture__annotationValue > li:before
  display: inline-block
  margin-left: -$mixtureIndent
  content: '-'
  color: $mixtureColor
  width: $mixtureIndent
  text-align: center


.mixture__annotationMissing
  margin: pxToRem(2)
  color: $mercury
  font-size: pxToRem(16)

%mixture-icon
  color: $ui
  font-size: pxToRem(10)
  height: 1rem
  width: 1.3rem

.mixture__autotext
  display: flex
  margin-bottom: pxToRem(10)
  color: $mixtureColor
  align-items: flex-start
  justify-content: space-between

  & > div:nth-last-of-type(1)
    width: pxToRem(35)
    min-width: pxToRem(35)

  & .copy_autotext
    margin-top: -pxToRem(2)
    color: $ui
    font-size: pxToRem(32)
    float: right

    .mixture__control
      margin-top: -1rem

      .fa
        font-size: pxToRem(14)

.mixture__container .mixture__control
  display: none

.mixture__container:hover .mixture__control
  display: inline-flex

.mixture__controlTooltip
  @extend %tooltiptext-common

.mixture__control
  @extend %mixture-icon

  .fa-pencil
    font-size: pxToRem(14)

  &:hover, &:focus, &:active
    color: $ui_hover
    text-decoration: none

// mixture dialog
.mixtureDialog
  height: 80vh
  width: 80vw
  max-width: pxToRem(1000)
  max-height: pxToRem(800)
  overflow: visible

.mixtureDialog-title
  background-color: $ui
  border-top-right-radius: pxToRem(2)
  border-top-left-radius: pxToRem(2)
  padding: pxToRem(30) pxToRem(60)
  color: $alabaster
  font-size: pxToRem(24)

[role=document]
  .mixtureDialog-content
    margin: pxToRem(20) 0
    padding: 0 0.5rem

    header
      background-color: $ui
      box-shadow: pxToRem(0) pxToRem(0)
    > div > div
      padding-top: pxToRem(0) !important
      & + div
        padding-top: pxToRem(5) !important
    div.mixtureDialog-assignment
      display: flex
      margin-left: pxToRem(30)
      margin-right: pxToRem(30)
      min-height: pxToRem(50)

  .mixtureDialog-actions
    margin: 0 pxToRem(50) pxToRem(10)
    padding: pxToRem(0)

// Assay annotation dialog Tabs
$mixtureTabHeight: pxToRem(33)
$mixtureTabRadius: pxToRem(5)

.mixtureDialog-appBar
  background-color: $ui
  box-shadow: none

.mixtureDialog-tabs
  margin: 0 pxToRem(60)
  min-height: $mixtureTabHeight
  div > div > span
    display: none

  button
    height: pxToRem(45)
    min-height: auto

%mixtureDialog-tab-base
  +border-radius-top-right($mixtureTabRadius)
  +border-radius-top-left($mixtureTabRadius)
  background-color: $ui
  color: $alabaster
  height: $mixtureTabHeight
  opacity: 1
  span
    font-size: 0.9rem

.mixtureDialog-tab
  @extend %mixtureDialog-tab-base

.mixtureDialog-tab-selected
  @extend %mixtureDialog-tab-base
  background-color: $white
  color: $black

.mixtureInput
  border: none

.mixtureInput-formControl
  align-self: flex-end

[role=document]
  .mixtureDialog-content
    div.mixtureDialog-assignment-container
      margin: 0
    div.mixtureDialog-assignment
      display: flex
      margin-left: pxToRem(0)
      margin-right: pxToRem(0)
      min-height: pxToRem(50)
      flex-direction: row
      justify-content: flex-end
      margin-bottom: 0.5rem

      & > div:first-child
        display: flex
        width: 100%
        flex-direction: column
        justify-content: flex-end
        border-bottom: pxToRem(2) solid $silver
        &:hover
          border-bottom-color: $regent-gray

          + .mixtureDialog-showAllButton
            border-color: $regent-gray

.mixtureDialog-currentAnnotations
  margin: 0 -pxToRem(2)
  width: 100%
  padding: 0.25rem 0 0.375rem
  &:focus
    outline: none

.mixtureDialog-showAllButton
  align-self: flex-end
  border-bottom: pxToRem(2) solid $silver

  .material-icons
    padding: pxToRem(5)

// Annotation Chip
$annotationChipHeight: pxToRem(22)
.mixtureDialog-annotation
  max-width: 100%

  span
    display: block
    padding: 0 pxToRem(8)
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.mixtureDialog-annotation-avatar
  margin-left: pxToRem(3)
  height: $annotationChipHeight - pxToRem(6)
  width: $annotationChipHeight - pxToRem(6)
  font-family: serif
  color: $catskill-white
  background-color: $silver-chalice

// suggestions
.mixtureDialog-suggestions
  margin: -0.5rem pxToRem(0) 0
  z-index: 100

  // retrieve suggestions
  > li
    padding: pxToRem(6) pxToRem(16)


.mixtureDialog-suggestion-freetext
  display: flex
  height: 1.5rem
  background-color: $denim
  color: $white
  padding-bottom: 0
  justify-content: space-between

  &:hover
    background-color: $denim

.mixtureDialog-suggestions-list
  max-height: pxToRem(300)
  overflow-y: scroll

  .proposal
    padding-left: pxToRem(30)

    &:nth-of-type(1):before
      display: block
      margin: -pxToRem(5) -pxToRem(30) pxToRem(5)
      content: 'Proposals'
      padding: pxToRem(5) pxToRem(32) pxToRem(5) pxToRem(15)
      background: $white
      width: 100%
      color: $ui
      pointer-events: none

.mixtureDialog-suggestion-suggestion
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  padding: pxToRem(6) pxToRem(16)
  height: auto

  & mark
    background: none
    font-weight: bold

  &.proposal + &.suggestion
    border-top-style: dashed
    border-top-color: $silver
    border-top-width: pxToRem(1)

.mixtureDialog-suggestion-footer
  height: 2rem
  background-color: $alabaster
  padding-top: 0
  padding-bottom: 0
  font-style: italic
