%disabled-button
  opacity: 0.25

.entry--actions
  display: inline-flex
  margin: pxToRem(2) 0 0 -pxToRem(12)

  &[disabled]
    @extend %disabled-button

  > div
    button, a
      height: pxToRem(36)
      width: pxToRem(36)
      color: $ui

      &:hover
        color: $ui_hover

    > span
      @extend %disabled-button

  &.tooltiptext
    @extend %tooltiptext-common
    position: relative
    top: pxToRem(4)
    left: pxToRem(10)

.entry--entry-identifier
  display: inline-block
  float: right
  padding-top: pxToRem(14)
  padding-right: pxToRem(15)

.discard-action
  float: right

.finalize__button
  &:hover
    .fa-unlock
      @extend .fa-lock // sass-lint:disable-line placeholder-in-extend

.reopen__button
  &:hover
    .fa-lock
      @extend .fa-unlock // sass-lint:disable-line placeholder-in-extend

.finalize__button, .duplicate__button, .export__button
  display: flex !important
  align-items: center
  justify-content: center
  padding-top: 0 !important
  text-decoration: none !important

  &.disabled
    @extend %disabled-button

  .fa
    color: $ui !important

    &.hover
      color: $ui_hover !important
