#newUploadForm
  max-width: pxToRem(1200)

#newUpload.subcontainer
  margin: 0 0 pxToRem(25) 0
  border: none
  background: none
  padding: pxToRem(30) 0 0 0 !important

  #newUpload-file
    margin: 0
    float: left
    th
      white-space: nowrap
    td
      input
        width: pxToRem(240)
  #newUpload-project
    margin: 0
    float: left
    th
      padding-left: pxToRem(10)
    td
      padding: pxToRem(5) pxToRem(10) pxToRem(27) pxToRem(10)
      width: 85%
    select
      width: pxToRem(240)
  #newUpload-parser
    margin: 0
    th
      padding-left: pxToRem(10)
    td
      padding: pxToRem(5) pxToRem(10) pxToRem(5) pxToRem(10)
    select
      margin-top: pxToRem(3)

  .buttons-right
    margin-right: pxToRem(10)
    margin-bottom: pxToRem(20)
    padding-left: 3rem
  #newUploadForm.buttony-submit
    margin: pxToRem(5) pxToRem(5) 0 pxToRem(15)
    white-space: nowrap

#data_file_project_id
  margin-top: pxToRem(3)

#newUploadProgress.subcontainer, #newUploadProcessing.subcontainer
  margin: 0 0 pxToRem(25) 0
  padding: pxToRem(10)
  height: pxToRem(60)
  p
    text-align: center
    font-size: pxToRem(15)
    padding: pxToRem(15) 0 pxToRem(5)
    .loading img
      width: auto

#previouslyUploaded
  h3
    margin: pxToRem(5) 0 pxToRem(15) 0
    float: left
  .data-filters
    .item
      float: right

  .collapsible-open
    .collapsible-title
      background: url("../images/cdd30/layout/collapsible/open-12.gif") no-repeat 0 pxToRem(2)
  .collapsible-closed
    .collapsible-title
      background: url("../images/cdd30/layout/collapsible/closed-12.gif") no-repeat 0 pxToRem(0)
  table.dataTable
    th, td
      vertical-align: middle
      &.file-name
        width: $sidebar-width
        word-break: break-all
        a
          color: $mine-shaft
        div
          padding-left: pxToRem(15)
      &.file-date
        width: pxToRem(150)
      &.file-status
        width: pxToRem(130)
      &.file-actions
        text-align: right
    .no-border
      border-top: none
    .icon-16
      vertical-align: pxToRem(0)
    .healthy
      color: $limeade
    .action-required
      color: $mango-tango
    .fatal
      color: $guardsman-red

// --------------------------------------------------
// Import Step 2
// --------------------------------------------------

#mappingInfo
  .item
    width: 100%
    strong
      padding: 0

// Mapper (use pixel widths for this, because it can get ginormous and we don't want it to scale)
#mapper
  float: left
  padding: 0
  width: calc(100% - 40px)
  font-size: 14px
  #mapper-data
    position: relative
    padding-top: 23px
    .mapper-pager
      position: absolute
      top: 23px
      width: 30px
      height: 245px
      background: url("../images/cdd30/layout/mapper/pager-fade.png") no-repeat 0 0
      .mapper-pager-button
        display: block
        position: absolute
        top: -30px
        width: 30px
        height: 30px
        background: url("../images/cdd30/layout/mapper/pager-button.png") no-repeat -5px -5px
        cursor: pointer
      #mapper-pager-button-left
        left: 1px
        background-position: -5px -5px
      #mapper-pager-button-left:hover
        background-position: -5px -45px
      #mapper-pager-button-left.disabled,
      #mapper-pager-button-left.disabled:hover
        background-position: -5px -85px
        cursor: default
      #mapper-pager-button-right
        right: 1px
        background-position: -45px -5px
      #mapper-pager-button-right:hover
        background-position: -45px -45px
      #mapper-pager-button-right.disabled,
      #mapper-pager-button-right.disabled:hover
        background-position: -45px -85px
        cursor: default
    #mapper-pager-left
      left: 0
    #mapper-pager-right
      right: 0
      background-position: -30px 0
    &.noScroll
      padding-top: 0
      .mapper-pager
        top: 0
      // We want to show the row numbers hence only hiding mapper-pager-left a
      #mapper-pager-right, #mapper-pager-left a, #mapper-scrollbar
        display: none
  #mapper-scrollbar
    margin: 0 30px 7px 30px
    position: absolute
    top: 0
    left: 0
    width: calc(100% - 30px)
    #mapper-scrollbar-background
      width: 100%
      height: 16px
      border-radius: 8px
      background: $mercury url("../images/cdd30/layout/mapper/scrollbar-bg.png") repeat-x top left
      border: none
    #mapper-scrollbar-track
      height: 16px
      border: none
      background: none
      .ui-slider-handle
        top: -0.1rem
        margin-left: -0.1rem
        width: 50%
        height: 16px
        border-radius: 8px
        background: $fountain-blue url("../images/cdd30/layout/mapper/scrollbar-highlight.png") repeat-x 0 0
        &:focus
          outline: none
          box-shadow: none
  #mapper-scrollbar.disabled
    #mapper-scrollbar-track
      #mapper-scrollbar-handle
        background-color: $silver
  #mapper-columns
    position: relative
    float: left
    padding: 0 30px
    width: 100%
    overflow-x: auto
    overflow-y: hidden
    margin-bottom: 20px
    #mapper-columns-inner
      float: left
    .column
      float: left
      width: 150px
      cursor: pointer
      background: url("../images/cdd30/layout/mapper/columns-bg.gif") repeat-x 0 30px
    .cell
      padding: 8px 9px 0 9px
      width: 131px
      height: 20px
      border-top: 1px solid $mercury
      border-right: 1px solid $mercury
      color: $dusty-gray
      line-height: 1rem
      overflow: hidden
    .columnName
      padding-top: 9px
      font-size: 11px
      font-weight: bold
      text-align: center
      background: $alabaster
    .headerCell, .footerCell
      padding: 9px 9px 0 9px
      height: 21px
      color: $mine-shaft
      font-weight: bold
    .headerCell
      .icon-16
        margin: -2px 0 0 0
        float: right
        vertical-align: 0
    .footerCell
      margin: 0 0 10px 0
      line-height: 14px
      height: 49px
      white-space: nowrap
    .mappedColumn .headerCell,
    .mappedColumn .footerCell
      color: $limeade
      background: $orinoco
      .arrow
        opacity: 0.5
        font-weight: normal
    .importedColumn .footerCell,
    .unmappedColumn .footerCell
      color: $dusty-gray
    .partiallyMappedColumn .footerCell,
    .partiallyMappedColumn .headerCell
      color: $ui_hover
      background: $serenade
    .mappingArrow
      position: relative
      height: 27px
      width: 100%
      border-top: 1px solid $mercury
      background: url("../images/cdd30/layout/mapper/column-tear.gif") no-repeat 0 0
    .activeColumn
      cursor: default
      .cell
        color: $tropical-blue
        background: $curious-blue
        border-color: $curious-blue_dark
      .mappingArrow
        border-color: $curious-blue_dark
        background-position: -300px 0
      .headerCell, .footerCell
        color: $white
        background: $curious-blue
      .footerCell
        margin: 0
        height: 59px
    .importedColumn
      cursor: default
      .headerCell
        color: $dusty-gray
    .selectableColumn:hover
      .cell
        background: $solitude
        border-color: $botticelli
      .mappingArrow
        border-color: $botticelli
        background-position: -150px 0
      .altCell
        background: $pattens-blue
      .headerCell
        background: $link-water
      &.mappedColumn .footerCell
        background: $apple-green
      &.importedColumn .footerCell,
      &.unmappedColumn .footerCell
        background: $link-water
      &.partiallyMappedColumn .footerCell
        background: $gray-nurse
    #newColumnLink
      margin-right: 30px
      .icon-16
        margin: -2px 5px 0 0
        float: left
        vertical-align: 0
      .footerCell
        color: $dusty-gray
        font-weight: normal
    #newColumnTemplate
      display: none

#mapper.loading
  #mapper-columns .column
    cursor: default

// Mapping template wizard

#mappingTemplateWizardLink
  margin: -pxToRem(3) 0 pxToRem(23) 0
  .icon-16
    vertical-align: -pxToRem(2)

#mappingTemplateWizard
  margin: 0 0 pxToRem(25) 0
  border: pxToRem(5) solid $curious-blue
  .message, .successMessage, .errorMessage, .warningMessage
    padding-top: pxToRem(20)

#mappingTemplateWizard-step1
  .actionIcon
    display: inline-block
    float: none
    vertical-align: -pxToRem(1)

#mappingTemplateWizard-step2-backLink
  margin: pxToRem(5) 0 0 0
  float: left
  .actionIcon
    margin: pxToRem(1) pxToRem(3) 0 0

#mappingTemplateWizardApplied
  margin-bottom: pxToRem(25)
  padding-top: pxToRem(20)

#mappingOptionsForm
  margin-bottom: -1%
  padding: 0
  ul
    max-height: 100%
    margin-top: 1%
    li
      display: inline-block
      height: 100%
      list-style-type: none
      padding: 0
      margin-right: 1rem
      font-size: pxToRem(16)

      .radio__text
        font-weight: 400

.container-content
  padding-top: .9%

// Warnings

#mappingWarnings
  .warningMessage,.errorMessage
    margin: pxToRem(25) 0 pxToRem(5) 0
    p
      font-weight: bold

// Picker

#mapper-picker
  clear: both
  padding: pxToRem(5) pxToRem(6)
  background: $curious-blue
  border-radius: pxToRem(3)
  max-width: $max-narrow-form-width
  margin: 0 auto
  .drilldown
    border-radius: pxToRem(2)
    margin-left: -pxToRem(1)
    background: $white
    padding: pxToRem(10)
    width: calc(100% - pxToRem(20))
    a.buttony
      margin: pxToRem(20) 0 0 0

#mapper-picker
  table.form
    th
      white-space: nowrap
    td
      p.runs_for_readout_definition
        margin-top: pxToRem(3)
    td.checklist
      padding: pxToRem(10) 0 pxToRem(10) pxToRem(10)

#mapper-picker
  a.active.unavailable:hover
    background-color: $curious-blue

.disabled #mapper-picker
  a.active.unavailable:hover
    background-color: inherit

// Registration types

#mapper-registrationType
  margin: 0 0 pxToRem(25)
  padding: pxToRem(15)
  text-align: center
  font-size: pxToRem(14)
  line-height: pxToRem(25)
  span
    text-align: center
    &.disabled
      color: $dusty-gray
      &:hover
        color: $dusty-gray
        cursor: default
        text-decoration: none
    span
      white-space: nowrap
  input.input-radio
    margin: 0 pxToRem(3) 0 0
    padding: 0
    position: relative
    top: -pxToRem(1)
    vertical-align: middle

// Batch and molecule default values

#mapper-defaultValues
  tbody th
    width: $sidebar-width
  #mapper-defaultValues-edit-moleculeFields, .editableData-editLink-save
    margin-top: pxToRem(25)
  table.simple .associated-model
    padding: 0 0 pxToRem(10) 0
    a
      margin: pxToRem(5) 0
      float: left
    input
      margin-right: pxToRem(10)
      float: left
    input.field
      width: pxToRem(90) !important
    input.value
      width: pxToRem(290) !important

// Create run on the fly

#createRun-dialog
  .popup-inner1
    top: -pxToRem(500)
    left: pxToRem(200)
    width: pxToRem(530)
  .popup-inner2
    width: pxToRem(500)
  table.simple th
    width: pxToRem(50)
  .input-checkbox
    width: auto
    vertical-align: text-bottom
    margin-left: pxToRem(3)
  label
    margin-left: pxToRem(5)
  #new_run_submit
    margin: 0

#new_run
  .wideForm
    td, th
      border-bottom: pxToRem(1) solid $gallery !important
      padding: pxToRem(15) pxToRem(10) !important
      vertical-align: middle
      background: transparent
    tr
      &:last-child
        td, th
          border-bottom: none !important

// --------------------------------------------------
// Import Step 2 and 3
// --------------------------------------------------

#import-3-mappingSummary
  width: auto
  max-width: pxToRem(420)

#saveMappingTemplate
  width: pxToRem(450)
  &.floatRight
    #saveMappingTemplate-link
      float: right
    #saveMappingTemplate-dialog .popup-inner1
      top: -pxToRem(20)
  &.floatLeft
    #saveMappingTemplate-link
      float: left
    #saveMappingTemplate-dialog .popup-inner1
      bottom: -pxToRem(40)
  .popup.dialog .popup-inner2 input
    width: auto

#addAColumn-dialog
  .popup-inner1
    display: none
    bottom: -pxToRem(210)
  // fixes IE7 problem
  .popup-inner2 input
    width: auto

#resolvableBatchAttributes
  .popup-inner1
    left: pxToRem(100)
    top: pxToRem(10)
  // fixes IE7 problem
  .popup-inner2 input
    width: auto

// --------------------------------------------------
// Import Step 3 (QC Report)
// --------------------------------------------------

// Summary

#import-summary
  .message
    ul
      list-style: none

// Events Viewer

#runs, #warnings, #details, #resolvableWarnings, #errors, #extra_files
  margin-bottom: pxToRem(25)

.eventSection
  clear: both
  .collapsible
    margin-bottom: pxToRem(15)
    h3
      line-height: pxToRem(19)
    .toggleSwitch
      padding: 0
    .toggleSwitch-on
      display: block
    .toggleSwitch-off .toggleSwitch-control-off, .toggleSwitch-off .toggleSwitch-control-off:hover
      background-color: $valencia
    .importEventsViewer-events
      overflow-x: visible
      overflow-y: hidden
      padding-bottom: pxToRem(10)
      .importEvents
        margin: 0
        tr.rejected
          td, th
            // 1 step down for $valencia
            background-color: $sea-pink
        td
          white-space: nowrap
    .description
      padding: pxToRem(5)
    .downloadAll
      margin-top: pxToRem(10)
      padding: pxToRem(10)
      background: $gallery
      p
        margin: 0
        text-align: center

.importEventsViewer
  float: left
  width: 100%

.importEventsViewer-events
  float: left
  width: 100%

.short_resolution
  display: block

.importEventsViewer-details
  display: none
  margin: pxToRem(25) 0 0 0
  float: right
  width: 48%
  .subcontainer
    display: inline-block
    ul
      margin: 0
      list-style: none
      padding: 0
  .close
    margin: -pxToRem(15) -pxToRem(15) 0 0
    float: right

.importEventsViewer-details-inactive
  display: none

.showDetails
  .importEventsViewer-events
    width: 49%
    .importEvents
      .active td, .active th
        background: $ui
        color: $white
        a, a:hover, a:active
          color: $white
          text-decoration: none
        .actionIcon img, a:hover .actionIcon img
          position: relative
          top: -pxToRem(20)
      .importEvents-status
        width: pxToRem(35)
    .status
      display: none
    .viewDetails .text
      display: none
  .importEventsViewer-details
    display: block

.importEvents
  .importEvents-row
    width: pxToRem(35)
  .importEvents-status
    width: pxToRem(100)
  table.importEvents td
    vertical-align: middle
  .viewDetails
    float: right
    cursor: pointer
    .text
      float: left
    .actionIcon
      margin: pxToRem(2) 0 0 pxToRem(2)
      float: left
  .viewDetails:hover, a:hover .viewDetails
    text-decoration: underline

.importEventsViewer-globalResolution
  margin: pxToRem(20) 0 0 0
  float: left

.hiddenCategoryTotalForTests
  display: none

.rejected_events
  color: $valencia

#extraFilesList
  background-color: $white
  p
    padding: pxToRem(10)

#warnings_table
  .ambiguousEventActions
    display: flex
    justify-content: space-between
    align-items: flex-end
