dl
  margin: 0
  padding: pxToRem(10) 0 0
  border-top: pxToRem(1) solid $border
  line-height: pxToRem(16)

  dt
    @extend %break-word
    margin: 0
    padding: 0 0 pxToRem(2)
    font-weight: bold

  dd
    @extend %break-word
    margin: 0
    padding: 0 0 pxToRem(20)


dl.dl__float
  float: left
  width: calc(50% - pxToRem(21))
  padding-right: pxToRem(20)

dl.dl__float__x3
  width: calc(33% - pxToRem(21))

dl.dl__float__x4
  width: calc(25% - pxToRem(21))

dl.dl-horizontal
  dt
    display: block
    padding: 0 pxToRem(20) pxToRem(5) 0
    float: left
    clear: left
    width: pxToRem(130)

  dd
    display: block
    width: calc(100% - pxToRem(150))
    float: left
    padding: 0 0 pxToRem(5)

dl.dl-multiple
  float: left
  padding-bottom: pxToRem(10)
  border: none

  dt
    color: $material-label
    font-weight: normal
  dd
    font-size: pxToRem(16)
    line-height: 1.4rem

  &:nth-of-type(4n + 1)
    clear: both

dl.dl-grid
  display: grid
  /* pxToRem(6) * 4 cols == pxToRem(8) * 3 gaps */
  grid-template-columns: repeat(4, calc(25% - pxToRem(6)))
  grid-column-gap: pxToRem(8)

.dl-group__file
  grid-column: 1 / span 2

  + .dl-group__file
    /* Fake :first-of-class */
    grid-column: span 2

  .filePreview

    img
      max-width: calc(100% - pxToRem(10))
      padding: 0.5rem
      border-radius: $border-radius
      background: $white
      border: pxToRem(1) solid $border
