@import ../../../../assets/stylesheets/base/_variables.sass

.LocationPicker, .sortable-tree-item
  .location-box-icon
    margin-right: .5rem
    svg
      transform: translateY(pxToRem(3))

.LocationBoxPickerContainer
  position: relative
  width: 100%
  height: calc(100% - 1rem)
  overflow: auto

.LocationPicker
  height: calc(100vh - 15rem) // allow room for changing location message
  overflow: hidden
  .location-picker-tree-item-content
    padding: 0

  .location-picker-tree-item-label
    font-size: pxToRem(14)!important

  .location-picker-tree-item-selected
    background-color: lighten($ui, 50%)!important

  .location-picker-tree-item-focused
    background: unset

  .LocationBoxPicker
    .loading-mask
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-color: rgba(255, 255, 255, 0.75)

      .loading-spinner
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

    .positions
      .row
        display: flex

      .box-position-selected
        // when boxes are close together, the right and bottom edges are hidden without this
        z-index: 2
        outline: pxToRem(5) solid $dark-blue

      .box-position-filled
        background-color: $ui

      .box-position-empty
        cursor: pointer
        background-color: $mercury

      .box-position-empty:hover
        background-color: $silver_dark
        text-decoration: none
      .box-position-bottom-edge
        border-bottom: pxToRem(1) solid black
      .box-position-left-edge
        border-left: pxToRem(1) solid black
      .box-position-element
        border-right: pxToRem(1) solid black
        border-top: pxToRem(1) solid black
        display: flex
        align-items: center

        label
          width: 100%
          text-align: center
          font-size: 1.125rem

      span
        position: relative
        display: inline-block

    .row-labels, .col-labels
      color: gray

    .col-labels
      position: absolute
      width: 100%
      text-align: center

      label
        display: inline-block
        padding-top: pxToRem(5)

    .row-labels
      position: absolute
      height: 100%
      text-align: center

      div
        display: flex
        align-items: center

      span
        align-content: center

.LocationPickerDialog
  .contents
    background-color: $white
    overflow: hidden

  .LocationBoxPickerContainer
    margin-top: 1rem

  .change-legend
    min-height: 3rem
    display: flex
    justify-content: center
    width: 100%
    td
      font-size: pxToRem(14)
      min-height: 0
      padding-left: .5rem

  .text-align-right
    text-align: right

  .bottom-modal-row
    button
      min-width: pxToRem(100)
      margin-left: pxToRem(8)
      margin-right: pxToRem(8)
