.marvin_version
  position: absolute
  bottom: pxToRem(2)
  left: pxToRem(3)
  color: $black_42

.molecule_selection
  position: fixed !important
  width: pxToRem(600)
  top: 50% !important
  left: 50% !important
  transform: translate(-50%, -50%)
  overflow: visible !important

.molecule_selection__content
  overflow: visible !important

#molecule-new
  #structure_editor_launcher
    padding: 0 !important
  .structure_editor
    background: transparent !important
    .clickable
      width: pxToRem(250) !important
      background: $white !important

.structure_editor
  text-align: center

  .structure_editor_icon
    fill: $ui

  #structure_editor_launcher
    padding: 1.125rem
    border: none

  h4
    display: inline-block
    margin: 0
    float: left
    padding-left: pxToRem(10)
    height: pxToRem(30)
    line-height: pxToRem(30)
    font-weight: bold

  &:hover
    border-color: $ui_hover-old

    .structure_editor_icon
      fill: $ui_hover-old

// New Molecule Form Structure Editor Launcher
#molecule-new .structure_editor
  float: left
  margin-right: pxToRem(10)
  background: $white
  border-color: $alto

  &:hover
    border-color: $ui_hover-old

//Search Page Structure Editor Launcher
.column1 .structure_editor
  margin: 0

  .inner
    padding: pxToRem(5) 0

//Admin Page Salts Structure Editor Launcher
.sidebar .structure_editor
  width: pxToRem(290)

  .inner
    padding: pxToRem(5) 0

// Custom Code Registration Form
.EditCustomNucleotideDialog, .EditCustomAminoAcidDialog
  .structure_editor
    .launch_structure_editor
      svg
        padding-left: pxToRem(0)
