.composeMacromol-destination
  border: pxToRem(3) solid $lochmara
  padding: 1rem

.composeMacromol-datagrid
  display: inline-grid
  align-items: stretch
  justify-items: stretch

.composeMacromol-datacell
  padding: 0.2rem 0.5rem 0.2rem 0.5rem
  border: pxToRem(1) solid $mercury
  margin-right: pxToRem(-1)
  margin-bottom: pxToRem(-1)

.composeMacromol-gridrowidx
  background-color: $alabaster
  text-align: center

.composeMacromol-gridcolidx
  background-color: $alabaster
  text-align: center
  cursor: pointer

.composeMacromol-gridtitle
  background-color: $concrete
  font-weight: bold

.composeMacromol-gridcontent
  background-color: $white

.composeMacromol-gridsel
  background-color: $lochmara
  color: $white
  border-top: pxToRem(1) solid $curious-blue

.composeMacromol-preview
  background-color: $white
  padding: 0.3rem
  margin-left: 0.1rem
  cursor: pointer

.composeMacromol-error
  color: $burnt-orange
  background-color: $white
  padding: 0.3rem
  margin-left: 0.1rem

.composeMacromol-isloading
  text-align: center
  padding-top: 1em
  font-size: 120%

.composeMacromol-optionbox
  display: flex
  flex-direction: row
  justify-content: start
  align-items: flex-start
  gap: 1rem

.composeMacromol-option-type
  flex-grow: 0

.composeMacromol-option-subtype
  flex-grow: 1

.composeMacromol-option-monomers
  flex-grow: 0
  align-self: stretch

.composeMacromol-custommonomers
  width: 100%
  height: 100%

.composeMacromol-custommonomers-dragging
  background-color: $link-water

.composeMacromol-optiongrid
  display: grid
  grid-template-columns: [title] auto [content] 1fr [end]
  flex-direction: row
  grid-column-gap: 0.5rem
  justify-items: start
  align-items: center

.composeMacromol-numberinput
  width: 5rem
  margin-right: 0.5rem

.composeMacromol-edit-grid
  display: grid
  column-gap: 0.5rem
  grid-template-columns: [start] 0.5rem [code] auto [natural] auto [molecule] auto [end]
  justify-items: start
  align-items: center

.composeMacromol-edit-item
  padding: 0.2rem 0 0.2rem 0

.composeMacromol-edit-line-odd
  width: 100%
  height: 100%
  background-color: $aqua-haze

.composeMacromol-edit-line-even
  width: 100%
  height: 100%
  background-color: $alice-blue

.composeMacromol-tabbar
  display: flex
  justify-content: center
  align-items: center
