.stoichiometry-table
  margin: 0 2rem 1rem
  border-collapse: collapse
  width: calc(100% - 4rem)

  .row-item
    display: -webkit-box
    overflow: hidden
    text-overflow: ellipsis
    word-break: break-all
    -webkit-box-orient: vertical

  tbody tr
    border-top: pxToRem(1) solid $border

    &:nth-of-type(1)
      border-color: $gray

    &.stoichiometry-table-solvent
      border-top-width: pxToRem(0)

    &.stoichiometry-table-solutionSolvent
      border-top-width: pxToRem(0)

    &.stoichiometry-table-comment
      border-top-width: pxToRem(0)

      td.comment-view-mode
        border-top: pxToRem(1) solid $border

        & .row-item
          word-break: break-word


  &.editable:hover
    cursor: pointer

  tr.stoichiometry-table-product
    &:nth-of-type(1)
      border-color: $gray

  th
    text-align: left
    padding: 0.5rem

  td
    vertical-align: top
    padding: 0.5rem
    max-width: pxToRem(200)
    td > div
      display: flex
      flex-direction: column
      align-items: stretch

    .noneditable
      opacity: 0.5

    .required
      margin: pxToRem(0)

    .optional
      margin: pxToRem(0)
      color: $jambalaya

  & .editable
    cursor: pointer

    & label
      cursor: pointer

  & .actions
    text-align: center

  .limitingReagent
    color: $denim

  .comment::placeholder
    color: $jambalaya
    opacity: 1.0

  .comment:focus::placeholder
    color: transparent

  .comment
    font-size: pxToRem(12)
    padding: pxToRem(0)
    cursor: pointer

    &:focus,
    &:active,
    &:hover
      color: $color !important

.stoichiometry__dialog

  > div
    overflow: visible

  .validation_error
    padding: pxToRem(8)
    font-size: pxToRem(12)
    color: $color_warning
    background: $bg_warning

.stoichiometry__input--disabled,
.stoichiometry__input--disabled:focus-within
  opacity: 0.5
  border: none

  label
    color: $shuttle-gray
    opacity: 0.5

  > div, input
    color: $shuttle-gray
    &:before
      border-color: $border
    &:after
      display: none

  > div:hover
    border: none
    &:before
      border-bottom: pxToRem(1) solid $border !important



.ratio-amount__fields
  display: flex
  flex-direction: row

.amount__field
  display: flex
  width: 200%
  flex-direction: column

.ratio__field
  display: flex
  width: 100%
  margin-left: 1rem
  flex-direction: column

.moleculeName__input
  padding: 1rem 0 2rem

  p
    margin: 0.25rem 0
    opacity: 0.5

.batchInformation__field
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 1rem

.batchInformation__radio
  padding: 0.5rem 0

  label
    margin: 0 1rem 0 0

  .radio
    height: 2rem
    width: 1.5rem
    margin-right: 0.5rem
    font-size: pxToRem(16)

    svg
      height: 0.75rem
      width: 0.75rem

.batchInformation__label
  font-weight: 400
  font-size: pxToRem(16)

.batchInformation__tooltip
  font-size: 1.2rem

.stoichiometryRegister__link
  padding-top: pxToRem(10)
  margin-bottom: -pxToRem(15)

.solvent-property__fields
  display: flex
  flex-direction: row
  padding-top: pxToRem(10)

.solvent-cas__field
  display: flex
  width: 100%
  flex-direction: column

.solvent-boiling-point__field
  display: flex
  width: 100%
  margin-left: 1rem
  flex-direction: column

.solvent-density__field
  display: flex
  width: 100%
  margin-left: 1rem
  flex-direction: column

.molecule-batch__fields
  display: flex
  flex-direction: row

.molecule-name__field
  display: flex
  width: 200%
  flex-direction: column

.batch-name__field
  display: flex
  width: 100%
  margin-left: 1rem
  flex-direction: column

.stoichiometry-table-controls a
  margin-right: 1rem
