.toggleSwitch
  +border-radius(unquote("pxToRem(4)"))
  float: left
  height: pxToRem(19)
  padding: pxToRem(2)
  border: pxToRem(1) solid $mercury
  background: $alabaster

.toggleSwitch-control-on, .toggleSwitch-control-off
  +border-radius(unquote("pxToRem(2)"))
  margin: 0
  padding: 0 pxToRem(7)
  float: left
  height: pxToRem(19)
  font-family: arial, sans-serif
  font-size: pxToRem(10)
  line-height: pxToRem(19)
  font-weight: bold
  text-transform: uppercase
  text-align: center
  &:hover
    text-decoration: none

.toggleSwitch-control-off
  margin: 0

.toggleSwitch-on
  display: none
  .toggleSwitch-control-on, .toggleSwitch-control-on:hover
    background: $limeade
    color: $white
    cursor: default
  .toggleSwitch-control-off
    color: $viking
  .toggleSwitch-control-off:hover
    color: $ui_hover
    text-decoration: underline !important
  &.toggleSwitch-disabled
    .toggleSwitch-control-off:hover
      cursor: default
      color: $viking
      text-decoration: none !important

.toggleSwitch-off
  display: block
  .toggleSwitch-control-on
    color: $viking
  .toggleSwitch-control-on:hover
    color: $ui_hover
    text-decoration: underline !important
  .toggleSwitch-control-off, .toggleSwitch-control-off:hover
    background: $dusty-gray
    color: $white
    cursor: default
  &.toggleSwitch-disabled
    .toggleSwitch-control-on:hover
      cursor: default
      color: $viking
      text-decoration: none !important

.toggle-on
  .toggleSwitch-on
    display: block
  .toggleSwitch-off
    display: none
