.SortableTreeView
  position: relative
  overflow: visible

  > *
    user-select: none

  .tree-view-dragging
    background-color: white
    > :hover
      background-color: unset !important

  > ul
    width: 100%
    overflow-y: auto !important
    overflow-x: hidden

  .dragging-placeholder
    width: 100%
    position: absolute
    pointer-events: none
    opacity: .75
    background-color: rgba(220,220,220)
    top: 0
    left: 0
    margin-top: pxToRem(20)

  .draggable-tree-item-contents
    cursor: ns-resize
    display: inline-block
    text-overflow: ellipsis
    float: left

  .cursor-not-allowed
    cursor: not-allowed!important
    > *
      cursor: not-allowed!important

  .sortable-tree-item-focused
    background: unset

  .sortable-tree-item-insert-above
    box-shadow: 0 -pxToRem(3) 0 #000

  .sortable-tree-item-insert-below
    box-shadow: 0 pxToRem(3) 0 #000

  .sortable-tree-item-insert-within
    > .sortable-tree-item-content
      outline: pxToRem(1) solid #rgb(0,0,0)
      background-color: $ui_focus !important
