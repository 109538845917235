// alt table style
.table__alt
  > tr,
  > tbody > tr
    > td
      background: $white

  > tr.alt,
  > tbody > tr.alt
    > td
      background: $alabaster
    > th
      background: $gallery

table
  &.simple
    margin: 0 0 pxToRem(15) 0
    td
      padding: pxToRem(10) 0 pxToRem(5) pxToRem(10)
      vertical-align: top
    th
      padding: pxToRem(15) 0 pxToRem(5) pxToRem(10)
      vertical-align: top
      white-space: nowrap
      text-align: left
      font-weight: normal
    tbody th
      white-space: normal
      font-weight: bold
    input[type="checkbox"]
      margin-top: pxToRem(5)
    .singleAttachmentFileSelector
      margin-top: pxToRem(3)

  &.features
    margin: 0 0 pxToRem(15) 0
    width: 99.7%
    border: pxToRem(1) solid $gallery
    border-collapse: collapse
    .alt
      td, th
        background: $white
    td, th
      padding: pxToRem(10)
      border: pxToRem(1) solid $gallery !important
      background: $white
    td
      text-align: center
    tr:nth-child(even) td, tr:nth-child(even) th
      background: $white
    thead th
      padding: pxToRem(5) pxToRem(10) pxToRem(7) pxToRem(10)
      text-align: center
      color: $polo-blue
      background: $black-squeeze
      border-top: none
      border-bottom: none
    tbody th
      text-align: left
      border-right: none
      border-left: none
  // files table
  &.filesTable
    td
      vertical-align: middle
  &.files
    tr:nth-child(odd), tr:nth-child(odd) td
      background: $white
    tr:nth-child(even), tr:nth-child(even) td
      background: $alice-blue
    td.actions
      vertical-align: middle
    td progress
      display: block
      clear: both
    td .status
      float: left
    td
      border-left: none
      border-right: none
  // form table
  &.form
    tbody th
      padding-right: 0
      font-weight: bold

      strong
        color: $mine-shaft
    .details
      margin: pxToRem(5) 0 0 0
      white-space: normal
      font-size: pxToRem(11)
      line-height: 1.3rem
      font-weight: normal
      color: $dusty-gray
      &.right-padded
        padding-right: pxToRem(35)
    .text
      padding-top: pxToRem(15)
    .hr
      margin: pxToRem(10) 0
    .hr2
      @extend %hr
      margin: 0 0 pxToRem(10) 0 !important
  // wideform
  &.wideForm
    width: 100%
    max-width: pxToRem(1024)
    .input-text, textarea
      width: pxToRem(400)
    .wideForm__pick-list
      width: pxToRem(410)
    .form
      width: auto
      .input-text, textarea
        width: auto
  &.paired-rows
    &> tbody > tr
      &:nth-child(4n + 1)
        &> th, > td
          background: $white
        &+ tr > th, + tr > td
          background: $white
      &:nth-child(4n + 3)
        &> th, > td
          background: $white
        &+ tr > th, + tr > td
          background: $white
  &.collapsible-rows > tbody > tr
    &.odd
      &> th, > td
        background: transparent
    &.even
      &> th, > td
        background: transparent
  &.alternating-rows
    tbody
      tr:nth-child(odd)
        &> th, > td
          background: $white
      tr:nth-child(even)
        &> th, > td
          background: inherit
  // all tables
  td.actions
    text-align: right
    white-space: nowrap
  // tables with sorting links (Search results, show run All Data)
  thead
    th
      &.sortable a, &.sorted a
        padding: 0 pxToRem(13) 0 0
        background: url("../images/cdd30/layout/sort/off.gif") no-repeat 100% 0
      &.ascending a
        background: url("../images/cdd30/layout/sort/up.gif") no-repeat 100% 0
      &.descending a
        background: url("../images/cdd30/layout/sort/down.gif") no-repeat 100% 0
      &.sorted a, &.sorted a:visited
        color: $cod-gray

td
  word-wrap: break-word

.table__item__width-30px
  width: pxToRem(30)

// other

span.mimicSimpleTableThTag
  font-weight: bold
  padding: 0 pxToRem(5) 0 pxToRem(5)
