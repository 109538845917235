@import ../../../../assets/stylesheets/base/variables

.DDForm-medium
  .round-icon-button
    width: pxToRem(48)
    height: pxToRem(48)

  .delete-icon-button, .help-icon-button
    padding: pxToRem(6) 0

  .help-icon
    img
      max-width: 1.5rem

.DDForm-small, .DDForm-medium
  .delete-button, .disabled-delete
    .button-contents
      display: inline-flex
      color: $color_warning
      svg
        margin-right: 0.5rem

  .disabled-delete
    p
      display: inline-flex
      color: rgba(100,0,0,.5)
      svg
        margin-right: 0.5rem

  .delete-icon-button
    .button-contents, button
      color: $color_warning !important
