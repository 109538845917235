// does not have an eln class/id parent so -new was added to classname in case it broke rest of app
.alert-new
  color: $color
  background: $bg
  padding: pxToRem(10)
  border-radius: pxToRem(2)
  font-size: pxToRem(16)

  > *:last-child
    margin-bottom: 0

.alert__right
  display: flex
  flex-grow: 1
  justify-content: flex-end

  > *, button
    margin-left: pxToRem(10)

.alert__warning
  color: $color_warning
  background: $bg_warning
