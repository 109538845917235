#alerts

  .muted
    padding: pxToRem(15) 0 0 pxToRem(10)

  table
    .centered
      text-align: center

  .removed_notice
    padding: pxToRem(10)
    text-align: center

  .inner
    display: inline-block
    padding: pxToRem(12) pxToRem(0) pxToRem(12) pxToRem(5)

    &.edit_alert_alert_type
      width: 14%
    &.edit_alert_project_id
      width: 14%
    &.edit_alert_period
      width: 8%
    &.edit_alert_detailed
      width: 11%
    &.edit_alert_protocol_id
      width: 20%
    &.edit_alert_inventory
      width: 19%
    &.actions
      width: 5%
      float: none
    select
      max-width: 100%

#alertPreview-dialog
  margin: auto
  width: pxToRem(600)

  .popup-inner1
    width: pxToRem(600)
  .popup-inner2
    width: pxToRem(570)
  .previewContent
    padding: pxToRem(10)
    background-color: $gallery
    border: solid $gray pxToRem(2)
  .message
    padding: pxToRem(10)

#alerts-show

  .subcontainer
    height: pxToRem(5)
    border: none

  .noDataMessage
    clear: both
