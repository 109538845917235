#dataSources
  min-height: pxToRem(600)

  &.drilldown
    display: flex

  .drilldown-menu
    border-radius: pxToRem(2)
    padding: 0

  // wide drilldown-menu width
  &.drilldown__explore-data .drilldown-menu
    min-width: $sidebar-width
    max-width: $sidebar-width

  .drilldown-options
    margin: 0
    flex-grow: 1

  #dataSources-sources
    padding: 0
    .collapsible-inner
      margin-top: pxToRem(40)
    .collapsible-closed
      margin: 0
      margin-bottom: pxToRem(25) !important
      .collapsible-header
        border-radius: pxToRem(3) !important
    .separator
      padding: 0 pxToRem(2)
    .dataSources-sourceList
      margin: 0 0 pxToRem(10)
      border-radius: pxToRem(3)
      padding: pxToRem(15) pxToRem(15) 0
      position: relative
      min-height: pxToRem(14)
      .dataSources-sourceList-browseAll
        margin: 0 0 0 -pxToRem(1)
        padding: 0 0 pxToRem(15) pxToRem(22)
        font-size: pxToRem(12)
        background: url("../images/cdd30/layout/show-icon.svg") no-repeat 0 pxToRem(1)
      .input-checkbox
        margin-right: pxToRem(5)
      .collapsible-header
        padding: pxToRem(15) 0 pxToRem(15) pxToRem(35)
        width: calc(100% - pxToRem(35))
        line-height: pxToRem(16)
        position: absolute
        top: 0
        left: 0
        label.collapsible-title
          display: inline-block
          position: relative
          font-size: pxToRem(12)
          padding: 0
          cursor: pointer
          font-weight: bold
          max-width: pxToRem(180)
          height: pxToRem(13)
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap
          z-index: 1
          &:hover
            margin: -pxToRem(15) -pxToRem(10) -pxToRem(18) 0
            max-width: pxToRem(500)
            text-overflow: unset
            padding: pxToRem(15) pxToRem(15) pxToRem(18) 0
            border-radius: pxToRem(3)
        .input-checkbox
          position: absolute
          top: initial
          left: pxToRem(15)
          top: pxToRem(16)
        span
          position: absolute
          top: pxToRem(17)
          right: pxToRem(12)
          height: pxToRem(8)
          width: pxToRem(8)
        .clickable
          cursor: pointer
        .sectionCount, .noCount
          display: inline-block
          position: absolute
          right: pxToRem(30)
          top: pxToRem(11)
          color: $white
          background: $polo-blue
          font-weight: bold
          padding: pxToRem(3) pxToRem(8)
          border-radius: pxToRem(10)
          transition: all 0.1s
          opacity: 0.5
          &.nonZero
            opacity: 1
    .collapsible-closed
      .collapsible-header
        span
          top: pxToRem(19)

    .dataSources-projects
      background: $black-squeeze
      label.collapsible-title
        color: $ship-cove
        &:hover
          background: $black-squeeze
      .dataSources-source-name
        color: $dove-gray
        text-overflow: ellipsis
        width: calc(100% - pxToRem(50))
        display: inline-block
        position: relative
        overflow: hidden
        margin-top: -pxToRem(4)
        margin-bottom: -pxToRem(4)
      .collapsible-header
        background: $black-squeeze_dark
        border-radius: pxToRem(3) pxToRem(3) 0 0
        span
          background: url("../images/cdd30/layout/collapsible/collapse-blue.svg") no-repeat
    #dataSources-shared
      .dataSources-source-name
        color: $ui_hover
    #dataSources-public
      margin-bottom: 0
      background: $frost
      .collapsible-header
        background: $loafer
        border-radius: pxToRem(3) pxToRem(3) 0 0
        label.collapsible-title
          &:hover
            background: $loafer
        .sectionCount
          background: $olivine
        span
          background: url("../images/cdd30/layout/collapsible/collapse-green.svg") no-repeat
      .dataSources-source-fade
        background: url("../images/cdd30/layout/datasources/fade-green.png") no-repeat top left
      .dataSources-source-name
        color: $dove-gray
    .dataSources-sourceList-toggles
      margin: pxToRem(1) pxToRem(25) 0 0
      float: right
      font-size: pxToRem(11)
    .dataSources-source
      margin: -pxToRem(7) 0 0 -pxToRem(10)
      padding: pxToRem(1) pxToRem(10)
      font-size: pxToRem(12)
      white-space: nowrap
      z-index: 100
      .new
        position: absolute
        left: -pxToRem(8)
        padding: pxToRem(10)
        z-index: 1000
      .dataSources-source-inner
        padding: pxToRem(9) 0
        overflow: hidden
      label
        font-weight: normal
        width: 100%
      .dataSources-source-hide
        display: none
        margin: 0 0 0 pxToRem(10)
        font-size: pxToRem(11)
        pointer-events: auto
      .dataSources-source-fade
        position: absolute
        margin-top: -pxToRem(18)
        right: pxToRem(40)
        width: pxToRem(20)
        height: pxToRem(20)
        pointer-events: none
    .dataSources-source-hover
      +border-radius(unquote("pxToRem(5)"))
      background: $alabaster
      padding: 0 pxToRem(9)
      border: pxToRem(1) solid $gallery
      z-index: 1000
      .new
        display: none
        padding: pxToRem(10) pxToRem(10) pxToRem(10) pxToRem(9)
      .dataSources-source-inner
        float: left
        width: auto
        overflow: visible
      .dataSources-source-hide
        display: inline
      .dataSources-source-fade
        display: none
    .dataSources-projects .dataSources-source-hover
      background: $black-squeeze
      border-color: $spindle
    #dataSources-shared .dataSources-source-hover
      background: $serenade
      border-color: $cashmere
    #dataSources-public .dataSources-source-hover
      background: $rice-flower
      border-color: $deco
    #dataSources-public .collapsible-title
      color: $olivine

  .dataSources-scrollBox-container
    position: relative
    overflow: hidden
    box-sizing: border-box
    left: -pxToRem(40)
    top: -pxToRem(8)
    padding-top: pxToRem(8)
    padding-left: pxToRem(40)
    margin-right: -pxToRem(40)

    &.dataSources-hoverBox
      width: pxToRem(1000)
      padding-right: pxToRem(686)
      pointer-events: none
      .dataSources-source-fade
        display: none
  .dataSources-scrollBox
    margin: 0 -pxToRem(40)
    padding: 0 0 0 pxToRem(40)
    position: relative
    max-height: pxToRem(412)
    overflow-x: hidden
    overflow-y: auto
    pointer-events: all
    width: 100%

.project-summary, .vault-summary, .shared-summary, .public-summary
  font-weight: bold

.dataSourcesFakeLink
  color: $ui
  &:hover
    text-decoration: underline
  cursor: pointer

#manageAccess
  margin: pxToRem(25) 0 0 0

#dataSources-currentSelection
  margin: pxToRem(20) 0
  padding: pxToRem(10) 0 pxToRem(10) pxToRem(6)
  background: $alabaster
  color: $dim-gray
  line-height: pxToRem(15)
  width: auto
  font-size: pxToRem(11)
  h4
    margin: 0
    font-size: pxToRem(11)
  .hr
    margin: pxToRem(10) 0
    border-color: $sidecar
  .collapsible-inner
    padding-right: pxToRem(10)

#dataSources #dataSources-currentSelection
  margin: 0 0 pxToRem(10) -pxToRem(1)
  padding: pxToRem(15)
  width: pxToRem(169)
  font-size: pxToRem(12)
  border-radius: pxToRem(3)

#dataSources-createNew
  position: relative
  #dataSources-createNew-link
    display: block
    padding: pxToRem(14) pxToRem(14) pxToRem(14) pxToRem(16)
    background: $ui url("../images/cdd30/layout/datasources/create-new-bg.svg") no-repeat top right
    background-size: pxToRem(500) pxToRem(120)
    font-weight: bold
    font-size: pxToRem(12)
    line-height: 1rem
    color: $white
    border-radius: pxToRem(3)
    margin-bottom: pxToRem(10)
  #dataSources-createNew-link:hover
    background-position: right -pxToRem(40)
    text-decoration: none
    color: $white
  #dataSources-createNew-menu
    display: none
    margin: 0
    padding: 0
    position: absolute
    top: pxToRem(42)
    left: 0
    width: $sidebar-width
    list-style: none
    z-index: 2000
    box-shadow: 0 pxToRem(2) pxToRem(2) $black_20
    li
      margin: 0
      padding: 0
      font-size: pxToRem(12)

      &:first-child
        a
          border-radius: pxToRem(3) pxToRem(3) 0 0

      &:last-child
        a
          border-radius: 0 0 pxToRem(3) pxToRem(3)
      .disabled
        background-color: $shuttle-gray
        color: #ccc
      .disabled:hover
        color: #ccc
        background-color: darken($shuttle-gray, 10%)
      a
        display: block
        padding: pxToRem(13)
        background: $denim
        border-top: pxToRem(1) solid $tory-blue
        color: $white
      a:hover
        background: $tory-blue
        border-top: pxToRem(1) solid $venice-blue
        color: $white
        text-decoration: none
      // The ? image to the right of add new molecule when disabled
      img[title]
        padding: pxToRem(13)

#dataSources-createNew.open
  #dataSources-createNew-link,
  #dataSources-createNew-link:hover
    background-position: right -pxToRem(80)
    color: $white
  #dataSources-createNew-menu
    display: block
