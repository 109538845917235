.muiDialog-title
  background-color: $ui
  border-top-right-radius: pxToRem(2)
  border-top-left-radius: pxToRem(2)
  padding: pxToRem(10) pxToRem(20)
  color: $alabaster
  font-size: pxToRem(20)
  text-shadow: 0 1px 0 rgba(0,0,0,0.5)
.muiDialog-content-400
  min-width: 400
