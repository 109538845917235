#plate-details
  .main
    .subcontainer
      margin: 0 0 pxToRem(25) 0
      &#plate-runs
        margin: 0

// Definition

#plate-definition
  #plate_data_table_name,
  #plate_data_table_location
    @extend %break-word

#plate-definitionAndFiles
  th
    width: pxToRem(150)

// Plate map

.bigDataTable.plate-map-container
  .plateLayout
    margin: 0
    width: pxToRem(890)
    td, th
      padding: pxToRem(4) pxToRem(5) pxToRem(2) pxToRem(5)
  .cells
    margin: 0
  .corner
    padding: pxToRem(6) pxToRem(4) pxToRem(4) pxToRem(6)
    border-style: solid
    border-width: 0 pxToRem(2) pxToRem(2) 0
    border-color: $mercury

// Plate layout

.plateLayout
  margin: 0 0 pxToRem(15) 0
  background: $mercury
  border-collapse: separate
  border-spacing: pxToRem(2)
  border: none
  width: 100%
  td, th
    border: pxToRem(4) solid $alice-blue
    padding: pxToRem(2) pxToRem(2) pxToRem(1) pxToRem(2)
  td
    margin: 0
    font-weight: normal
    border: pxToRem(4) solid $concrete
    text-align: center
    background: $white
    font-size: pxToRem(10)
    width: 4%
  th
    margin: 0
    font-size: pxToRem(12)
    width: 4%
  thead
    th
      vertical-align: middle
      text-align: center
      font-size: pxToRem(10)
      background: $alice-blue
      font-weight: normal
    td
      border: none
      background: $white
  tbody
    th
      vertical-align: middle
      text-align: right
      font-size: pxToRem(10)
      background: $alice-blue
      font-weight: normal
    td
      text-align: center
  .well
    background: $white
    cursor: default
  .empty
    background: $white
  &.plateLayout-1536Wells
    border: none
    border-spacing: pxToRem(1)
    td,th
      border: pxToRem(1) solid $concrete
      padding: 0
      width: pxToRem(9)
      height: pxToRem(11)
      font-size: 0.500rem
      text-align: center

form.new_control_layout,form.edit_control_layout
  table.plateLayout
    td,th.well-column-header,th.well-row-header
      cursor: pointer

.wells_legend
  float: left
  width: 15%
  h3
    margin: 0 0 pxToRem(10) 0
  .item
    margin: 0 0 pxToRem(10) 0
    float: left
    padding: pxToRem(4) 0 0 0
    width: 100%
  .example
    margin: -pxToRem(4) pxToRem(7) 0 0
    clear: left
    float: left
    width: pxToRem(20)
    height: pxToRem(20)

.plateLayout td.positive, .wells_legend .positive, #legend td.positive
  border: pxToRem(4) solid $red-orange

.plateLayout td.reference, .wells_legend .reference, #legend td.reference
  border: pxToRem(4) solid $sunglow

.plateLayout td.negative, .wells_legend .negative
  border: pxToRem(4) solid $dodger-blue

.plateLayout-1536Wells td.positive, .plateLayout-1536Wells td.negative, .plateLayout-1536Wells td.reference
  border-width: pxToRem(1)

#legend
  border: none
  border-collapse: separate
  border-spacing: pxToRem(0) pxToRem(5)
  td.negative
    border: pxToRem(4) solid $blue
  th
    font-weight: normal
    border: pxToRem(4) solid $white
    vertical-align: center
  td
    font-size: pxToRem(24)
    font-weight: bold
    padding: pxToRem(5) pxToRem(8)
    border: pxToRem(4) solid $alto
    text-align: center
    background: $gallery
    width: pxToRem(20)

span
  &.positive
    color: $red
    font-weight: bold
  &.reference
    color: $lime
    font-weight: bold
  &.negative
    color: $blue
    font-weight: bold

.form-submit
  margin-top: pxToRem(20)
  text-align: right
  background-color: inherit

// Editing

.plateLayout-instructions
  margin: 0 0 pxToRem(15) 0
  height: auto
  padding: pxToRem(15)
  text-align: center
  p
    margin: 0
    padding: 0
  .plateLayout-instructions-positive
    font-weight: bold
    color: $red-orange
  .plateLayout-instructions-reference
    font-weight: bold
    color: $sun
  .plateLayout-instructions-negative
    font-weight: bold
    color: $dodger-blue
  .plateLayout-instructions-noControl
    font-weight: bold
    color: $gray

.editablePlateLayout
  td, th
    cursor: pointer
  td:hover
    background: $serenade !important
    border-color: $sandy-beach !important
  th:hover
    background: $serenade !important
    border-color: $serenade !important

// Heat map

.heatMap-container
  .heatMap-accessories
    float: left
    width: 100%
    padding-bottom: pxToRem(20)
    line-height: 1.2rem
  .heatMap-scatterPlotLink
    float: left
    width: 30%
    font-weight: bold
    font-size: pxToRem(13)
  .heatMap-stats
    float: left
    width: 50%
    .item
      margin: 0 pxToRem(20) 0 0
      float: left

.heatMap-wrapper
  background: $mercury
  padding: pxToRem(20)

.heatMap
  margin: 0 auto
  width: auto
  border-spacing: pxToRem(2)
  background: transparent
  td, th
    border-width: pxToRem(1) !important
    border-color: $silver
    padding: 0
    width: pxToRem(30)
    height: pxToRem(30)
    line-height: pxToRem(30)
  &.heatMap-96
    td
      width: pxToRem(64)
      height: pxToRem(64)
      line-height: pxToRem(64)
  &.heatMap-1536
    td, th
      width: pxToRem(14)
      height: pxToRem(14)
      line-height: pxToRem(14)
    thead th
      padding: 0
    .innerWell
      width: pxToRem(6)
      height: pxToRem(6)
  // 384 well plates have default td/th dimensions
  thead th, tbody th
    background: transparent
    border: none
    font-size: pxToRem(10)
    color: $dim-gray
    width: auto
    height: auto
  thead th
    padding: 0 0 pxToRem(4) 0
    line-height: 1rem
  tbody th
    padding: 0 pxToRem(6) 0 0
  tbody td
    cursor: pointer
    &.has-outlier-readouts
      background-image: url("../images/layout/icons/outlier_well.svg")
      background-size: cover
      background-repeat: no-repeat
  .innerWell
    margin: 0 auto
    border: pxToRem(1) solid $silver
    width: pxToRem(14)
    height: pxToRem(14)
    background: $your-pink
  .positive .innerWell
    border-color: $red-orange
  .reference .innerWell
    border-color: $sunglow
  .negative .innerWell
    border-color: $dodger-blue

// Multiple heat maps

#heatMap-multiple-hr
  margin: 0

.heatMap-multiple
  width: pxToRem(915)
  .heatMap-multiple-heatMap
    margin: pxToRem(25) pxToRem(25) 0 0
    float: left
    width: pxToRem(430)
  .heatMap-wrapper
    margin: pxToRem(10) 0
    padding: pxToRem(7) pxToRem(10) pxToRem(10) pxToRem(8)
    height: pxToRem(298)
  .heatMap
    border-spacing: pxToRem(1)
    &.heatMap-96
      td
        width: pxToRem(31)
        height: pxToRem(31)
        line-height: pxToRem(31)
    // th the same for both 96 and 384 well plate
    &.heatMap-384
      td, th
        width: pxToRem(14)
        height: pxToRem(14)
        line-height: pxToRem(14)
    &.heatMap-1536
      td, th
        width: pxToRem(5)
        height: pxToRem(6)
        line-height: pxToRem(6)
      tbody th, thead th
        font-size: 0.375rem
    thead th
      padding: 0
      border-top: pxToRem(1) solid $mercury
      border-bottom: pxToRem(1) solid $mercury
    tbody th
      padding: 0 pxToRem(4) 0 0
      border-top: pxToRem(1) solid $mercury
      border-bottom: pxToRem(1) solid $mercury
    .innerWell
      width: pxToRem(6)
      height: pxToRem(6)
  .heatMap-multiple-stats
    text-align: center
    color: $dim-gray
    font-size: pxToRem(11)

.plate-loading
  padding: pxToRem(140) 0 0 0
  text-align: center
  font-size: 1.375rem
  color: $tundora
  img
    margin: 0 pxToRem(10) 0 0
    vertical-align: pxToRem(3)

.controlLayout-loading
  padding: pxToRem(118) pxToRem(100) pxToRem(119) pxToRem(100)

.details-popup
  padding: pxToRem(10)
  a
    line-height: 1.1rem
    color: $ui
  a:hover
    color: $ui_hover
  a:active
    color: $ui
  h3
    margin: 0 0 pxToRem(10) 0
    a
      font-size: pxToRem(13)
  .thumbnail
    margin: 0 0 pxToRem(10) 0
    padding: pxToRem(1)
    border: pxToRem(2) solid transparent
    cursor: pointer
  .thumbnail:hover, a:hover .thumbnail
    border-color: $ui_hover
  ul
    list-style: none
    padding-left: 0pt
    li, strong
      color: $jambalaya
    li
      margin: 0 0 pxToRem(5) 0

// Heat map viewer

#readout-selection
  padding: pxToRem(5)
  select
    height: pxToRem(20)
    max-width: pxToRem(300)
