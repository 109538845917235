.mixture_editor
  background: $alabaster
  border: pxToRem(1) solid $gallery
  text-align: center
  cursor: pointer
  border-radius: pxToRem(10)

  .mixture_editor_icon
    fill: $ui

  #mixture_editor_launcher
    padding: 1.125rem
    border: none

  .launch_mixture_editor
    display: flex

    .instructions
      white-space: nowrap

    svg
      height: pxToRem(30)
      width: pxToRem(30)
      padding-left: pxToRem(40)

  h4
    display: inline-block
    margin: 0
    float: left
    padding-left: pxToRem(10)
    height: pxToRem(30)
    line-height: pxToRem(30)
    font-weight: bold
    font-size: 1.125rem

  &:hover
    border-color: $ui_hover-old

    .mixture_editor_icon
      fill: $ui_hover-old

img#mixture_query
  max-width: 100%
  max-height: 100%

// New Molecule Form Mixture Editor Launcher
#molecule-new .mixture_editor
  float: left
  margin-right: pxToRem(10)
  background: $white
  border-color: $alto

  .launch_mixture_editor
    svg
      padding: pxToRem(3) 0
      height: pxToRem(24)
      width: pxToRem(24)

  #mixture_editor_launcher
    padding: pxToRem(10)
    width: pxToRem(243)

  &:hover
    border-color: $ui_hover-old

//Search Page Mixture Editor Launcher
.column1 .mixture_editor
  margin: 0

  .inner
    padding: pxToRem(5) 0

//Admin Page Salts Structure Editor Launcher
.sidebar .mixture_editor
  width: pxToRem(290)

  .inner
    padding: pxToRem(5) 0

  .launch_mixture_editor
    svg
      padding-left: pxToRem(20)
