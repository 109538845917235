.activityLog
  margin: 0 0 pxToRem(15) 0
  border-top: pxToRem(1) solid $mercury
  border-collapse: collapse
  width: 100%

  td
    border-bottom: pxToRem(1) solid $mercury
    padding: pxToRem(10)
    vertical-align: top

  .what
    width: pxToRem(70)
    padding: pxToRem(6) 0 0 0

    span
      display: block
      color: $white
      padding: pxToRem(6)
      font-size: pxToRem(10)
      text-align: right

  .item
    line-height: 1.2rem

  .time
    text-align: right
    font-size: pxToRem(11)
    color: $dusty-gray
    white-space: nowrap

  .action-protocol
    background-color: $bright-red

  .action-readouts
    background-color: $coral-tree

  .action-run
    background-color: $well-read

  .action-molecule
    background-color: $limeade

  .action-batch
    background-color: $atlantis

  .action-communication
    background-color: $astral

  .action-user
    background-color: $violet-eggplant

  .action-file
    background-color: $bermuda-gray

  .action-slurp
    background-color: $tenn

  .action-snapshot
    background-color: $dark-blue
