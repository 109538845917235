.list
  padding: 1rem 0

.list__unstyled
  margin-left: 0
  padding-left: 0
  list-style-type: none

  li
    margin: 0
