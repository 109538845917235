// Big data table

.bigDataTable
  width: 100%
  position: relative
  overflow: hidden

  .cells
    margin: 0 0 pxToRem(15) 0
    overflow: auto

  .columnHeaders, .rowHeaders, .corner
    position: absolute
    overflow: hidden
    top: 0
    left: 0
    background: $white
    .container &
      background: $alabaster
    .subcontainer &
      background: $alabaster
    table
      position: relative
      left: 0

  .dataTable
    margin: 0
    top: 0
