.link-suggestion__dropdown
  max-height: calc(pxToRem(48) * 10)
  overflow-y: auto

  em
    font-style: normal
    font-weight: 600

  div[role="progressbar"]
    flex-grow: 1

  .noDataMessage
    margin-bottom: 0

.link-suggestion
  width: 100%
  position: relative

  .link-suggestion__content
    position: absolute
    text-align: left
    white-space: normal
    right: -pxToRem(5)
    width: calc(100% - pxToRem(140))

  .ChemistryImage
    max-width: pxToRem(120)
    svg
      height: pxToRem(80)
      max-width: pxToRem(120)
      margin-right: pxToRem(15)

.link-suggestion img
  margin-right: pxToRem(8)
  height: pxToRem(32)
  max-width: 100%

.link-suggestion__caption-text
  color: $tenn

#idAutoCompleteStructureLookup-popup
  li
    background-color: $white
