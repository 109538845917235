@import ../../../assets/stylesheets/base/_variables

.EditFieldDefinitionList
  thead
    tr
      th
        border-bottom: pxToRem(1) solid $th-border

  tbody
    tr
      border-bottom: pxToRem(1) solid $bg
  th
    white-space: nowrap
    color: $th

  td[name="is_sample_id"]
    white-space: nowrap
    text-align: center

  td, th
    font-size: pxToRem(12)!important
    padding-top: pxToRem(5)
    padding-bottom: pxToRem(5)
    padding-left: pxToRem(10)
    padding-right: pxToRem(10)

    cursor: auto!important

    .cursor-text
      cursor: text

    input, select
      width: 100%

    .pick-list-edit
      right: pxToRem(15)

    h3
      margin: 0

    .unique
      min-width: pxToRem(100)

    .checkmark-cell
      text-align: center
      width: 100%
      display: inline-block

  .warning-row
    height: 0
    margin: pxToRem(2)

  .removed_notice
    font-size: pxToRem(14)
    display: flex
    width: 100%
    justify-content: center
    text-align: center

  .fake_data_table__add_row
    .row
      .inner
        display: inline-block
        position: relative
        padding: pxToRem(10) 0 pxToRem(10) pxToRem(10)

  .uniqueness-note
    padding-top: pxToRem(20)

  .single-use-option
    .single-use-checkbox
      label
        font-weight: normal
      input
        width: unset

