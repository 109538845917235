.inventory-wrapper
  position: relative

  h2
    display: inline-block
    margin-bottom: 0
    line-height: pxToRem(24)
  .inventory-state
    float: right
    .current-amount, .current-location, .debit-amount, .new-location
      float: left
      margin-right: 0.8rem
      line-height: pxToRem(24)
    .current
      float: right
      .current-amount, .current-location
        span
          font-weight: bold
          margin-right: 0.2rem
      .current-location:before
        content: '•'
        color: $periwinkle-gray
        padding-right: .8rem
      a
        float: left
        cursor: pointer
        margin-left: pxToRem(10)
    .update
      display: none
      float: right
      input[type="text"]
        margin: 0
        font-family: helvetica, arial, sans-serif
        width: pxToRem(92)
        font-size: pxToRem(12)
        padding: pxToRem(3) pxToRem(8)
      input[type="submit"]
        margin: 0
        height: pxToRem(25)
        padding: pxToRem(4) pxToRem(3) pxToRem(6) pxToRem(3)
        cursor: pointer
        z-index: 500
        min-width: pxToRem(59)
      .debit-amount, .new-location
        span
          font-weight: bold
          margin-right: 0.4rem
      .debit-amount
        position: relative
        z-index: 200
        input[type="text"]
          width: pxToRem(36)
          padding-right: pxToRem(56)
        .of-amount
          font-weight: normal
          line-height: pxToRem(24)
          color: $silver-chalice
          text-align: right
          width: auto
          position: absolute
          right: pxToRem(4)
          top: 0
        .overshoot
          color: $guardsman-red
      .cancel-update
        display: inline-block
        padding: 0.5rem 1rem
        line-height: 1
        cursor: pointer
        color: $color_warning

        &:hover
          color: $ui_hover

      .clearfix
        clear: both
      .errorMessage
        margin: pxToRem(10) 0 pxToRem(10) 0
        padding: pxToRem(0)
        background: none
        border: none
        text-align: right
        ul
          margin: 0
          padding: 0
          list-style-type: none
          li
            margin: 0 0 pxToRem(5) 0
  .collapsible-inner
    clear: both
    .no-inventory-updates
      text-align: center
      margin-top: pxToRem(20)
      .info
        color: $dusty-gray
        padding: pxToRem(5)
        font-size: pxToRem(14)
        text-align: center
        font-style: italic
    table#inventory-history
      margin-left: pxToRem(10)
      margin-bottom: 0
      width: calc(100% - pxToRem(10))

.slide-in-right
  animation: slide-in-right .2s ease

@keyframes slide-in-right
  0%
    transform: translateX(20%)
    opacity: 0
  100%
    transform: translateX(0%)
    opacity: 1

.slide-in-left
  animation: slide-in-left .2s ease

@keyframes slide-in-left
  0%
    transform: translateX(-20%)
    opacity: 0
  100%
    transform: translateX(0%)
    opacity: 1

.collapsible-open
  h2.collapsible-title
    background: url("../images/cdd30/layout/collapsible/opened-mid.svg") no-repeat pxToRem(2) pxToRem(5)
    cursor: pointer

.collapsible-closed
  h2.collapsible-title
    background: url("../images/cdd30/layout/collapsible/closed-mid.svg") no-repeat pxToRem(2) pxToRem(5)
    cursor: pointer
