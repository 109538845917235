.search-bar
  margin-bottom: 1rem

  .datepicker
    max-width: 10rem

.search-bar + .dataTable
  top: 0

.search-bar__form
  display: flex
  flex-direction: column

  > div
    box-shadow: none

    > .search-bar__summary
      min-height: unset
      background-color: transparent
      padding: 0.5rem 0

      > div
        margin: 0

  .search-bar__filters
    padding: 0

.search-bar__summary
  display: flex
  justify-content: space-between
  align-items: baseline

.search-bar__summary_text
  display: flex
  flex-direction: column

.search-bar__entries_count
  padding-top: 1rem
  font-weight: 600
  text-align: right
  margin-bottom: 0

.search-bar__export_link
  padding-top: 1rem
  font-weight: 600
  text-align: right
  margin-bottom: 0

  &.hidden
    display: none

  &.disabled img
    filter: grayscale(100%) brightness(1.4)

.search-bar__container
  display: flex
  position: relative
  width: 100%

.search-bar__input
  margin: 0 0.5rem
  padding: pxToRem(8) pxToRem(10) pxToRem(12)
  padding-right: pxToRem(140)
  font-size: pxToRem(14)
  color: $color
  flex-grow: 1
  border: pxToRem(1) solid $border
  // The left and right border was hidden intentionally, but per Kellan we should show it:
  // border-left: 0
  // border-right: 0
  border-radius: 0
  text-overflow: ellipsis
  overflow: hidden
  border-radius: 0 pxToRem(2) 0 0

  &::placeholder
    font-weight: 300
    color: $regent-gray

  &:focus
    outline: pxToRem(2) solid $ui_focus

.search-bar__submit
  border-radius: 0 pxToRem(2) pxToRem(2) 0

  &:hover
    text-decoration: none
    background: $japanese-laurel !important

  i
    padding-bottom: pxToRem(2)

.search-bar__substructure
  display: inline-flex
  position: absolute
  right: pxToRem(45)
  align-items: center

.search-bar__substructure__button
  margin: 0 !important
  background: transparent !important

  svg
    fill: $ui
    height: pxToRem(16)
    width: pxToRem(16)

  &:hover,
  &:focus,
  &:active

    svg
      fill: $ui_hover

.search-bar__substructure__moleculesearch
  background: $black-squeeze
  border-radius: pxToRem(2)
  padding: pxToRem(6) pxToRem(8) pxToRem(7)
  color: $mine-shaft
  height: pxToRem(15)

.search-bar__filter__chip
  background: $black-squeeze
  border-radius: pxToRem(2)
  color: $mine-shaft
  cursor: pointer

.search-bar__filter
  background: $bg
  border-radius: pxToRem(2) 0 0 pxToRem(2)

.search-bar__filter__summary
  display: flex
  align-items: baseline
  cursor: pointer

  .search-bar__summary__text
    font-weight: 600

.search-bar__filter__open
  position: absolute
  margin-top: pxToRem(39)
  width: $container-width
  z-index: 1

.search-bar__filters
  display: flex
  flex-direction: column
  flex-grow: 1

  .filter-field-select
    min-width: 8rem

  .add-associated-model
    padding: 1rem

    span
      margin-left: pxToRem(3)

  .filter-to
    padding-left: pxToRem(8)
    padding-right: pxToRem(8)
    top: pxToRem(10)
    position: relative

.search-bar__filters__actions
  display: flex
  justify-content: space-between
  align-items: center

.search-bar__filters__toggle
  line-height: 1
  cursor: pointer
  padding-top: pxToRem(1)

.search-bar__filters__item
  display: flex
  align-items: center
  padding: 0.5rem 0

.search-bar__filters__icon
  padding: 0.5rem
  padding-left: 1rem

.search-bar__filters__field
  display: flex
  align-items: flex-end

  .filter-field-select, .filter-style-select
    padding-right: pxToRem(10)

  .filter-style-select
    min-width: 8rem

    // an ugly fix, but makes the select fields line up
    & > div > div > div
      margin: 0

// couldn't figure out how to style selected filter : selectedMenuItemStyle didn't work

.search-bar__filters__author,
.search-bar__filters__status

  > div > div
    margin-top: pxToRem(5)

  svg
    width: 0.75rem
    top: 0.25rem

.filter__value__select
  font-size: pxToRem(14)
  line-height: 1.25
  border: 0
  min-width: 8rem

.filter__value__text input
  font-size: pxToRem(14)
  line-height: 1.75 !important
  border: 0 !important

  &:focus
    outline: 0 !important
    background: $black_05
    color: $black

.search-bar__filters__top-level-category
  opacity: 1

  span
    color: $black
.search-bar__filters__second-level-category
  opacity: 1

  padding-left: 2rem
  span
    color: $black

.search-bar__filters__field-name
  padding-left: 2rem
