.column-editor-menu
  ul
    padding: 0
    min-width: 12rem

    li
      font-size: pxToRem(12)

    .search_results__columns__project-name
      opacity: 1!important
      padding-top: .25rem
      padding-bottom: .25rem
      padding-left: 1rem
      font-weight: bold

    .search_results__columns__field-name
      padding-left: 1rem
      padding-top: .5rem
      padding-bottom: .5rem
