.CDDFormStories
  .border
    border: pxToRem(1) black solid

  .stepper-page-container
    height: pxToRem(100)!important

  .hover-json
    margin-top: pxToRem(10)
    p
      padding: pxToRem(4)
      text-align: center
      border-radius: 50%
      background-color: lightyellow
      border: pxToRem(1) gray solid
