.DownloadMoleculeImage
  min-width: pxToRem(600)
  .tabs
    background-color: transparent
    margin-bottom: pxToRem(20)

  .downloadLink
    display: block
    padding: pxToRem(8)
    text-align: center
    margin: pxToRem(20)

  .ChemistryImage
    svg
      width: 100%
      max-height: pxToRem(300)

  .source-image-container
    position: fixed
    visibility: hidden

  .displayed-image-container
    display: flex
    justify-content: center
    min-height: pxToRem(182)

  img
    object-fit: contain
    width: pxToRem(500)
    max-height: pxToRem(500)

.DownloadMoleculeImage-tabbar
  display: flex
  justify-content: center
  align-items: center

.DownloadMoleculeImage-sizing
  display: flex
  justify-content: center
  align-items: center
  gap: 1rem
  margin-top: 1rem

.DownloadMoleculeImage-disabled
  color: $silver-chalice

.DownloadMoleculeImage-size-input
  width: 6rem
  padding: 0.1rem
  font: inherit

.DownloadMoleculeImage-textraw
  padding: 0.1rem

.DownloadMoleculeImage-center
  display: grid
  grid-template-columns: [start] 1fr [end]
  align-items: center

.DownloadMoleculeImage-centrepoint
  grid-area: 1 / 1
  text-align: center
  position: relative

.DownloadMoleculeImage-outline
  display: inline-block
  position: relative
  border: pxToRem(1) dotted $anakiwa
  padding: pxToRem(1)

.DownloadMoleculeImage-outline-mouseover
  position: absolute

.DownloadMoleculeImage-scalenote
  color: $anakiwa
  position: absolute
  bottom: -1rem
  right: 0

.DownloadMoleculeImage-downloadline
  display: flex
  justify-content: center
  gap: 1rem

.DownloadMoleculeImage-unclickableLink
  color: $hit-gray
  cursor: not-allowed

.DownloadMoleculeImage-minchi
  white-space: normal
  word-break: break-all

.DownloadMoleculeImage-minchiref
  margin-top: 1rem
  padding-top: 1rem
  border-top: pxToRem(1) solid $gray

.DownloadMoleculeImage-alert, .CopyToClipboard-alert
  left: 0
  bottom: 0
  pointer-events: none
  position: fixed
  width: 100%
  z-index: 1500

.DownloadMoleculeImage-notation-grid
  display: grid
  gap: 0.5rem
  justify-items: start
  align-items: baseline
  grid-template-columns: [start label] auto [value] 1fr [download] auto [copy] auto [end]

.DownloadMoleculeImage-readonly-input
  padding: 0.1rem
  font-family: monospace
  justify-self: stretch

.DownloadMoleculeImage-tipfill
  width: 100%
  height: 100%
