.equation
  .where
    padding-top: pxToRem(12)
    line-height: 150%
    img
      vertical-align: center
      margin-left: pxToRem(12)
      padding-right: pxToRem(3)

.section__help
  p img
    width: 100%
    &.framed
      display: block
      margin: pxToRem(30) auto
      border: pxToRem(2) solid $alto
      width: 90%
    &.icon-16
      width: auto

  ul
    margin: -pxToRem(5) 0 pxToRem(30) pxToRem(20)

  .message
    margin: pxToRem(24)
