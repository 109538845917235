
.SampleDataView

  .section__header
    margin-bottom: 0
    .editableData-editLink
      padding-top: pxToRem(15)
      float: right
      position: relative
      right: pxToRem(40)

  .MultipleTablesWithStickyHeaders
    .header-row
      pointer-events: none

    .sample-icon-button
      margin-right: pxToRem(10)

    .single-use-depleted
      td
        text-decoration: line-through
        font-style: italic
        pointer-events: none
        cursor: not-allowed

      td:last-child
        pointer-events: all !important
        cursor: pointer

    .depleted
      .additionalHeaderElements
        .label-text, .value-text, td
          text-decoration: line-through

      tbody
        td
          text-decoration: line-through
          text-decoration: line-through
          font-style: italic
          pointer-events: none

    .sticky-header-additional-row
      margin-left: pxToRem(15)
      opacity: .6

    .sticky-header-container
      pointer-events: all

    .section__header
      padding: 0

    .noDataMessage
      color: black

    .label-text
      font-weight: bold
      padding-right: pxToRem(8)

    .value-text
      font-weight: normal

    .additionalHeaderElements
      font-weight: normal !important
      flex-grow: 1

      display: inline-block
      label
        margin-right: pxToRem(20)

      .disabled-icon
        opacity: .5
        width: pxToRem(24)
        height: pxToRem(24)
        display: inline-block
        img
          vertical-align: middle!important
          padding-left: pxToRem(3)

      .left
        float: left
      .right
        float: right
        button
          margin-left: pxToRem(8)

      .parent
        display: flex
        align-items: flex-start
        widtH: 100%

      .left-container
        order: 1
        width: 100%

      .right-container
        order: 2
        margin-left: auto

      .edit-sample
        white-space: nowrap
        margin-left: pxToRem(20)

.EditInventoryEventDialog
  .top-row
    margin-top: pxToRem(12)

  .creditOrDebitValue
    p
      width: pxToRem(500)
      display: inline-block
      margin-bottom: pxToRem(10)
      min-height: pxToRem(40)

.EditInventorySampleDialog
  .main-dialog-paper-wide
    min-width: calc(100% - 20rem)
    max-width: 60rem

  a
    white-space: nowrap

  .unitsSelect
    input
      padding-top: pxToRem(3) !important
      padding-bottom: pxToRem(3) !important

  .eventFieldsColumn
    margin-left: 3rem

.EditInventoryEventDialog, .EditInventorySampleDialog
  .main-dialog-paper
    min-width: pxToRem(800)
  .top-row
    .material-input
      text-align: right

  .bottom-modal-row
    margin-top: 2rem

.field_file_uploader
  min-height: 5rem
  td
    padding-left: pxToRem(4)
    padding-right: pxToRem(10)
.element-date
  max-width: 10rem !important

.SampleDataView-readonly
  table
    tr
      pointer-events: none
