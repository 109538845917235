.editor
  select
    border: none !important
    color: $ui
    outline: none
    padding: 0 pxToRem(24) 0 0 !important
    cursor: pointer
    height: inherit !important
    font-family: $font-family

    &:hover,
    &:active,
    &:focus
      color: $ui_hover
      outline: none
