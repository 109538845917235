// show mode
.elnEntry__show #elnEntryFields
  margin-top: 0
  margin-bottom: 2rem
  font-size: 1rem

  > div
    height: auto

    > dl
      width: 100%

      dd
        font-size: 1rem
        padding: 0

#elnEntryFields
  display: flex
  margin: pxToRem(5) -pxToRem(20) pxToRem(30) 0
  flex-wrap: wrap

  // overwrite material default font-weight: bold
  label
    font-weight: normal

  > div
    display: inline-flex
    margin-right: pxToRem(20)
    width: pxToRem(220)
    height: pxToRem(67)
    margin-top: -pxToRem(15)

    > div
      width: 100%
      margin-top: pxToRem(16)
      margin-bottom: pxToRem(6.5)

  .input-container-file
    border-bottom: pxToRem(1) solid $material-hr

    &:hover,
    &:focus,
    &:focus-within
      .upload-file-text
        color: $ui_hover-old
        text-decoration: underline

    label
      display: flex
      margin-bottom: pxToRem(12)
      margin-top: pxToRem(1)
      color: $material-label
      font-size: 0.563rem
      height: pxToRem(9)
      align-items: center

    span
      display: flex

    a
      display: flex

    .upload-file-text
      display: block
      margin-left: pxToRem(2)
      font-size: pxToRem(12)
      line-height: 1.25
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

    .uploaded-file--no-preview
      display: block
      content: "&nbsp;"

    .icon-16
      height: pxToRem(13)
      width: pxToRem(13)

  #project-field, .eln-fields__pick-list
    width: pxToRem(215) !important

  #project-field
    padding-right: pxToRem(6)
    background: transparent

  .eln-fields__pick-list > div

    &:focus
      background: transparent

// datepicker
#elnEntryFields

  .datepicker

    input[type="text"]
      background: transparent
      border: none
      padding: pxToRem(6) 0 pxToRem(7) // mui default

      &:focus
        outline: none

    button
      margin-bottom: pxToRem(4)
      margin-right: pxToRem(4)

    .material-icons, i
      font-size: pxToRem(12) !important
