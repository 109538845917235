// data ownership
.dataOwnership
  margin: pxToRem(15) 0 0
  padding: 0 0 0 pxToRem(20)
  border-left: pxToRem(1) solid $gallery

  h4
    margin: 0 0 pxToRem(5)

  .vaultOrDataSetName
    font-weight: bold
    font-size: pxToRem(14)

  .titleTag
    vertical-align: pxToRem(1)
    padding: pxToRem(4) pxToRem(4) pxToRem(1) pxToRem(4)
    line-height: 1rem
