.ChemistryImage
  position: relative
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden

  svg
    height: 100%

  p, a
    text-align: center
    width: calc(100% - pxToRem(30))
