.link__dialog
  > div
    padding-top: pxToRem(0)
  .link-suggestion
    padding: pxToRem(16)

.link__custom-label
  display: flex
  margin-right: 0

.custom-label__radio
  align-self: flex-end

.custom-label__label
  flex-grow: 1

.link__label
  flex-grow: 1
  margin-top: 1.5rem

.default-label__label
  font-size: pxToRem(12)

  &:hover
    color: $ui

.structure-label__label
  border: pxToRem(1) solid $border
  border-radius: pxToRem(2)

  &:hover
    border-color: $ui

  img
    height: 10rem
    width: 10rem

.link__details
  position: relative

  .buttons-react-invert,
  .buttons-react
    margin-top: pxToRem(20)

.link__display-options
  margin-top: pxToRem(30)
  width: 100%
  white-space: pre-wrap
  overflow-wrap: break-word
  word-break: break-word
