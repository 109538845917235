.pickListItemTextBox
  margin: pxToRem(20) 0 pxToRem(0)
  padding: pxToRem(10)
  resize: none
  width: calc(100% - pxToRem(24))
  overflow: auto
  height: 1rem
  line-height: 1rem
  outline: none
  border: pxToRem(1) solid $border_dark
  border-radius: pxToRem(2)

  &:focus,
  &:hover
    outline: none
    border: pxToRem(1) solid $ui

.pickListEntryWrapper
  margin-bottom: 1rem

.pickListNotification
  top: 0
  position: relative
  font-size: pxToRem(14)
  height: 1rem
  padding-top: pxToRem(10)
  color: $color_warning

.pickListItem
  margin: 0 0 pxToRem(10)
  position: relative
  font-size: pxToRem(16)

  .pickListItemEditor
    background-color: $bg
    padding: pxToRem(10) 5rem pxToRem(10) pxToRem(10)
    width: 100%
    box-sizing: border-box
    border-radius: pxToRem(2)
    border: none

    &:focus,
    &:hover
      background-color: $ui
      outline: none
      color: $white

      + .pickListItemActions
        color: $white

    &.errors
      background-color: $bg_warning

      &:focus,
      &:hover
        background-color: $red

.pickListItemActions
  position: absolute
  float: right
  top: pxToRem(1)
  right: pxToRem(5)

  .fa
    padding: pxToRem(8)
    cursor: pointer

    &:hover
      color: $ui_hover

    &.disabled
      opacity: 0.5

  .alertIcon
    color: $color_warning

.pick_list__container
  overflow-y: unset

.pickListTitle, .pickListTitle
  background: $ui
  color: $white

.pickListActionsContainer
  border-top: none !important

  .pickListActions
    margin: pxToRem(12)
    height: pxToRem(32)

.pickListContent
  padding-bottom: pxToRem(0) !important
