// share
.new_snapshot
  .buttons-right
    flex-direction: column
    align-items: flex-end

    .orcancel
      white-space: nowrap

.new_private_snapshot-button
  height: 3rem
