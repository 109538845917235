.eln_search_results__table
  td
    border-bottom: none
    padding-top: pxToRem(9)
    padding-bottom: pxToRem(9)

.eln

  input, textarea
    color: $black

    &:hover,
    &:active,
    &:focus
      color: $ui !important

  a.cancel
    color: $color_warning

  header
    .title
      @extend %title-size

      &.title--readonly
        @extend %break-word

      textarea
        @extend %title-size
        overflow: hidden

  .inline-container--image
    svg
      height: pxToRem(250)

#elnEntries-show
  tbody
    tr:hover
      background: $black-squeeze
      cursor: pointer

.div--eln-entries-header-row
  display: table
  margin: 0 0 1.25rem
  width: 100%

.h2--eln-entries-title
  display: table-cell

.h3--eln-entries-import-link
  display: table-cell
  text-align: right
