.LaunchStructureEditor
  display: flex
  justify-content: center
  text-align: center
  color: #0077cc

  .structure_editor_icon
    fill: $ui

  #structure_editor_launcher
    padding: 1.125rem
    border: none

  h4
    display: inline-block
    margin: 0
    float: left
    padding-left: pxToRem(10)
    height: pxToRem(30)
    line-height: pxToRem(30)
    font-weight: bold
    font-size: pxToRem(14)

  &:hover
    border-color: $ui_hover-old

    .structure_editor_icon
      fill: $ui_hover-old

  .clickable
    background: $alabaster
    display: flex
    justify-content: center
    text-align: center
    cursor: pointer
    padding: pxToRem(12)
    border: pxToRem(1) solid $gallery
    border-radius: pxToRem(10)
    width: pxToRem(250)


    #structure_query
      width: pxToRem(250)
      height: pxToRem(250)

    &:hover
      border-color: $ui_hover-old
      .structure_editor_icon
        fill: $ui_hover-old

  .instructions
    white-space: nowrap

  .content_without_structure
    display: flex
    white-space: nowrap
    &[hidden]
      visibility: hidden
    svg
      height: pxToRem(30)
      width: pxToRem(30)
