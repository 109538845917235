// override eln ul styles
.editor__body .check-list,
.check-list
  margin: 0
  list-style: none
  padding: 0
  font-size: pxToRem(16)

.check-list__item
  display: flex
  margin: 0
  align-items: center

  > span
    margin: 0 0.25rem 0 0
    color: $denim !important // active state : slightly blue when checked and overrides material

    &:hover
      background: $material-bg

    svg
      color: $color

  > div
    padding: 0
