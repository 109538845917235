// Title tag and user tag

.titleTag
  padding: pxToRem(6) pxToRem(6) pxToRem(4) pxToRem(6)
  vertical-align: pxToRem(6)
  font-size: 0.563rem
  font-weight: normal
  text-transform: uppercase
  color: $white
  &.public
    background: $ui_hover
  &.shared
    background: $limeade

.userTag
  padding: pxToRem(3) pxToRem(3) pxToRem(2) pxToRem(3)
  vertical-align: pxToRem(1)
  font-size: 0.500rem
  font-weight: normal
  text-transform: uppercase
  &.core
    color: $white
    background: $ui
  &.pending
    color: $white
    background: $ui_hover
  &.disabled
    color: $white
    background: $boulder
