#vault-admin

  .container-content-inner
    float: left
    width: calc(100% - pxToRem(250))
    max-width: pxToRem(1024)

    .subcontainer
      padding: 0
      background: $white
      border: none

  .membership-actions
    display: flex
    justify-content: space-between

  .popup
    position: fixed

  #pickListValues
    margin-bottom: pxToRem(10)
