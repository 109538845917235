.letter
  width: 8.5in
  //height: 11in

  &.landscape
    width: 11in
    //height: 8.5in

.A4
  width: 21cm
  //height: 29.7cm

  &.landscape
    width: 29.7cm
    //height: 21cm

.portrait, .landscape
  display: none

@media print
  .screen
    display: none

  .portrait
    display: block
