.modal-open
  overflow: hidden

#modal-overlay
  display: flex
  position: fixed
  top: 0%
  left: 0%
  width: 100%
  height: 100%
  background-color: $black_40
  justify-content: center
  z-index: 1001

#modal-dialog, .modal-dialog
  align-self: center
  width: pxToRem(800)
  height: pxToRem(570)
  background: $white
  z-index: 1002
  max-height: calc(100vh - pxToRem(60))
  max-width: calc(100vw - pxToRem(60))
  min-height: pxToRem(275)
  min-width: pxToRem(275)

  .section
    height: calc(100% - pxToRem(55))
    margin-bottom: 0

  .section.buttons
    margin: 0
    padding: pxToRem(14) pxToRem(15) pxToRem(15)
    border-top: pxToRem(1) solid $mercury
    height: pxToRem(25)

    .orcancel
      line-height: 0.8rem

    .icon-16
      margin-top: pxToRem(4)

.custom-modal-overlay
  display: flex
  position: fixed
  top: 0%
  left: 0%
  width: 100%
  height: 100%
  background-color: $black_40
  justify-content: center
  z-index: 1001

.custom-modal-dialog
  align-self: center
  background: $white
  z-index: 1002
  max-height: calc(100vh - pxToRem(60))
  max-width: calc(100vw - pxToRem(60))
  min-height: pxToRem(275)
  min-width: pxToRem(275)

  .section
    height: calc(100% - pxToRem(55))
    margin-bottom: 0

  .section.buttons
    margin: 0
    padding: pxToRem(14) pxToRem(15) pxToRem(15)
    border-top: pxToRem(1) solid $mercury
    height: pxToRem(25)

    .orcancel
      line-height: 0.8rem

    .icon-16
      margin-top: pxToRem(4)
