#vaultDeletionNotice
  text-align: center
  font-size: pxToRem(19)

  .buttony
    float: right

form.edit_vault,
form.new_vault
  input[name="vault[structureless_image_name]"]
    display: none
  .structureless
    display: inline-block
    width: 4rem
    height: 4rem
    border: pxToRem(5.5) solid transparent
    float: left
    cursor: pointer
  input[name="vault[structureless_image_name]"]:checked ~ div
    border: pxToRem(2.5) solid $anakiwa
    padding: pxToRem(3)
  .structureless-row
    th
      width: 16.25rem
    td
      display: grid
      grid-template-rows: repeat(2, 1fr)
      grid-auto-flow: column
      grid-gap: 0.5rem
      justify-content: start
