// colors chosen for readability : not part of style guide or branding
$tamarillo: rgb(139,26,17) // #8b1a11
$alizarin-crimson: rgb(235,49,36) // #eb3124
$jaffa: rgb(242,156,57) // #F29C39
$ronchi: rgb(235,194,82) // #ebc252
$asparagus: rgb(120,166,91) // #78a65b
$smalt-blue: rgb(83,128,140) // #53808c
$havelock-blue: rgb(87,135,225) // #5787e1
$blue-cs: rgb(0, 38, 245) // #0026f5
$purple: rgb(140,48,245) // #8c30f5
$magenta: rgb(235,64,247) // #eb40f7


// toolbar
.colour-select
  display: flex
  margin: 0.75rem
  flex-direction: row

.colour-select__option
  margin: 0.25rem
  height: 2rem
  width: 2rem
  border-radius: pxToRem(2)
  cursor: pointer

  &.crimson
    background-color: $tamarillo
  &.red
    background-color: $alizarin-crimson
  &.orange
    background-color: $jaffa
  &.yellow
    background-color: $ronchi
  &.green
    background-color: $asparagus
  &.turquoise
    background-color: $smalt-blue
  &.cornflower
    background-color: $havelock-blue
  &.indigo
    background-color: $blue-cs
  &.purple
    background-color: $purple
  &.magenta
    background-color: $magenta
  &.black
    background-color: $black

  &:hover
    margin: 0.125rem
    height: 2.25rem
    width: 2.25rem
    box-shadow: 0 0 0.25rem $black_20


// editor
.text-colour__crimson
  color: $tamarillo
.text-colour__red
  color: $alizarin-crimson
.text-colour__orange
  color: $jaffa
.text-colour__yellow
  color: $ronchi
.text-colour__green
  color: $asparagus
.text-colour__turquoise
  color: $smalt-blue
.text-colour__cornflower
  color: $havelock-blue
.text-colour__indigo
  color: $blue-cs
.text-colour__purple
  color: $purple
.text-colour__magenta
  color: $magenta
.text-colour__black
  color: $black
