@import ../../../../assets/stylesheets/base/_variables.sass

.EditLocationsDialog
  .empty-location-button-container
    margin-top: 1rem
    button
      margin-left: 10%
      margin-right: 10%
      width: 80%

      .add-box-button-contents
        display: flex
        flex-direction: column
        align-items: center
        margin-left: 5rem
        margin-right: 5rem
        svg
          font-size: 3rem

  .contents
    background-color: $white
    overflow: hidden
    display: 'flex'
    flexDirection: 'row'

    .left-column
      width: 24rem

      .SortableTreeView
        overflow-y: auto
        overflow-x: hidden
        height: 100%

        .tree-view-dragging
          .tree-item-icons
            pointer-events: none

        .tree-item-contents
          display: flex
          width: 100%

          .label
            flex-grow: 1
            text-align: left
            text-overflow: ellipsis
            white-space: nowrap
            overflow: hidden

          .icons
            text-align: right
            white-space: nowrap

            .tree-item-icons
              display: inline-block
              padding-right: pxToRem(10)

              button:hover
                background-color: $ui_focus

              .disabled
                opacity: 0.5

              .disabled:hover
                pointer-events: none

              img, svg
                width: pxToRem(15)
                margin-left: pxToRem(3)
                margin-right: pxToRem(3)
                margin-top: pxToRem(0)

        .dragging-placeholder
          .tree-item-icons
            display: none

        .selected-edit-mode
          background-color: $ui
          color: $white
          font-weight: bold

    .right-column
      flex: 1
      padding-left: 2rem
      overflow: auto

      .LocationBoxPicker
        pointer-events: none

    .location-editor-container
      height: calc(100vh - 10rem)

    .mode-editor
      padding: pxToRem(10)
      white-space: nowrap
      .node-name
        width: pxToRem(200)
      .row-col-input
        width: pxToRem(80)
        margin-left: 1rem

  .bottom-modal-row
    button
      min-width: pxToRem(100)
      margin-left: pxToRem(8)
      margin-right: pxToRem(8)

