.ColumnsEditor-dialog
  > *
    user-select: none
  td
    padding-top: 0
    padding-bottom: 0
    height: 1rem

  tr
    td:first-child
      padding-left: 0
      padding-right: 0

  .group-name
    opacity: .5
    font-style: italic

  .dialog-contents
    padding-right: 2rem
    > div
      max-height: calc(100vh - 10rem)
