@import ../../../../assets/stylesheets/base/variables

.StickyDataTable
  border: pxToRem(10) solid #eee
  border-top: pxToRem(5)
  padding-top: 0

  .sticky-header
    display: flex
    margin-top: pxToRem(4)

    .dropdownOpen
      transform: rotate(0)

    .dropdownClosed
      transform: rotate(-90deg)

  .additional-header-row
    padding: 0
    background: #eee

  .right-margin
    margin-right: pxToRem(20)
  tr
    background-color: white!important
    padding-top: pxToRem(4)
    padding-bottom: pxToRem(4)

    &:hover
      background-color: $bg!important

    th
      padding-top: pxToRem(16)
      padding-bottom: pxToRem(8)

.MultipleTablesWithStickyHeaders
  .table-container
    margin-bottom: pxToRem(15)
