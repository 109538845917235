.div--eln-entries-import-header-row
  display: table
  width: 100%

.h2--eln-entries-import-title
  display: table-cell

.h3--eln-entries-import-new-link
  display: table-cell
  text-align: right
