@import ../../../../assets/stylesheets/base/_variables.sass

.ChangePassword

  table
    display: inline-block

.ChangePassword-tooltip
  font-size: pxToRem(12)

  ul
    color: $guardsman-red
    margin: 0

  .strength-meter
    display: flex
    width: 100%
    height: 2rem

    .left-label
      color: $material-label

    .right-label
      color: $material-label

    .bar
      flex: 1 1 auto
      position: relative
      background-color: white
      height: .8rem
      outline: pxToRem(1) solid $black_40
      padding: pxToRem(3)
      margin-left: pxToRem(6)
      margin-right: pxToRem(6)

      .fill
        height: 100%

    .score1, .score2
      .fill
        width: 33%
        background-color: red

    .score3
      .fill
        width: 66%
        background-color: yellow

    .score4
      .fill
        width: 100%
        background-color: green

