th
  .header
    position: relative
    padding: pxToRem(5) pxToRem(10)
    z-index: 2
    height: auto
    float: none
    width: auto
    color: $cod-gray

#search_structure_form_content
  max-width: $max-narrow-form-width

#search_results_table
  @mixin single_sticky
    position: sticky
    z-index: 3

  @mixin double-sticky
    position: sticky
    z-index: 4

  .column_1_sticky
    @include single_sticky
    left: 0

  .column_2_sticky
    @include single_sticky
    left: pxToRem(70)
    border-right: 2px solid $dove-gray

    &:last-child
      border-right: none

  .row_1_sticky_column_helper
    @include double-sticky
    left: pxToRem(10)

  .row_1_sticky
    @include double-sticky
    top: 0

  .row_2_sticky
    @include single_sticky
    top: pxToRem(47)

  .row_3_sticky
    @include single_sticky
    top: pxToRem(70)

  .column_1_sticky.row_2_sticky,
  .column_1_sticky.row_3_sticky,
  .column_2_sticky.row_2_sticky,
  .column_2_sticky.row_3_sticky
    @include double-sticky

th.topmost
  .header
    border-bottom-width: medium
    border-bottom-style: none
    border-bottom-color: $cod-gray
    margin-bottom: 0pt
    padding-top: pxToRem(8)
    padding-bottom: 3px

img.icon
  margin: 0 2px 0 0
  vertical-align: -3px

// Not sure why margin-top is normally -pxToRem(100), but on the registered compound page that does not work.
#registered_compounds_index
  #search_results
    margin-top: pxToRem(0)

#search_results
  width: 100%
  margin-top: -pxToRem(100)

  .molecule-image-container
    min-height: pxToRem(223)
    position: relative

    .moleculeImage
      max-width: pxToRem(200)
      max-height: pxToRem(200)
      margin: pxToRem(10)

    .error-boundary-msg
      font-size: pxToRem(24)
      color: $color_warning

#search_results_header
  h2
    display: inline-block
    margin: 0 0 pxToRem(15) 0
    line-height: pxToRem(18)
    height: pxToRem(18)
    white-space: nowrap

.results_options
  margin: 0
  width: pxToRem(500)
  float: right
  height: pxToRem(18)
  padding: 0

  li
    margin: 0
    font-size: pxToRem(11)
    float: right
    list-style-type: none
    list-style-image: none
    list-style-position: outside
    padding: 0 0 pxToRem(5) pxToRem(20)
    background: asset_pack_path("packs/images/cdd30/layout/vertical-rule-90.gif") no-repeat pxToRem(10) pxToRem(0)
    vertical-align: top

  .first
    background: none

#search_results_actions
  border-bottom: 1px solid $silver-chalice
  padding: pxToRem(12) pxToRem(10) pxToRem(14) pxToRem(10)
  border-right: 1px solid $alto

  h3
    display: inline
    font-size: pxToRem(15)

  #search_results_actions_links
    a
      margin: 0 3px 0 3px
      font-weight: normal
      font-size: pxToRem(15)

    .launch-visualization
      float: none
      color: $white
      border-radius: pxToRem(4)
      padding: pxToRem(8) pxToRem(12) pxToRem(8) pxToRem(8)
      background: $dodger-blue
      background: linear-gradient(to bottom, $dodger-blue 0%, $dodger-blue_light 100%)
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$dodger-blue_light', endColorstr='$dodger-blue', GradientType=0)

      &:hover
        background: $azure-radiance

      img
        vertical-align: -pxToRem(6)

      &.disabled
        background: $dusty-gray
        border-style: none

        &:hover
          cursor: default
          text-decoration: none

  #display_options_link
    border-left: 1px solid $silver-chalice

#selectAllNonePrompt
  font-weight: normal
  text-align: center
  color: $dim-gray
  padding-top: pxToRem(10)
  padding-bottom: 0

#selectAllNone
  font-weight: normal
  text-align: center

  a
    font-weight: normal

  .separator
    padding: 0 2px
    color: $dusty-gray

h2
  .results_count
    margin: 0 0 0 pxToRem(5)
    font-size: pxToRem(11)
    font-weight: normal
    vertical-align: top

fieldset
  margin: 0
  border: 0
  padding: 0

.details
  font-size: pxToRem(11)

// search : sequence
#structure_criteria
  .sequence_input
    #sequence
      width: pxToRem(300)

  #registration_type
    margin: 0 0 pxToRem(5) 0

  .displayed_form_content
    > select
      width: pxToRem(200)

// search : keywords
$molecule-criteria__margin: pxToRem(4)
$molecule-criteria__padding: pxToRem(4)
$molecule-criteria__border: 1px
$molecule-criteria__filter: pxToRem(95)

.molecule_criteria
  .item
    line-height: unset
    margin-bottom: $molecule-criteria__margin

    + .item
      margin-left: $molecule-criteria__margin
      width: calc(100% - #{$molecule-criteria__margin} - #{$molecule-criteria__filter})

    + .item__inline_label
      width: auto
      padding-top: pxToRem(6)

    + .item__inline_input
      width: pxToRem(110)

.molecule_criteria__query
  box-sizing: border-box
  width: 100%
  min-height: 2rem
  padding: $molecule-criteria__padding


.molecule_criteria__query_single_row
  margin: 0
  min-height: pxToRem(28)
  height: pxToRem(28)
  fieldset
    height: pxToRem(28)
  > div
    padding: 0
    border: none !important
    height: pxToRem(26)
    textarea
      margin-left: pxToRem(5) !important

.molecule_criteria__query__textarea
  min-height: pxToRem(26)
  max-height: pxToRem(160)
  resize: none

  &:empty
    height: pxToRem(26)

.molecule_criteria__query__hidden
  display: none

.molecule_criteria__query__date_button
  button
    padding: pxToRem(5)!important

.molecule_criteria__query__date

  fieldset
    border: none

  > p
    display: none

  > div
    position: absolute
    right: -1rem

    button
      margin-right: -0.5rem

    .fa-calendar
      display: block
      margin-top: 0.167rem
      font-size: pxToRem(12)

    &::before, &::after, input
      display: none !important

.molecule_criteria__select
  max-width: 21rem

.molecule_criteria__field
  width: $sidebar-width

.molecule_criteria__query_style
  width: $molecule-criteria__filter

.subsubcontainer .hr
  border-bottom-color: $alabaster

#structures_filters
  label
    display: inline
    margin: 0 0 0 3px
    padding: 0
    font-weight: normal
    font-size: pxToRem(12)

  input
    display: inline
    padding: 0

  select
    display: inline
    margin-left: 3px

.condition
  margin: pxToRem(7) 0
  background-color: $white

  select
    padding: $molecule-criteria__padding
    font-size: pxToRem(12)
    background: $white
    border: 1px solid $alto

  input
    font-size: pxToRem(12)
    padding: $molecule-criteria__padding
    border: 1px solid $alto
    line-height: pxToRem(16)

// Keep the old UI hidden (manipulated through RJS) but use it as the data source to create the new UI
#protocol_criteria
  visibility: hidden
  position: fixed

#protocol_criteria .condition
  display: inline-block
  clear: both
  width: 100%

  .molecule_column
    .molecule
      min-width: 20rem

  .column
    padding-right: pxToRem(5)
    max-width: pxToRem(650)
    float: left

    span.protocol-readout-operator
      padding-left: pxToRem(5)
      padding-right: pxToRem(5)

    .protocol_field_span
      select
        vertical-align: middle

      textarea
        font: pxToRem(12) arial
        color: $shuttle-gray
        resize: none
        vertical-align: middle
        height: pxToRem(16)

        &:focus
          outline: 2px solid $anakiwa

.protocol_readout_junction,
.criterion_junction
  margin-bottom: pxToRem(5)

  select
    padding: 3px 3px 2px 3px
    font-size: pxToRem(11)
    background: $white
    border: 1px solid $alto

.collection_criterion:first-child .criterion_junction
  display: none

.collection_criterion .collection_select
  max-width: 75%
  text-overflow: ellipsis

.search_criterion_icon_junction
  margin: pxToRem(7) 0 pxToRem(10) 0

  .input-radio
    vertical-align: -2px

  label
    font-weight: normal

  img
    vertical-align: -3px

#spinner
  float: right

.junction
  font-weight: bold

  input
    padding-left: 5rem

.selection
  display: inline-block
  float: left
  clear: both
  margin-top: 3px
  white-space: no-wrap

  .input-text
    height: pxToRem(17)

.readouts
  table
    background: inherit

.delete_condition
  float: right
  text-align: right

#mine-viewSaved
  position: absolute
  bottom: pxToRem(10)
  right: pxToRem(15)
  font-weight: bold

#mine-search
  .group1
    margin: 0 0 pxToRem(5) 0
    padding: 0 2% pxToRem(10) 2%

  .group2
    margin: 0 0 pxToRem(10) 0
    padding: 0 0 0 2%
    width: pxToRem(300)

  .group3
    margin: 0 2% pxToRem(15) 0
    float: right
    width: 55%

  .group4
    margin: 0 0 pxToRem(10) 0
    padding: 0

  h3
    margin-bottom: pxToRem(10)

#mine_search_form
  .column1, .column2
    margin: 0

  h3
    margin: 0 0 pxToRem(5) 0
    white-space: nowrap
    line-height: pxToRem(25)

    label
      font-weight: normal

  .data-filters
    .left
      .item
        margin: 0

    .right
      font-size: pxToRem(11)

      .item
        margin-left: 2px

      select
        font-size: pxToRem(11)
        padding: 1px

  .hr
    margin: pxToRem(15) 0

#chemical_properties, #chemical_properties_react
  .dropdown_row
    width: auto

  .chemical_property_select
    float: left
    width: pxToRem(150)

  .chemical_property_name
    margin: pxToRem(4) 0
    float: left
    font-weight: bold

  .chemical_property_values

    input
      margin-left: pxToRem(4)

    label
      font-weight: normal
      color: $dim-gray
      padding: 0 0 0 3px

    .input-text
      width: pxToRem(50)

  .chemical_property_junction
    margin: pxToRem(7) 0

#mine_save_search_primary
  float: left
  padding-top: 0.3rem

#mine_save_search_secondary
  float: left

#mine_submit
  float: right

#structures
  h3, div.options
    float: left
    margin-bottom: 0.8rem

  h3
    margin-right: 0.3rem

#structure div.options
  font-size: pxToRem(11)
  font-weight: normal

#structures div.options
  .aligned, input
    vertical-align: middle

#mine-search .structure_editor
  margin: 0 0 pxToRem(15) 0
  clear: left
  width: 90%
  overflow: hidden

label
  font-weight: bold

#limit
  margin-right: 1rem

  select
    font-weight: bold

#result_headers a
  color: $science-blue
  display: inherit

  &.cancel
    color: $red

  &.disabled
    color: $dusty-gray

#searchType
  margin: 0 pxToRem(10) 0 0

  label
    font-weight: normal

#searchButton
  margin: -pxToRem(5) 0 0 0
  float: right

.vault_name
  color: $gray

.similarity_score
  color: $black

.positive_score_bar
  display: inline
  margin: pxToRem(0)
  color: $science-blue
  padding: pxToRem(0)

.negative_score_bar
  display: inline
  margin: pxToRem(0)
  color: $silver
  padding: pxToRem(0)

.normal_text
  font-weight: normal

  label
    font-weight: normal

.search_owner
  font-weight: normal
  color: $cadet-blue

.criterion
  margin: 0.25rem 0.5rem

.highlighted, td.highlighted
  font-weight: bold

#search_results_table
  .plate_name
    display: inline-flex

  td.leftmost
    border-left: 1px solid $silver-chalice

  #result_headers .topmost
    border-top: none
    background: $alto
    z-index: 5

  .topmost
    border-top: 1px solid $silver-chalice

  .flaggable-toggle
    position: static
    float: right

  td.selector
    cursor: pointer
    // set the width to children contents (.toggleSwitch)
    width: pxToRem(30)

    .toggleSwitch
      margin: pxToRem(6)
      margin-right: pxToRem(7)
      // pxToRem(30.5) only widens windows chrome 1px
      width: pxToRem(30.5)
      height: pxToRem(30)

      .toggleSwitch-control-on,
      .toggleSwitch-control-on:hover
        height: pxToRem(30)
        width: pxToRem(30)
        padding: 0
        background-image: url("../images/cdd30/layout/toggle_checkbox.png")
        background-repeat: no-repeat
        background-position: 0 0

    .toggleSwitch-off
      .toggleSwitch-control-on,
      .toggleSwitch-control-on:hover
        background-color: $concrete
        background-position: 0 -pxToRem(30)

    .toggleSwitch-on
      display: block

      .toggleSwitch-control-on,
      .toggleSwitch-control-on:hover
        height: pxToRem(30)
        width: pxToRem(30)
        padding: 0
        cursor: pointer

    &.rowWithStructure
      .toggleSwitch
        margin-top: pxToRem(110)

    &.rowWithoutStructure
      .toggleSwitch
        margin-top: pxToRem(20)

  td > p
    max-width: pxToRem(200)

  tr.unselected
    td
      color: $silver

      a
        pointer-events: none
        cursor: default

    td > *
      opacity: 0.25
      color: $mine-shaft

    td.selector > *
      opacity: 1

  .filePreview.noThumb
    padding: pxToRem(5) 0

#search_results_section
  margin: 0 pxToRem(25)

#search_results_table,
.search_results_table
  margin-bottom: pxToRem(20)
  clear: both
  min-width: pxToRem(940)
  border-top: thin solid $silver-chalice
  border-right: thin solid $silver-chalice
  border-bottom: 3px solid $silver-chalice
  // 2px or smaller does not show when zoomed out
  border-collapse: separate
  border-spacing: 0
  vertical-align: top

  .date
    color: $dim-gray
    text-align: right
    white-space: nowrap

  > thead
    position: sticky
    top: 0
    left: 0
    z-index: 4
    .molecule, .protocol
      font-size: pxToRem(13)

    .readout, .date
      padding-top: 0

  > tfoot
    > tr
      background-color: $alto

  > thead, > tbody, > tfoot
    > tr
      > th, > td
        border-left: 1px solid $alto
        border-top: 1px solid $alto
        padding: pxToRem(10)

      > td
        vertical-align: top
        font-size: pxToRem(13.5)

      > th
        padding: 0
        font-size: pxToRem(13.5)
        border-top: none
        text-align: left
        border-left: none
        background-color: $alto
        border-left: 1px solid $gallery
        margin-bottom: -1px
      > th.leftmost
        border-left: 1px solid $silver-chalice

      > .molecule_header
        background-color: $silver

    > tr:not(.alt)
      > th
        span:not(.separator)
          display: flex
          min-width: pxToRem(150)
          white-space: pre-wrap

.summary_details_toggle
  float: right
  min-height: pxToRem(16)

  img
    vertical-align: top

#long_search_notice
  clear: both
  padding: 2rem
  text-align: center

#exportDisabledMessage
  float: right

#display_options_link
  font-size: pxToRem(12)
  font-weight: normal
  padding: pxToRem(10) 0 pxToRem(11) pxToRem(10)

.list_column
  display: inline-table
  width: 33%

  li
    list-style: none

  .fa-hidden
    color: transparent

#display_options
  table
    border-bottom: 0

  ul
    margin: 0
    padding: 0
    border: 1px solid $gallery
    border-bottom: none

    li
      margin: 1px 0
      background: $alabaster
      list-style: none
      padding: pxToRem(15) pxToRem(10)
      font-weight: bold
      border-bottom: 1px solid $gallery

      ul
        margin: pxToRem(15) 0 0 pxToRem(24)
        padding: pxToRem(10) 0 0
        border: none
        border-top: 1px solid $gallery

        li
          background: none
          border: none
          padding: pxToRem(5) 0
          font-weight: normal

          label
            input
              margin-right: pxToRem(5)

  .submit
    float: right
    padding-top: pxToRem(10)

  .aligned
    display: inline-block
    margin-left: pxToRem(4)
    min-width: pxToRem(120)

  input[type='radio']
    margin: 0
    vertical-align: text-top

  .drag_handle, .locked_handle
    margin: 0 pxToRem(6) 0 0
    vertical-align: -3px
    cursor: move

  .locked_handle
    cursor: default

  .general_options
    margin: pxToRem(25) 0 pxToRem(15) 0

    th, td
      padding-top: pxToRem(13)
      padding-bottom: pxToRem(15)

    th
      width: pxToRem(220)
      white-space: nowrap
      vertical-align: middle

    td
      padding-left: 0

  #detail_level
    margin: 0 0 pxToRem(15) 0

    label
      font-weight: normal

    h4
      display: inline
      padding-right: pxToRem(5)

    .inlineRadios
      label
        padding-right: pxToRem(5)

  #addProtocolsToDisplay
    margin: pxToRem(15) 0 pxToRem(15) 0
    background-color: $white
    padding: pxToRem(10) 0 pxToRem(10) 0

    img
      padding: 0 0 0 pxToRem(10)

    span
      padding: pxToRem(10) pxToRem(10) pxToRem(10) 0
      font-weight: bold

    #protocol_filtering_message
      display: inline
      padding-left: pxToRem(10)

    #filtered_protocols
      max-width: 80%

  #per_molecule_plot_scale_type_button
    margin-left: pxToRem(20)

#project_addition_options
  top: pxToRem(5)
  z-index: 10000

  table.dataTable
    margin-bottom: 0
    background-color: $white

    th, td
      border: 1px solid $alto

    th
      width: 20%
      border-right: none

    td
      border-left: none

  label
    font-weight: normal

#project_addition_options
  left: pxToRem(280)

// Pagination

.incrementalLoadLink
  margin: pxToRem(25) 0
  font-size: pxToRem(16)
  text-align: left

// All protocols hidden message

#search_results-protocolsHidden
  margin: pxToRem(5) auto pxToRem(30)
  width: calc(100% - pxToRem(40))

  .subhead
    margin: -pxToRem(5) 0 pxToRem(10) 0

// Popup box

.save-search__dialog
  #save_search_form
    color: $shuttle-gray
    margin: auto
    h3
      margin: 0 0 pxToRem(5)
      white-space: nowrap
      line-height: pxToRem(25)
      font-size: pxToRem(14)
      font-weight: 400
    table.simple
      th
        width: pxToRem(50)
        font-weight: 700
      .unbold
        font-weight: 400

  .input-checkbox
    width: auto
    vertical-align: text-bottom
    margin-left: 3px

  label
    margin-left: pxToRem(5)

.plainMessage
  margin: 0 0 pxToRem(20) 0
  padding: pxToRem(17) pxToRem(20) pxToRem(5) pxToRem(20)
  color: $black
  background: $white
  border: 1px solid $alto

.add-to-collection__dialog
  .subcontainer
    width: pxToRem(500)
    min-height: pxToRem(200)

#addToExistingCollection-project
  height: pxToRem(20)

#addToCollection-notice
  margin-bottom: pxToRem(30)
  margin-left: auto
  margin-right: auto
  width: pxToRem(900)

  #shareDialog
    right: 0

.export_options__dialog
  .subcontainer
    width: pxToRem(410)

.build-model__dialog
  table.simple
    margin: 0

    th
      width: pxToRem(140)

    select
      width: 100%

// Booleans Key

.booleansKey
  margin: pxToRem(25) auto pxToRem(20) auto
  width: pxToRem(260)

.booleansKey-item
  margin: 0 pxToRem(10)
  float: left
  font-size: pxToRem(10)
  font-weight: bold
  text-align: center

  img
    display: block
    margin: 0 0 pxToRem(10) 0

#saved_searches_table, #saved_collections_table
  td.actions
    text-align: left

#saveSearch-link
  margin-left: -pxToRem(10)

.molecule-download-copy-link
  display: grid
