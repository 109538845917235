// Recent protocols grid
.recent_protocols
  .molecules_link
    width: 20%

#dashboard-vaultSettings
  tbody th
    width: $sidebar-width
    text-align: right

#vaultPlotConfiguration-form, #dashboard-vaultPlotConfiguration
  tbody th
    width: $sidebar-width
    text-align: right
  .field_with_errors
    display: inline-block

// Public dashboard

#publicDashboard
  .main
    width: pxToRem(620)
  .sidebar
    width: pxToRem(290)

// Scott: more horribleness for bugfix/183201436-reposition-search
.registration_type_structure
  .data-filters
    .right
      margin-top: -pxToRem(30)
      margin-left: -pxToRem(84)
      float: left

.registration_type_nucleotide_sequence, .registration_type_amino_acid_sequence
  .data-filters
    .right
      margin-top: -pxToRem(30)
      margin-left: -pxToRem(100)
      float: left
