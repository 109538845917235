.icon-10
  margin: 0 1px 0 0
  vertical-align: -1px

.icon-16
  margin: 0 2px 0 0
  vertical-align: -3px

  &.date-range
    margin: 0 2px 0 2px

.icon__45 .fa
  transform: rotate(45deg)

.icon__135 .fa
  transform: rotate(135deg)


// Icon list and Action links
.iconList
  list-style: none
  padding: 0 0 0 22px
  .actionLinks &
    margin: 0 0 20px 0
  li
    margin: 6px 0
    position: relative
    padding: 0
    line-height: 16px
    .icon, .icon-16
      position: absolute
      top: -1px
      left: -22px
    .actionLinks &
      margin: 10px 0


// Action icon
.actionIcon
  position: relative
  float: left
  width: 10px
  height: 10px
  overflow: hidden
  a:hover & img
    position: relative
    top: -10px
    cursor: pointer


.small-edit-icon
  color: $ui
  cursor: pointer
  padding: 12px
  position: absolute
  top: calc(50% - 18px)
  z-index: 100
