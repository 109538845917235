=vertically-centered($height)
  margin: auto
  position: absolute
  height: $height
  top: 0
  bottom: 0

.editor
  max-width: pxToRem(940)
  margin: auto
  padding: pxToRem(10) pxToRem(20) pxToRem(20)
  border: pxToRem(1) solid $border
  border-radius: pxToRem(2)
  box-shadow: 0 0 pxToRem(36) $box-shadow
  position: relative

.editor--busy
  cursor: wait

  &.editor__body
    pointer-events: none

.editor__body
  padding-top: pxToRem(10)
  min-height: calc(100vh - pxToRem(190))
  line-height: 1.5
  color: $black

  > div
    position: relative
    flex-wrap: wrap
    align-items: flex-end

  ul,
  ol
    margin: 0 0 pxToRem(15) 0
    padding: 0 0 0 pxToRem(15)

  .no-bullet
    list-style-type: none

%tooltiptext-common
  font-size: pxToRem(10)
  font-family: Roboto, sans-serif
  background-color: $dim-gray
  color: $white !important
  border-radius: pxToRem(2)
  z-index: 1

.tooltip + .tooltiptext
  @extend %tooltiptext-common
  line-height: pxToRem(22)
  padding: pxToRem(0) pxToRem(8)
  visibility: hidden
  text-align: center
  position: absolute
  top: 100%
  left: 50%
  transform: translateX(-50%)
  white-space: nowrap

.tooltip:hover + .tooltiptext
  visibility: visible

.bottom-right-icon
  text-shadow: -pxToRem(2) -pxToRem(2) pxToRem(2) $white
  font-size: pxToRem(13)
  position: absolute
  top: pxToRem(17)
  left: pxToRem(19)
  pointer-events: none

.red-icon
  color: $amaranth

.green-icon
  color: $malachite

.rotate-270
  transform: rotate(270deg)

.editor__toolbar
  display: flex
  margin: 0
  padding: 0
  font-size: pxToRem(16)
  border-bottom: pxToRem(1) solid $border
  background: $white
  list-style: none
  align-items: flex-end
  height: pxToRem(40)
  z-index: 10

  li
    display: flex
    margin: 0
    position: relative
    text-align: center
    align-self: center
    line-height: 1rem

    label
      font-weight: normal

    .material-icons
      width: pxToRem(16)

    .hidden
      display: none

    &.header-button
      margin-left: 0
      width: 6.25rem

      .header-button__select
        margin-top: pxToRem(6)
        font-size: pxToRem(14)
        color: $ui
        text-align: left
        padding-right: 1.75rem
        width: 5.5rem

        &:focus
          background: transparent

      .header-button__select-icon
        color: $ui
        opacity: 0.75

      .header-button__input:before
        display: none

      .header-button__input:after
        display: none

    &.toolbar__rightmost
      margin: pxToRem(0)
      flex-grow: 1
      color: $dusty-gray
      font-size: pxToRem(14)
      padding: pxToRem(10) 0 pxToRem(10)
      line-height: pxToRem(20)
      flex-direction: row-reverse

    &.divider
      margin: pxToRem(12) pxToRem(5) pxToRem(10)
      border-right: pxToRem(1) solid $border
      align-self: stretch

    select
      font-size: pxToRem(16)

    a
      color: $ui
      cursor: pointer
      padding: pxToRem(10) !important
      width: auto !important
      height: auto !important

      &:hover,
      &:active,
      &:focus,
      .active
        color: $ui_hover
        text-decoration: none

        + span
          color: $ui_hover

    span
      &[name='bold']
        font-weight: bold
      &[name='italic']
        font-style: italic
      &[name='underline']
        text-decoration: underline

.editor__toolbar--vertical
  flex-direction: column
  height: auto
  padding: pxToRem(10) 0

  .icon__135
    .material-icons
      transform-origin: pxToRem(8)

  .molecule-button
    img
      padding-right: pxToRem(5)
      margin-left: -pxToRem(3)

  > li
    width: 100%
    text-align: left

    > *
      width: 100%

    .material-icons, svg
      padding-right: pxToRem(8)

    a
      padding-left: pxToRem(8)

      span
        white-space: nowrap

    &.header-button

      .header-button__select
        margin: 0
        padding: pxToRem(10) pxToRem(15)

        &:focus,
        &:active,
        &:hover
          background: $material-bg_hover

      .header-button__select-icon
        right: pxToRem(5)
        top: calc(50% - pxToRem(14))

    &.assay-annotation-button

      a
        padding: pxToRem(10) pxToRem(15) !important

      svg
        margin-left: -pxToRem(2)

    &.mixture-button

      a
        padding: pxToRem(10) pxToRem(15) !important

      svg
        margin-left: -pxToRem(2)

    &.divider
      margin: 0 pxToRem(15) pxToRem(4)
      padding: pxToRem(4) 0 0
      border-right: none
      border-bottom: pxToRem(1) solid $border
      width: 25%

    a
      line-height: 1.25
      flex-grow: 1
      border-radius: 0
      justify-content: flex-start
      width: calc(100% - pxToRem(30)) !important
      padding: pxToRem(10) pxToRem(15) !important

      &.tooltip
        margin: 0 !important

      > span
        font-size: pxToRem(14)

        span[class*="fn-"],
        span[class*="fa-"],
        svg
          margin-right: 0.5rem

  .toolbar__rightmost,
  .tooltiptext
    display: none


// grid-select
$grid-select-unit-size: pxToRem(4)
$grid-select-cell-size: pxToRem(16)
$grid-select-color: $silver

.grid-select
  .grid-select__row
    display: flex

  .grid-select__cell-container
    float: left
    padding: $grid-select-unit-size

  .grid-select__cell
    height: $grid-select-cell-size
    width: $grid-select-cell-size
    border: thin solid $grid-select-color
    border-radius: pxToRem(1)

    &.cell--selected
      border: thin solid $color_brand
      background-color: $color_brand

  .grid-select__dimensions
    padding: $grid-select-cell-size

  .grid-select__label
    width: 100%
    text-align: center
    vertical-align: middle
    padding-bottom: $grid-select-cell-size
    color: $color
    font-family: sans-serif
    box-sizing: border-box


.link__container
  position: relative

  > a
    cursor: pointer
    word-break: break-word

.link__edit-container
  display: inline-block

.link__edit
  position: absolute !important
  top: -pxToRem(8)
  left: -pxToRem(18)
  font-size: pxToRem(12)
  min-height: pxToRem(28)
  height: pxToRem(28)
  width: pxToRem(28)
  border: pxToRem(1) solid $white
  box-shadow: pxToRem(0) pxToRem(0) pxToRem(10) pxToRem(0) $white
  z-index: 100

  &.hidden
    display: none
    opacity: 0

  // &:hover
  //   background: $ui_hover !important

  .fa-icon
    margin-top: -pxToRem(2)
    color: $color
    &:hover
      color: $ui

// column menu svg icons
.toolbar__column-menu,
.toolbar__row-menu

  svg
    fill: $ui
    height: pxToRem(14)
    width: pxToRem(14)

  &:hover,
  &:focus,
  &:active

    svg
      fill: $ui_hover

// structure svg icon
.structure-button

  svg
    fill: $ui
    height: pxToRem(16)
    width: pxToRem(16)

  &:hover,
  &:focus,
  &:active

    svg
      fill: $ui_hover


// molecule svg icon
.molecule-button

  img
    fill: $ui
    height: pxToRem(20)
    width: pxToRem(20)

  &:hover,
  &:focus,
  &:active

    img
      fill: $ui_hover

// annotation svg icon
.assay-annotation-button
  margin-left: -pxToRem(2)

  > span a
    padding: pxToRem(8) pxToRem(9) pxToRem(8) pxToRem(7) !important

  svg
    fill: $ui
    stroke: $ui
    width: pxToRem(18)
    height: pxToRem(18)

  &:hover,
  &:focus,
  &:active

    svg
      fill: $ui_hover
      stroke: $ui_hover

// mixture svg icon
.mixture-button
  margin-left: -pxToRem(2)

  > span a
    padding: pxToRem(8) pxToRem(9) pxToRem(8) pxToRem(7) !important

  svg
    fill: $ui
    stroke: $ui
    width: pxToRem(18)
    height: pxToRem(18)

  &:hover,
  &:focus,
  &:active

    svg
      fill: $ui_hover
      stroke: $ui_hover

// italic button
.italic-button .tooltip
  margin-left: -pxToRem(4) !important

// table icon too high
.toolbar__table-button
  padding-top: pxToRem(1)

.new-molecule-modal
  .new-molecule-inner
    display: block
    margin: auto
    border: 0
    height: 100%
    width: pxToRem(1100)

  .new-molecule-loading
    background-color: $white

    img
      position: absolute
      top: 50%
      bottom: 50%
      margin-top: -pxToRem(15)
      left: 50%
      right: 50%
      margin-left: -pxToRem(15)
      height: pxToRem(30)
      width: pxToRem(30)

    &.hidden
      display: none
