@import components/Table/InventorySearchTable
@import components/SearchBar/SearchBar

// TODO dedupe with ruby/app/frontend/Accounts/Projects/ProjectsPage.sass
#inventorySearchView
  .top-row
    .vault-select
      padding: 1rem

  // We put the simple filter padding here as well as we are importing that page in the vault settings
  // and it doesn't inherit from AccountsPage
  .simple-filter
    padding: 1rem
