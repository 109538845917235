$assayAnnotationTitleColor: $mine-shaft
$assayAnnotationColor: $shuttle-gray
$assayAnnotationIndent: 1rem
$proposalHeader: $ui

.assay-annotation-button .disabled
  @extend %disabled-button

.assayAnnotation__container
  margin: pxToRem(10) 0
  min-height: pxToRem(100)
  border-style: solid
  border-color: $catskill-white
  border-width: pxToRem(1)
  padding: pxToRem(10)
  border-radius: pxToRem(2)

  &:hover
    border-color: $denim

%assayAnnotation-titles
  color: $assayAnnotationTitleColor
  border-bottom-style: solid
  border-color: $catskill-white
  border-width: pxToRem(1)
  padding-bottom: pxToRem(8)

.assayAnnotation__title
  @extend %assayAnnotation-titles
  margin-bottom: pxToRem(12)
  padding-bottom: pxToRem(9)
  font-size: pxToRem(32)
  padding-top: pxToRem(2)
  border-color: $silver
  line-height: 1.25

.assayAnnotation__sectiontitle
  @extend %assayAnnotation-titles
  font-size: pxToRem(20)

.assayAnnotation__sectionblock
  display: flex
  margin-bottom: pxToRem(15)
  line-height: 1.125

  &:nth-last-of-type(1)
    margin-bottom: 0

.assayAnnotation__sectioncolumn
  flex-basis: 0
  flex-grow: 1

  + .assayAnnotation__sectioncolumn
    padding-left: pxToRem(10)

.assayAnnotation__assignmentLabel
  margin: pxToRem(10) 0 pxToRem(5)
  color: $assayAnnotationColor

.assayAnnotation__assignmentTooltip
  @extend %tooltiptext-common
  max-width: pxToRem(500)

.assayAnnotation__annotationValue
  color: $assayAnnotationColor
  list-style-type: none
  font-size: pxToRem(16)
  padding-left: $assayAnnotationIndent

.assayAnnotation__annotationValue > li:before
  display: inline-block
  margin-left: -$assayAnnotationIndent
  content: '-'
  color: $assayAnnotationColor
  width: $assayAnnotationIndent
  text-align: center


.assayAnnotation__annotationMissing
  margin: pxToRem(2)
  color: $mercury
  font-size: pxToRem(16)

%assayAnnotation-icon
  color: $ui
  font-size: pxToRem(10)
  height: 2rem
  width: 2rem

.assayAnnotation__autotext
  display: flex
  margin-bottom: pxToRem(10)
  color: $assayAnnotationColor
  align-items: flex-start
  justify-content: space-between

  & > div:nth-last-of-type(1)
    width: pxToRem(35)
    min-width: pxToRem(35)

  & .copy_autotext
    margin-top: -pxToRem(2)
    color: $ui
    font-size: pxToRem(32)
    float: right

    .assayAnnotation__control
      margin-top: -1rem

      .fa
        font-size: pxToRem(14)

.assayAnnotation__container .assayAnnotation__control
  display: none

.assayAnnotation__container:hover .assayAnnotation__control
  display: inline-flex

.assayAnnotation__controlTooltip
  @extend %tooltiptext-common

.assayAnnotation__control
  @extend %assayAnnotation-icon

  .fa-pencil
    font-size: pxToRem(14)

  &:hover, &:focus, &:active
    color: $ui_hover
    text-decoration: none

// Assay annotation dialog
.assayAnnotationDialog
  height: 80vh
  width: 80vw
  max-width: pxToRem(1000)
  overflow: visible

.assayAnnotationDialog-title
  background-color: $ui
  border-top-right-radius: pxToRem(2)
  border-top-left-radius: pxToRem(2)
  padding: pxToRem(30) pxToRem(60)
  color: $alabaster
  font-size: pxToRem(24)

[role=document], [role=dialog]
  .assayAnnotationDialog-content
    margin: pxToRem(20) 0
    padding: 0 pxToRem(60)

    header
      background-color: $ui
      box-shadow: pxToRem(0) pxToRem(0)
    > div > div
      padding-top: pxToRem(0) !important
      & + div
        padding-top: pxToRem(5) !important
    div.assayAnnotationDialog-assignment
      display: flex
      margin-left: pxToRem(30)
      margin-right: pxToRem(30)
      min-height: pxToRem(50)

  .assayAnnotationDialog-actions
    margin: 0 pxToRem(50) pxToRem(10)
    padding: pxToRem(0)

// Assay annotation dialog Tabs
$assayAnnotationTabHeight: pxToRem(33)
$assayAnnotationTabRadius: pxToRem(5)

.assayAnnotationDialog-appBar
  // this contains the tabs for the dialog, and looks much better as flat white
  background-color: $white !important
  box-shadow: none !important

.assayAnnotationDialog-tabs
  margin: 0 pxToRem(60)
  min-height: $assayAnnotationTabHeight
  div > div > span
    display: none

  button
    height: pxToRem(45)
    min-height: auto

%assayAnnotationDialog-tab-base
  +border-radius-top-right($assayAnnotationTabRadius)
  +border-radius-top-left($assayAnnotationTabRadius)
  background-color: $ui
  color: $alabaster
  height: $assayAnnotationTabHeight
  opacity: 1
  span
    font-size: 0.9rem

.assayAnnotationDialog-tab
  @extend %assayAnnotationDialog-tab-base

.assayAnnotationDialog-tab-selected
  @extend %assayAnnotationDialog-tab-base
  background-color: $white
  color: $black

.assayAnnotationInput
  border: none

.assayAnnotationInput-formControl
  align-self: flex-end

[role=document], [role=dialog]
  .assayAnnotationDialog-content
    div.assayAnnotationDialog-assignment-container
      margin: 0
    div.assayAnnotationDialog-assignment
      display: flex
      margin-left: pxToRem(0)
      margin-right: pxToRem(0)
      min-height: pxToRem(50)
      flex-direction: row
      justify-content: flex-end
      margin-bottom: 0.5rem

      & > div:first-child
        display: flex
        width: 100%
        flex-direction: column
        justify-content: flex-end
        border-bottom: pxToRem(2) solid $silver
        &:hover
          border-bottom-color: $regent-gray

          + .assayAnnotationDialog-showAllButton
            border-color: $regent-gray

.assayAnnotationDialog-currentAnnotations
  margin: 0 -pxToRem(2)
  width: 100%
  padding: 0.25rem 0 0.375rem
  &:focus
    outline: none

.assayAnnotationDialog-showAllButton
  align-self: flex-end
  border-bottom: pxToRem(2) solid $silver

  .material-icons
    padding: pxToRem(5)

// Annotation Chip
$annotationChipHeight: pxToRem(22)
.assayAnnotationDialog-annotation
  max-width: 100%

  span
    display: block
    padding: 0 pxToRem(8)
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.assayAnnotationDialog-annotation-avatar
  margin-left: pxToRem(3)
  height: $annotationChipHeight - pxToRem(6)
  width: $annotationChipHeight - pxToRem(6)
  font-family: serif
  color: $catskill-white
  background-color: $silver-chalice

// suggestions
.assayAnnotationDialog-suggestions
  margin: -0.5rem pxToRem(0) 0
  z-index: 100

  // retrieve suggestions
  > li
    padding: pxToRem(6) pxToRem(16)


.assayAnnotationDialog-suggestion-freetext
  display: flex
  height: 1.5rem
  background-color: $denim
  color: $white
  padding-bottom: 0
  justify-content: space-between

  &:hover
    background-color: $denim

.assayAnnotationDialog-suggestions-list
  max-height: pxToRem(300)
  overflow-y: scroll

  .proposal
    padding-left: pxToRem(30)

    &:nth-of-type(1):before
      display: block
      margin: -pxToRem(5) -pxToRem(30) pxToRem(5)
      content: 'Proposals'
      padding: pxToRem(5) pxToRem(32) pxToRem(5) pxToRem(15)
      background: $white
      width: 100%
      color: $ui
      pointer-events: none

.assayAnnotationDialog-suggestion-suggestion
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  padding: pxToRem(6) pxToRem(16)
  height: auto

  & mark
    background: none
    font-weight: bold

  &.proposal + &.suggestion
    border-top-style: dashed
    border-top-color: $silver
    border-top-width: pxToRem(1)

.assayAnnotationDialog-suggestion-footer
  height: 2rem
  background-color: $alabaster
  padding-top: 0
  padding-bottom: 0
  font-style: italic
