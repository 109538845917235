#elnEntries-show.noDataMessage
  margin-top: pxToRem(15)

.search_results
  display: flex
  flex-direction: column

.search_results__header
  border-bottom: pxToRem(1) solid $th-border !important
  color: $th !important

  .search_results__cell
    padding: pxToRem(11) pxToRem(8) pxToRem(5)

.search_results__cell
  display: inline-block
  box-sizing: border-box
  overflow: hidden
  text-overflow: ellipsis
  width: 15%
  padding: pxToRem(11) pxToRem(8) pxToRem(8)
  color: $black_87

  em
    font-weight: 600

  a
    overflow: hidden
    text-overflow: ellipsis

    &:hover
      text-decoration: none

  &[name=identifier]
    width: 11%

  &[name=title]
    width: 32%

  &[name=user_full_name]
    width: 20%

  &[name=updated_at]
    width: 13%

  &[name=created_at]
    width: 13%

  &[name=status]
    text-align: right
    width: 11%

.search_results__highlight
  margin: 0 0 0 pxToRem(8)
  list-style: none
  font-size: pxToRem(12)
  padding: 0
  opacity: 0.75

  li
    display: inline-block
    margin: 0 pxToRem(8) 0 0
    padding: pxToRem(6) pxToRem(9)

.search_results__key
  display: block
  font-size: pxToRem(12)
  opacity: 0.5

.search_results__value

  em
    font-weight: 600

  &:not(:last-of-type)::after
    content: ' ... '

.search_results__value + .search_results__value:before
  content: ','
  margin-right: 0.25rem


.search_results__structures
  display: flex
  width: calc(#{$container-width} - 1rem) // padding is 0.5rem on both sides
  position: sticky
  left: 0.5rem
  flex-wrap: wrap
  margin-top: -0.5rem

.search_results__molecule_new_image_service
  display: flex
  justify-content: center
  align-items: center
  svg
    padding-top: pxToRem(5)
    padding-right: pxToRem(5)
    padding-left: pxToRem(5)
    max-height: pxToRem(150)

.search_results__molecule
  margin-right: 0.5rem
  margin-top: 0.5rem
  img
    height: pxToRem(155)
    max-width: pxToRem(650)
    object-fit: contain

.search_results__molecule_name
  margin: 0.5rem
  padding: 0.25rem
  position: absolute
  line-height: 1
  background: $white_50
  max-width: 12rem
  border-radius: $border-radius

// .search_results__structure
.search_results__molecule_structure
  border: pxToRem(1) solid $border
  box-shadow: none
  background: $white
  height: 14rem
  width: 14rem
  padding-top: pxToRem(10)
  img
    max-width: pxToRem(168)

.search_results__reaction_structure
  border: pxToRem(1) solid $border
  box-shadow: none
  background: $white
  height: 14rem
  width: 58rem

.search_results__molecule_structureless
  border: pxToRem(1) solid $border
  box-shadow: none
  background: $white
  height: 14rem
  width: 14rem
  .eln_entries_thumbnail
    transform: scale(0.75)
    img
      height: pxToRem(155)
      max-width: pxToRem(650)
      object-fit: contain

// eln search results table
.search_results__container
  overflow-x: auto
  transform: rotateX(180deg)

.search_results__table
  transform: rotateX(180deg)

// .search_results__header

.search_results__body

  + .search_results__body
    border-top: pxToRem(1) solid $border

  &:hover
    background: $alabaster

.search_results__row
  height: unset

.search_results__row__context

  .search_results__item
    padding-top: 0
    padding-bottom: 0

.search_results__item
  padding: 0.75rem 0.5rem
  font-size: pxToRem(14)!important
  line-height: 1.5
  min-width: 8rem
  vertical-align: bottom
  border: none

  &:nth-last-of-type(1)
    padding-right: 0.5rem // material override

.search_results__item__sort
  align-items: flex-end

  svg
    display: flex
    margin-bottom: pxToRem(2)

.search_results__item__title
  min-width: 12rem

.search_results__item__author
  min-width: 10rem

.search_results__item__witness
  min-width: 10rem

.search_results__item__edit
  padding-bottom: 0.25rem
  width: 1.5rem // material override
  min-width: unset
  vertical-align: bottom

.search_results__button
  height: 1.75rem
  width: 1.75rem
  vertical-align: initial

  svg
    font-size: pxToRem(12)
