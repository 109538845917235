.autocomplete
  width: pxToRem(350)
  z-index: 1

  ul
    margin: 0
    padding: 0
    border: pxToRem(1) solid $gray
    background: $white
    list-style-type: none
    width: 100%

    li
      margin: 0
      padding: pxToRem(3)
      font-weight: normal

      &.selected div
        background-color: $dolly

      .name
        font-weight: bold

  &#autoformula_custom_calculation
    overflow-x: hidden
    border: pxToRem(1) solid $gray

    ul
      border: none

// add alert to edit readout definitions formula autocomplete
.custom-calculation-form

  .autocomplete.truncated:before
    display: block
    content: 'The list has been truncated, please refine your search.'
    font-family: $font-family
    padding: pxToRem(8)
    width: 100%
    color: $white
    background: $denim
