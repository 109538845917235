.EditProjectDialog

  .vault-permission-label
    text-align: right
    padding-right: pxToRem(8)

  .no-padding
    padding: 0 !important

  .team-user-row
    width: 46.4rem

  .readonly-role-text
    text-align: right
    max-width: 6rem
    padding-right: 1rem
