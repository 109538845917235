#run-details
  .main
    .subcontainer
      margin: 0 0 pxToRem(25) 0
      &#run-controlLayouts
        margin: 0
  .sidebar
    #sidebar-delete
      margin-bottom: pxToRem(30)
      border-bottom: pxToRem(1) dotted $gallery

// Definition

#run-definitionAndFiles
  th
    width: pxToRem(120)

// Summary tab

#run-summary
  .hitColor
    margin: -pxToRem(2) 0 -pxToRem(2) 0
    padding: pxToRem(4) pxToRem(6) pxToRem(3) pxToRem(6)

#run-summary-links
  margin: pxToRem(8) 0 pxToRem(33) 0
  text-align: center
  font-size: pxToRem(15)
  padding-bottom: pxToRem(24)
  .separator
    padding: 0 pxToRem(7)

#run-summary-plate-statistics
  padding-bottom: pxToRem(24)

#run-plates-table
  tbody tr.alt
    background: $alabaster
  td.lastReadoutDefinition
    border-right: pxToRem(1) solid $gallery

#createRun-dialog,
.run__table
  span.hidden
    color: $concrete

.run__table
  thead
    th
      br.paddedBottom
        margin-bottom: pxToRem(6)

// Control layouts

#run-controlLayouts
  .plateLayout
    margin: 0 0 pxToRem(5) 0
  .editablePlateLayout
    margin: 0 0 pxToRem(15) 0
  .editableData-editLink-save
    margin: 0
  h3
    margin: 0 0 pxToRem(20) 0
  h4
    margin: 0 0 pxToRem(15) 0

// Move run

#moveRun-dialog
  .popup-inner1
    bottom: 0
    left: 0

// New readout form

#run-newReadout-edit
  margin: 0 0 pxToRem(25) 0
  th
    @extend %break-word
    width: $sidebar-width
  td
    background: transparent

// Edit readout form

.readout-row-edit
  tr td
    background: transparent
  .field_with_errors
    display: inline-block
.new_readout_row
  .field_with_errors
    display: inline-block

// Flagging outliers on new and edit readout forms

#run-newReadout-edit, .run-readout-edit
  .flaggable
    margin: pxToRem(2) 0 0 pxToRem(10)
    font-size: pxToRem(12)
    .actionIcon
      margin: 0 pxToRem(3) 0 0
      vertical-align: -pxToRem(1)
    .text
      display: none
  .flagged
    font-style: normal
    text-decoration: none
    .text
      display: inline
    a
      color: $guardsman-red

// Readouts table

table[id^=run-readouts-data-table]
  td
    max-width: 20rem
    min-width: 6rem

  td.actions
    text-align: left

  th:not(#sort_by_molecule_name)
    .sortable-header
      @extend %max-char
      display: block
      max-width: 20rem

// Files tab
#run-files
  .subcontainer
    margin: 0 0 pxToRem(25) 0

#linked-eln-entries-show
  span + span::before
    content: "· "
  span + span.first::before
    content: "  "

.new_run,
#run-definition-edit
  .file
    .filePreview
      float: left

      img
        max-width: pxToRem(220)

      &.noThumb
        padding: pxToRem(30) 0
        height: pxToRem(20)

    .actions
      float: left

      ul
        list-style: none

  .readoutFileUploader
    display: inline-block
    vertical-align: bottom

    &.qq-upload-button-hover a
      text-decoration: underline
      color: $red

#run-definition-show
  .filePreview
    text-align: left
