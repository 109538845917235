.NestedExpandableDataTable
  .collapsed-table-row
    opacity: 0%
    animation: fade-out 0.5s forwards
    @keyframes fade-out
      from
        visibility: visible
      to
        position: absolute
        z-index: 0
        visibility: hidden

  .expanded-table-row
    opacity: 100%
    animation: fade-in 0.5s forwards
    @keyframes fade-in
      from
        position: absolute
        z-index: 0
        visibility: hidden
      0%
        visibility: visible
      to
        visibility: visible

  .child-row
    transition: opacity 0.49s ease-in-out
    z-index: 0
    visibility: hidden
    font-style: italic
