@import components/EditProjectDialog

.ProjectsPage
  .top-row
    .vault-select
      padding: 1rem

  .add-associated-model, .download-associated-model
    text-align: right
    width: 100%
  // We put the simple filter padding here as well as we are importing that page in the vault settings
  // and it doesn't inherit from AccountsPage
  .simple-filter
    padding: 1rem
