@import ../../../../assets/stylesheets/base/_variables

.ReorderDataTable
  td, th
    font-size: pxToRem(12)!important

  tr
    td
      border: none
      cursor: pointer

    .drag-handle
      cursor: move
  tr
    td
      border: none
      cursor: pointer

    .actions
      td
        white-space: nowrap

      a
        margin-right: pxToRem(14)
