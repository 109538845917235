.diagnostic-details
  margin-top: 2rem
  font-family: monospace
  font-size: pxToRem(11)
  max-width: 100%
  padding: 1rem
  max-height: 12rem
  overflow-x: auto
  overflow-y: auto
  overflow-wrap: break-word
  border: pxToRem(1) solid rgba(0,0,0,.5)
