.drilldown
  display: flex
  width: 100%

  .drilldown-menu
    padding: pxToRem(10)
    width: $sidebar-width
    min-width: $sidebar-width
    margin-right: pxToRem(20)

    .section
      margin: 0
      padding: pxToRem(10)

    .hr
      margin: pxToRem(10)

    a
      outline: none

    h5
      padding: pxToRem(7) 0 0 0
      min-height: pxToRem(18)
      line-height: 1.5rem

    h5.collapsible-title
      word-wrap: break-word
      padding: pxToRem(5) 0 pxToRem(2) pxToRem(20)
      background-position: pxToRem(5) pxToRem(8)

    .active
      color: $white
      background-color: $curious-blue
      background-image: url("../images/cdd30/layout/drilldown/bullet-active.gif")

      &:hover
        color: $white
        background-color: $curious-blue
        background-image: url("../images/cdd30/layout/drilldown/bullet-active.gif")

    .collapsible-inner
      position: relative

.drilldown-item
  @extend %break-word
  display: block
  border-radius: $border-radius
  padding: pxToRem(2) 0 0 pxToRem(20)
  min-height: pxToRem(18)
  background: url("../images/cdd30/layout/drilldown/bullet.gif") no-repeat pxToRem(5) pxToRem(6)
  font-size: pxToRem(11)
  line-height: 1.5rem

  &:hover
    text-decoration: none
    background-color: $white

  &.unavailable
    color: $dusty-gray
    cursor: default

    &:hover
      background-color: inherit

.drilldown-options
  margin: pxToRem(22) 0 pxToRem(25) pxToRem(25)
  width: pxToRem(580)

.drilldown-loading
  display: none
  padding: pxToRem(100)
  text-align: center
  font-size: 1.375rem
  color: $tundora

  img
    margin: 0 pxToRem(10) 0 0
    vertical-align: pxToRem(3)


// loading
.loading

  .drilldown-menu,
  .drilldown-options
    display: none

  .drilldown-loading
    display: block


// collapsible
.collapsible

  .drilldown-item
    padding-left: pxToRem(35)
    background-position: pxToRem(20) pxToRem(6)
