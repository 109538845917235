.EditFormDialog
  .main-dialog-paper
    min-height: 90%
    max-width: 1200px

  .dialog-contents
    display: flex
    flex-direction: column
    margin-top: 0

    .top-row
      margin-bottom: pxToRem(20)

    .form-name
      width: 100%
      label
        z-index: 100

  .contents-and-sidebar-container
    overflow: hidden
    display: flex

    .FormField
      height: 2.2rem

    .SidebarField
      height: pxToRem(24)
      margin-bottom: pxToRem(6)

    .FormField
      margin-bottom: pxToRem(4)

    .FormField, .SidebarField
      background-color: #f0f0f0
      display: inline-block
      position: relative
      width: calc(100% - pxToRem(10))
      border: pxToRem(1) solid #b0b0b0
      border-radius: pxToRem(6)
      color: black
      box-shadow: pxToRem(1) pxToRem(1) pxToRem(2) pxToRem(0) rgba(0, 0, 0, 0)
      margin-right: pxToRem(8)
      padding-left: pxToRem(4)
      padding-top: pxToRem(2)
      cursor: move
      user-select: none

    .FormField:hover
      transition-duration: 500ms
      background-color: #e0e0f0
      box-shadow: pxToRem(1) pxToRem(1) pxToRem(2) pxToRem(0) rgba(0, 0, 0, 0.3)

    .disabled
      opacity: 0.5
      cursor: default
      pointer-events: none
      border: pxToRem(1) solid rgba(0, 0, 0, .3)

    .FormField-drag-placement
      box-shadow: pxToRem(0) pxToRem(0) pxToRem(5) pxToRem(5) rgba(9,73,220,0.3)

    .dragging
      position: fixed
      top: 0
      left: 0
      opacity: .7

    .preview-parent
      width: 100%
      height: 100%
      overflow: auto
      display: block

      > a, > h3, .ProtocolAnnotator-section
        display: none

    .form-contents-container
      float: left
      overflow: auto
      width: calc(100% - pxToRem(200))
      min-height: calc(100vh - pxToRem(292))
      margin-right: pxToRem(20)
      display: flex
      background-color: rgb(253,253,253)
      border: pxToRem(1) #cccccc solid

      .form-contents
        width: 100%
        white-space: nowrap
        display: flex
        flex-direction: column
        padding: pxToRem(10)

        .FormContents
          position: relative
          width: 100%
          height: 100%


          .render-drag-false, .render-drag-true, .drag-overlay
            position: absolute
            left: 0
            top: 0
            width: 100%

          .drag-overlay
            .FormField--dragging
              z-index: 1000

            .FormField
              padding: 0
              margin-left: 0
              margin-top: 0
              margin-right: pxToRem(2)
              margin-bottom: pxToRem(2)

          .render-drag-true
            opacity: 0

          .render-drag-false
            padding-bottom: pxToRem(39)

        .FormRow
          width: 100%
          margin-top: pxToRem(8)
          min-height: pxToRem(39)
          display: flex
          flex-direction: row

      .form-contents, .FormRow
        width: 100%

        .FormField
          margin-left: pxToRem(10)
          margin-right: pxToRem(10)

          .FormFieldContents
            position: absolute
            overflow: hidden
            display: flex
            top: 0
            left: 2rem
            right: pxToRem(4)
            align-items: center

            .text
              font-size: pxToRem(12)
              padding-top: pxToRem(2)
              width: 100%
              min-width: pxToRem(100)
              text-overflow: ellipsis

            .field
              width: 100%
              height: 100%
              align-items: center
              display: flex
              min-height: pxToRem(32)
              a
                user-select: none
              a:hover
                text-decoration: none

              .ProtocolAnnotator-assignment-hbox
                width: unset
                float: right
                font-size: pxToRem(12)

                .plus-icon
                  font-size: 1.125rem

            input, select
              pointer-events: none
              user-select: none
              width: calc(100% - pxToRem(8))
              border: pxToRem(1) solid #a0a0a0
              padding-top: pxToRem(2)
              padding-bottom: pxToRem(2)
              padding-right: 0
              padding-left: pxToRem(4)

          .btn-move
            display: none
            position: absolute
            left: 0
            top: 0
            padding: pxToRem(4)

          .btn-edit-field
            // position: absolute
            // right: pxToRem(4)
            top: 0
            padding: pxToRem(4)

    .sidebar-contents-container
      float: right
      overflow-y: auto
      overflow-x: hidden
      width: pxToRem(300)

      .Sidebar
        display: block !important
        text-align: left
        user-select: none

        .group-label
          font-weight: bold
          padding-bottom: pxToRem(4)

        .label
          font-weight: normal
          padding-top: pxToRem(10)
          padding-bottom: pxToRem(4)
          display: inline-block
          font-style: italic

        .template-select
          margin-top: pxToRem(10)
          margin-bottom: pxToRem(4)
          width: 100%

  .bottom-bar
    button
      min-width: pxToRem(100)
      padding-left: pxToRem(4)
      padding-right: pxToRem(4)

.ProtocolAnnotator-bottom-buttons
  padding-top: pxToRem(10)

.FormField-selectable
  height: 1.8rem !important

.FormField-selectable-text
  display: flex
  margin-top: 0.2rem
  margin-bottom: 0.2rem
  pointer-events: none

.FormField-selectable-text-inline
  flex: 1
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.FormField-divbtn-view
  padding-right: 0.5rem
  pointer-events: auto

.FormField-ontobranch-grid
  display: grid
  grid-column-gap: 0.5rem
  grid-row-gap: 0.2em
  grid-template-columns: [start term] 1fr [end]

.FormField-ontobranch-item
  align-self: center
  justify-self: start
