div[role=progressbar]:not(.circular_progress)
  margin: pxToRem(10) 0
  background: $denim_05

  > div
    background-color: $denim_50

.infinite_scroll__loader
  display: block
  margin: 0 auto
