// require_self

.light-box-open
  overflow: hidden

.light-box
  display: flex
  width: 100vw
  height: 100vh
  position: fixed
  left: 0
  top: 0
  background-color: $black_40
  justify-content: center
  z-index: 999

  &.hidden
    visibility: hidden

  .subcontainer
    align-self: center
    padding-bottom: pxToRem(24)
    max-height: calc(100vh - pxToRem(60))
    max-width: pxToRem(1000)
    overflow-x: clip
    overflow-y: auto

    .floatRight
      margin: 0
      position: sticky
      top: 0

    .centered
      text-align: center

    .full-width
      width: 100%

      textarea
        width: calc(100% - pxToRem(11))
        min-height: pxToRem(15)
