@import ../../../assets/stylesheets/base/_variables.sass

.OntologyTemplate-indent
  margin-left: 2rem

.OntologyTemplate-toppadding
  padding-top: 1rem !important

.OntologyTemplate-footspace
  margin-bottom: 1rem

.OntologyTemplate-schemaPrefix
  padding: 0.2rem
  border: pxToRem(1) solid #808080
  background-color: #F0F0F0

.OntologyTemplate-schemagrid
  display: inline-grid
  gap: 1rem
  justify-items: start
  align-items: baseline
  grid-template-columns: [start title] auto [uri] auto [permission] auto [end]
  padding-bottom: 1rem
  border-bottom: pxToRem(1) solid $gallery
  margin-bottom: 1rem

.OntologyTemplate-schemagrid-shorter
  display: inline-grid
  grid-column-gap: 0.5rem
  grid-row-gap: 0.2rem
  justify-items: start
  align-items: baseline
  grid-template-columns: [start title] auto [uri] auto [end]
  padding-top: 0.5rem

.OntologyTemplate-schemagrid-entries
  display: grid
  grid-column-gap: 0.5rem
  grid-row-gap: 0.2rem
  justify-items: start
  align-items: baseline
  grid-template-columns: [start title] auto [entry] 1fr [end]
  padding-top: 0.5rem

.OntologyTemplate-edit-hbox
  display: flex
  width: 100%
  flex-direction: row
  justify-content: flex-start
  align-items: center
  gap: 0.5rem

.OntologyTemplate-flexgrow0
  flex-grow: 0

.OntologyTemplate-flexgrow1
  flex-grow: 1

.OntologyTemplate-branch
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  gap: 0.3rem

.OntologyTemplate-group
  padding: 0.2rem 0.3rem 0.2rem 0.3rem
  margin-bottom: 0.4rem
  border-radius: pxToRem(2)
  background-color: $science-blue
  color: white

.OntologyTemplate-assn
  padding: 0.2rem 0.3rem 0.2rem 0.3rem
  margin-bottom: 0.4rem
  border-radius: pxToRem(2)
  background-color: $catskill-white

.OntologyTemplate-value
  padding: 0.2rem 0.3rem 0.2rem 0.3rem
  margin-bottom: 0.4rem
  border-radius: pxToRem(2)
  background-color: $alabaster
  border: pxToRem(1) solid $hit-gray

.OntologyTemplate-clickable
  cursor: pointer
.OntologyTemplate-clickable:hover
  text-decoration: underline

.OntologyTemplate-unclickable
  color: $loblolly !important

.OntologyTemplate-menubutton
  color: $lochmara
  display: inline-block
  margin-left: 0.5rem
  margin-bottom: 0.4rem

.OntologyTemplate-menuparent
  position: relative
  .menuboundary
    position: absolute
    left: calc(-15rem + 100%)
    border: pxToRem(1) solid $regent-gray
    margin-top: 0.5rem
    padding: 0.5rem
    width: 15rem
    background-color: white
    color: $shuttle-gray
    text-align: left
    max-height: 30rem
    overflow: auto

.OntologyTemplate-code
  font-family: monospace
  font-weight: bold
  text-decoration: underline
  color: $outer-space

.OntologyTemplate-oppositesides
  display: flex
  width: 100%
  justify-content: space-between
  align-items: baseline

.OntologyTemplate-topborder
  border-top: pxToRem(1) solid $gallery

.OntologyTemplate-clipboardtext
  width: calc(100% - pxToRem(8))
  max-height: 70vh

.OntologyTemplate-draginto
  background: $pattens-blue

.OntologyComponent-textarea
  font-size: pxToRem(14)
  font-family: Arial
  color: $shuttle-gray

.OntologyComponent-gridlabel
  display: grid
  gap: 0.5rem
  align-items: baseline
  grid-template-columns: [start title] auto [content] 1fr [end]

.OntologyComponent-gridlabelbtn
  display: grid
  gap: 0.5rem
  align-items: baseline
  grid-template-columns: [start title] auto [content] 1fr [button] auto [end]

.OntologyComponent-label
  font-weight: bold

.OntologyComponent-vbox
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: stretch
  gap: 0.5rem

.OntologyComponent-withuri
  height: 75vh

.OntologyComponent-grow
  flex-grow: 1

.OntologyComponent-hierarchy
  width: 80vw
  flex-grow: 1
  border: pxToRem(1) solid $cadet-blue
  border-radius: pxToRem(4)
  padding: 0.5rem
  overflow: scroll

.OntologyComponent-selected
  background-color: $varden

.OntologyComponent-text::placeholder
  color: $loblolly

.ProtocolAnnotator-errors
  margin: 0
  padding: pxToRem(10)
  background-color: $pippin
  color: $amaranth

  ul
    margin: 0

    li:last-of-type
      margin: 0

.ProtocolAnnotator-section
  text-decoration: underline dotted

.ProtocolAnnotator-table-autoscroll
  overflow: auto

.ProtocolAnnotator-table
  width: 100%
  display: inline-block
  text-align: left
  vertical-align: middle
  background: transparent

  th, td
    padding: pxToRem(15) pxToRem(10)
    text-align: left
    border-top: pxToRem(1) solid $gallery

  tr
    &:first-child
      th, td
        border-top: none
    th
      width: 1% !important
      white-space: nowrap
      text-align: left
    td
      white-space: normal
      text-align: left

  .input-text
    min-width: pxToRem(200)
    max-width: 100%
  textarea
    min-width: pxToRem(200)
    max-width: 100%
  .pick-list
    width: pxToRem(410)

  .locked-field-wrapper
    .locked-widget
      display: inline-block
      input, select, textarea
        color: $black_54
      &, & *
        user-select: none
        pointer-events: none
    .fa-lock
      margin-left: pxToRem(12)

.ProtocolAnnotator-label
  padding-right: 1rem

.ProtocolAnnotator-emphasis-underline
  text-decoration: underline

.ProtocolAnnotator-assignment-vbox
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  gap: 0.3rem

.ProtocolAnnotator-assignment-hbox
  display: flex
  width: 100%
  flex-direction: row
  justify-content: space-between
  align-items: center
  gap: 0.3rem

.ProtocolAnnotator-flexgrow0
  flex-grow: 0

.ProtocolAnnotator-flexgrow1
  flex-grow: 1

.ProtocolAnnotator-annotation
  display: inline-block
  font-weight: bold
  border: pxToRem(1) solid $silver
  border-radius: pxToRem(3)
  background-color: $gallery
  padding: 0.3rem

.ProtocolAnnotator-term-button
  display: inline-block
  padding: 0.2rem 0 0.2rem 0
  color: $lochmara
  cursor: pointer
  font-weight: bold

.ProtocolAnnotator-wideinput-wrap
  display: flex
  width: 100%
  align-items: center

  .ui-datepicker-trigger
    width: pxToRem(16)

.ProtocolAnnotator-wideinput-gift
  flex-grow: 1

.ProtocolAnnotator-tooltip
  font-size: pxToRem(11) !important

.ProtocolAnnotator-buttonadd
  padding-left: 1rem
  white-space: nowrap

.ProtocolAnnotator-keycapture
  outline: none

.TermPicker-termline
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  gap: 0.3rem

.TermPicker-searchhit
  background-color: $varden

.TermPicker-label
  font-weight: bold
  padding: 0.2rem 0.3rem 0.2rem 0.3rem

.TermPicker-inactive
  padding: 0.2rem 0.3rem 0.2rem 0.3rem

.TermPicker-selected
  font-weight: bold
  color: white
  background-color: black
  padding: 0.2rem 0.3rem 0.2rem 0.3rem

.TermPicker-clickable
  cursor: pointer

.TermPicker-placeholder
  padding: 0.2rem 0.3rem 0.2rem 0.3rem
  font-weight: bold
  color: $regent-gray

.TermPicker-icon
  width: pxToRem(15)
  height: pxToRem(15)

.TermPicker-toggle
  cursor: pointer

.TermPicker-ancestor-matched
  background: $sunglow
  margin: -pxToRem(3) -pxToRem(3) pxToRem(0) -pxToRem(3)
  padding: pxToRem(3) pxToRem(3) pxToRem(0) pxToRem(3)

.TermPicker-actions
  justify-content: space-between !important

.OntoLabelEdit-field
  width: 100%

.OntoLabelEdit-url
  width: 100%

.OntoLabelEdit-id
  width: 100%

.OntoLabelEdit-string
  width: 100%

.OntoLabelEdit-invalid
  color: $cardinal !important

.OntoLabelEdit-number
  width: 10rem

.OntoLabelEdit-integer
  width: 10rem

.OntoLabelEdit-date
  width: 12rem

.GlobalTemplateSearch-enclosingdiv
  width: 100%
  text-align: right
  position: relative
  > input
    width: 20rem
  .results
    position: absolute
    border: pxToRem(1) solid $regent-gray
    margin-top: 0.5rem
    padding: 0.5rem
    width: 100%
    background-color: white
    text-align: left
    max-height: 30rem
    overflow-x: clip
    overflow-y: auto
    z-index: 1
  .progress
    position: absolute
    top: 0.5rem
    right: 0.5rem
    color: $regent-gray

.GlobalTemplateSearch-noresults
  font-style: italic

.GlobalTemplateSearch-resultflex
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  align-content: flex-start
  gap: 0.5rem

.GlobalTemplateSearch-resultprop
  white-space: nowrap
  padding: 0.3rem

.GlobalTemplateSearch-resultvalue
  white-space: nowrap
  font-weight: bold
  border: pxToRem(1) solid $silver
  border-radius: pxToRem(3)
  background-color: $gallery
  padding: 0.3rem
  cursor: pointer

.AdvancedSearch-filterterms
  display: flex
  align-items: baseline
  gap: 0.5rem
  flex-wrap: wrap
  padding: 0.2rem
  margin-top: -0.2rem
  margin-bottom: -0.2rem
  background: $alabaster
  border: pxToRem(1) solid $gallery

.AdvancedSearch-minortitle
  font-weight: bold
  margin-bottom: 0.2rem

.AdvancedSearch-field
  padding: 0.2rem 0.3rem 0.2rem 0.3rem
  border-radius: pxToRem(2)
  background-color: $catskill-white

.AdvancedSearch-layergrid
  display: inline-grid
  margin-top: 0.5rem
  align-items: center
  grid-gap: 0.5rem
  grid-template-columns: [button] auto [title] auto [field] auto [value] auto

.AdvancedSearch-checklabel
  font-weight: normal

.AdvancedSearch-checkdisabled
  font-weight: normal
  color: $silver

.AdvancedSearch-propgridouter
  position: relative

.AdvancedSearch-propgridwrapper
  position: relative
  padding-right: 1rem

.AdvancedSearch-propgridsvg
  position: absolute

.AdvancedSearch-redzaplink
  color: red
  font-size: large

.AdvancedSearch-propgridhover
  position: absolute
  &:hover
    background-color: rgb(0, 0, 0, 0.1)

.AdvancedSearch-propgridtransp
  position: absolute

.AdvancedSearch-popover-outline
  padding: 1em

.AdvancedSearch-popover-listblock
  margin: 0.5em 0 0 1em

.AdvancedSearch-button-flexrow
  display: flex
  gap: 1rem
