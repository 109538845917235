.mixtureProperties-structurecell
  vertical-align: middle
  padding: 0 1rem 0.5rem 0

.mixtureProperties-detailcell
  padding: 0 0 0.5rem 0
  text-align: left
  vertical-align: middle

.mixtureLinks-flexdiv
  display: flex
  margin-top: 0.5rem
  padding-top: 0.5rem
  align-items: center

.mixtureLinks-overline
  border-top: pxToRem(1) solid $cadet-blue

.mixtureLinks-hidden
  display: none !important

.mixtureLinks-more
  margin-top: 1rem

