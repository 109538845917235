.PlateBlockImporter-tabbar
  display: flex
  justify-content: flex-start
  align-items: center

.PlateBlockImporter-maingrid
  margin-top: 1rem
  position: relative
  display: flex
  flex-direction: row
  align-items: flex-start
  width: 100%
  user-select: none

.PlateBlockImporter-gridleft
  flex-grow: 1
  flex-basis: 0
  overflow: auto
  max-height: 100%

.PlateBlockImporter-gridright
  flex-grow: 0
  flex-basis: auto
  margin-left: 0.5rem
  max-width: 21rem
  > div
    background-color: $alabaster

.PlateBlockImporter-absbottomright
  position: fixed
  bottom: 0.5rem
  right: 3rem

.PlateBlockImporter-cardpadded
  padding: 0.5rem

.PlateBlockImporter-bottom-margin
  margin-bottom: 1rem

.PlateBlockImporter-toolheader
  font-weight: bold

.PlateBlockImporter-chevron
  margin-right: 0.5rem
  cursor: pointer

.PlateBlockImporter-tablegrid
  border-collapse: collapse

.PlateBlockImporter-tablecell
  border: 1px solid $loblolly
  padding: 0.2rem
  min-width: 3rem
  min-height: 1rem
  cursor: pointer

.PlateBlockImporter-tablecell-selected
  background-color: $peach

.PlateBlockImporter-tablecell-content
  background-color: $pattens-blue

.PlateBlockImporter-tablecell-content-high
  background-color: $botticelli

.PlateBlockImporter-tablecell-content-ghost
  background-color: $black-squeeze

.PlateBlockImporter-tablecell-header
  background-color: $anakiwa

.PlateBlockImporter-tablecell-header-high
  background-color: $viking

.PlateBlockImporter-tablecell-header-ghost
  background-color: $black-squeeze

.PlateBlockImporter-tablecell-pointer
  cursor: pointer

.PlateBlockImporter-tablecell-inside
  cursor: no-drop

.PlateBlockImporter-tablecell-outside
  cursor: cell

.PlateBlockImporter-previewheader
  font-weight: bold
  background-color: $gray-nurse
  border: 1px solid $loblolly
  padding: 0.2rem
  min-width: 3rem
  min-height: 1rem

.PlateBlockImporter-previewrowidx
  font-weight: bold
  background-color: $gray-nurse
  border: 1px solid $loblolly
  padding: 0.2rem
  min-height: 1rem
  text-align: center

.PlateBlockImporter-previewcell
  border: 1px solid $loblolly
  padding: 0.2rem
  min-width: 3rem
  min-height: 1rem

.PlateBlockImporter-preview
  font-weight: bold

.PlateBlockImporter-fullwidth
  width: 100%

.PlateBlockImporter-flexsides
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 0.2rem

.PlateBlockImporter-section
  border-top: 1px solid $catskill-white
  margin-top: 0.2rem

.PlateBlockImporter-section-highleft
  background-color: $aqua-haze
  padding-left: 0.2rem
  margin-left: -0.2rem
  padding-right: 0.2rem
  margin-right: -0.2rem
  padding-bottom: 0.2rem
  margin-bottom: -0.2rem

.PlateBlockImporter-section-highright
  border-left: 5px solid $aqua-haze
  margin-left: -5px
  border-right: 5px solid $aqua-haze
  margin-right: -5px

.PlateBlockImporter-grid-labelvalue
  display: grid
  grid-template-columns: [label] auto [value] 1fr
  grid-gap: 0.5rem 0.2rem
  align-items: center
  margin-bottom: 1rem

  > div
    min-height: 1.5rem

  input
    padding: .4rem

.PlateBlockImporter-sidebar-input
  width: 10rem

.PlateBlockImporter-sidebar-input-stunted
  width: 6rem

.PlateBlockImporter-iconbutton
  display: inline-block
  cursor: pointer
  margin-left: 0.5rem

.PlateBlockImporter-header-load
  margin-bottom: 1rem

.PlateBlockImporter-footer-save
  margin-top: 1rem
  display: flex
  gap: 1rem

.PlateBlockImporter-alert
  left: 0
  bottom: 0
  pointer-events: none
  position: fixed
  width: 100%
  z-index: 1500

.PlateBlockImporter-td-padleft
  padding-left: 1rem

.PlateBlockImporter-unmatched
  color: $hit-gray

.PlateBlockImporter-buttons-proceed
  text-align: right
  margin: 0.5rem 0 0.5rem 0

.PlateBlockImporter-button-inline
  display: inline-block

.PlateBlockImporter-processing
  text-align: center
  margin: 0.5rem 0 0.5rem 0
  padding: 0.5rem
  border: 1px solid $cadet-blue
  background-color: $link-water

.PlateBlockImporter-problem
  color: $vermilion

.PlateBlockImporter-popover-outline
  padding: 1rem

.PlateBlockImporter-savename-edit
  width: 25rem
  margin-right: 1.5rem !important

.PlateBlockImporter-twosides
  display: flex
  justify-content: space-between

.PlateBlockImporter-uploadbuttonwidth
  max-width: 10rem

.PlateBlockImporter-upload-rowflex
  display: flex
  gap: 1rem
  justify-content: flex-start
  align-items: center

.PlateBlockImporter-matchmessage
  border: 1px solid $cadet-blue
  background-color: $link-water
  padding: 0.5rem
  margin-bottom: 0.5rem

.PlateBlockImporter-pager
  margin-bottom: 0.5rem

.PlateBlockImporter-dialogtitle
  padding: 0 1rem 0.5rem 0

.PlateBlockImporter-nowrap
  white-space: nowrap

.PlateBlockImporter-input-inline
  display: inline-block

  input
    padding: 0.2rem

.PlateBlockImporter-input-grow
  width: calc(100% - 0.5rem)

.PlateBlockImporter-keeptogether
  white-space: nowrap

.PlateBlockManager-section
  margin-top: 0.5rem
  display: flex
  gap: 0.5rem

.PlateBlockManager-tabgroup
  margin-top: 1em
  border-bottom: 1px solid $cadet-blue

.PlateBlockManager-footer-buttons
  margin-top: 1rem
  text-align: right

.PlateBlockManager-saveflex
  display: flex
  gap: 0.5rem
  align-items: center
  margin: 0.5rem 0 0.5rem 0

.PlateBlockManager-savename-edit
  min-width: 25rem

.PlateBlockManager-textarea
  font-size: pxToRem(14)
  font-family: Arial
  color: $shuttle-gray
  margin: 0.5rem 0 0.5rem 0
