#miniApp
  height: 100%
  width: 100%
  overflow: hidden


// header
.miniApp-header
  display: flex
  background: $denim
  color: $white
  justify-content: space-between
  align-items: flex-start
  padding: 0 1.5rem
  flex-direction: column

  @media (min-width: pxToRem(500))
    align-items: center
    flex-direction: row

  .separator, .muted
    color: $danube

  a
    color: $danube

    &:hover,
    &:active
      color: $white

#miniApp-header-title
  margin: 1rem 0
  font-size: pxToRem(20)
  line-height: 1

#miniApp-header-right
  color: $polo-blue
  font-weight: bold
  padding: 0.5rem 0 0

  @media (min-width: pxToRem(500))
    align-self: flex-end

  a,
  a:hover
    margin: 0 pxToRem(3) 0 0
    color: $white
    background: none repeat scroll 0 0 $venice-blue

  a.active
    color: $black
    background: none repeat scroll 0 0 $alabaster !important

#miniApp-header-beta
  display: inline-block
  position: relative

#miniApp-header-beta-inner
  display: block
  position: absolute
  top: -pxToRem(13)
  left: pxToRem(3)
  background: $white url("../images/cdd30/layout/miniapps/beta-bg-left.gif") no-repeat top left
  color: $monza
  font-weight: normal
  font-size: pxToRem(11)
  font-style: italic
  line-height: pxToRem(12)

  span
    display: block
    padding: pxToRem(3) pxToRem(22) 0 pxToRem(7)
    height: pxToRem(15)
    width: $sidebar-width
    background: url("../images/cdd30/layout/miniapps/beta-bg-right.gif") no-repeat top right

  img
    position: absolute
    top: pxToRem(1)
    right: 0


// content
#miniApp-content
  clear: both
  position: relative
  width: 100%

#updateErrors
  left: 50%
  position: fixed
  z-index: 9000
  margin-left: -pxToRem(127)

.padder
  padding: pxToRem(25)
  overflow: hidden


// Panels : for apps with sidebar and main content
#miniApp-panels
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  overflow: hidden

  .miniApp-panels-panel
    position: absolute
    top: 0
    height: 100%
    overflow: auto

  #miniApp-panels-sidebar
    width: pxToRem(220)
    background: $black-squeeze
    font-size: pxToRem(11)
    .padder
      padding: pxToRem(20) pxToRem(10) pxToRem(20) pxToRem(25)
    .section
      margin: 0
      padding: 0
      .section-content
        margin: 0 0 pxToRem(10) pxToRem(13)
    .drilldown-menu
      margin-top: -pxToRem(7)
      padding: 0
      width: 100%
      h5
        white-space: nowrap
        text-transform: uppercase

      h5.collapsible-title
        margin: pxToRem(10) 0 pxToRem(5) -pxToRem(15)
        a
          color: $mine-shaft
      .collapsible-closed h5.collapsible-title a
        color: $dim-gray
      .collapsible-inner
        padding: 0
    p
      margin: 0 0 pxToRem(5) 0
    table.simple
      margin: 0 0 pxToRem(10) 0
      td
        padding: pxToRem(3) 0 pxToRem(3) pxToRem(10)
    th, td
      font-size: pxToRem(11)
    table.form tbody th
      padding: pxToRem(7) 0 0 0
      white-space: nowrap
    table.form tbody td
      padding-left: pxToRem(6)
    table.form .text
      padding-top: pxToRem(6)
    .input-text
      font-size: pxToRem(11)
      padding: pxToRem(2)
      width: 100%
      &.fitParameter
        width: pxToRem(30)

  #miniApp-panels-main
    background: $alabaster

  #miniApp-panels-resize
    margin: 0
    width: pxToRem(11)
    height: 100%
    background: url("../images/cdd30/layout/miniapps/resize.png") no-repeat -pxToRem(5) 50%
    cursor: col-resize

  #miniApp-panels-sidebar-fade
    margin: -pxToRem(20) 0 0 0
    position: absolute
    top: 0
    right: 0
    width: pxToRem(20)
    height: 100%
    background: url("../images/cdd30/layout/miniapps/sidebar-fade.png") repeat-y top right
    pointer-events: none

  &.sidebar-left
    #miniApp-panels-sidebar
      left: 0
      @media (max-width: pxToRem(699))
        display: none

    #miniApp-panels-main
      border-left: pxToRem(1) solid $alto
      left: pxToRem(220)
      @media (max-width: pxToRem(699))
        left: 0
        width: 100%

    #miniApp-panels-resize
      left: pxToRem(220)
      @media (max-width: pxToRem(699))
        display: none

    #miniApp-panels-sidebar-fade
      right: auto
      left: pxToRem(220)
      margin-left: -pxToRem(20)
      @media (max-width: pxToRem(699))
        display: none

  &.sidebar-right
    #miniApp-panels-sidebar
      left: 73%
    #miniApp-panels-main
      border-right: pxToRem(1) solid $alto
      left: 0
    #miniApp-panels-resize
      left: 73%
