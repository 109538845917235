@use "@fontsource/noto-sans/scss/mixins" as NotoSans

// Fonts
@include NotoSans.faces($directory: "~@fontsource/noto-sans/files", $subsets: all, $weights: all, $styles: all)

$fa-font-path: "~font-awesome/fonts"
@import '../../../node_modules/font-awesome/scss/font-awesome.scss'
@import '../../../node_modules/@cdd/eln/dist/elnEntry/main.css'
@import '../../../node_modules/ketcher-react/dist/index.css'
@import '../../../node_modules/react-contexify/dist/ReactContexify.css'

// Base
@import base/variables
@import base/placeholders
@import base/mixins
@import base/elements

// Layout
@import layout/content
@import layout/footer
@import layout/header
@import layout/wrapper
@import layout/wide

// Vendor Overrides : jQuery
@import vendor/jquery/datepicker
@import vendor/jquery/dialog

// Vendor Overrides : Material
@import vendor/material/react_component
@import vendor/material/button
@import vendor/material/datatable
@import vendor/material/form
@import vendor/material/icon
@import vendor/material/progress-bar
@import vendor/material/slate

// Components
@import components/abstract-resource
@import components/activity-log
@import components/alerts
@import components/animations
@import components/announcements
@import components/audit-trail
@import components/autocomplete
@import components/balloon
@import components/big-data-table
@import components/buttons
@import components/check-list
@import components/checklist
@import components/columns
@import components/container
@import components/column-editor
@import components/colour-select
@import components/data-filters
@import components/data-ownership
@import components/data-table
@import components/datepicker
@import components/description-list
@import components/dose-response-plot
@import components/drilldown
@import components/editable-data
@import components/file
@import components/flags
@import components/form
@import components/grid
@import components/hit-color
@import components/icons
@import components/light-box
@import components/list
@import components/loading
@import components/message
@import components/miniapp
@import components/mixture-editor
@import components/modal
@import components/molecule-preview
@import components/page-header
@import components/pagination
@import components/plate
@import components/pick-lists
@import components/popup
@import components/radio-checkbox
@import components/required
@import components/select-option
@import components/share
@import components/share-dialog
@import components/slurper-compose-mixtures
@import components/sidebar
@import components/structure-editor
@import components/table
@import components/text
@import components/title-user-tag
@import components/toggle-switch
@import components/vault_admin

// Components : new
@import components/section

// Partials
@import partials/admin_users
@import partials/admin_vaults
@import partials/admin_vault_memberships
@import partials/api-key
@import partials/dashboard
@import partials/data-sources
@import partials/deletion-warning
@import partials/eln-witnessing-settings
@import partials/heat-map-controls
@import partials/help
@import partials/import
@import partials/inventory
@import partials/locked-vaults
@import partials/mapping
@import partials/mixture-properties
@import partials/molecule-collection
@import partials/molecule-edit
@import partials/molecule-index
@import partials/molecule-show
@import partials/new-molecule
@import partials/new-user
@import partials/password
@import partials/prelogin
@import partials/project-settings
@import partials/protocol
@import partials/protocol-mapper
@import partials/resource-project
@import partials/run
@import partials/saved-search
@import partials/search
@import partials/search-results
@import partials/settings
@import partials/settings-alerts
@import partials/slurp
@import partials/step-progress
@import partials/suggestions
@import partials/vault-admin
@import partials/vault-reports

// Partials : ELN
@import partials/eln/assay-annotation
@import partials/eln/editor
@import partials/eln/editor-toolbar
@import partials/eln/entries-import
@import partials/eln/entry
@import partials/eln/entry-actions
@import partials/eln/fields
@import partials/eln/file-upload-progress-bar
@import partials/eln/inline
@import partials/eln/link-dialog
@import partials/eln/mixtures
@import partials/eln/project-dialog
@import partials/eln/search-bar
@import partials/eln/search-results
@import partials/eln/stoichiometry
@import partials/eln/table
@import partials/eln/timestamp
@import partials/eln/uploaded-file

// Print
@import print/print

// Hacks
@import hacks

@import '../../frontend/index'
@import dialog
@import darkmode
