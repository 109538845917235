.control-layouts
  margin-bottom: pxToRem(20)

.heatMap-controls
  float: right
  .item
    margin: 0 0 0 pxToRem(20)
    float: left
  .controlExample
    margin: 0 pxToRem(6) 0 0
    float: left
    width: pxToRem(10)
    height: pxToRem(10)
    border: pxToRem(2) solid
    background: $white
  .controlExample-positive
    border-color: $red-orange
  .controlExample-reference
    border-color: $sunglow
  .controlExample-negative
    border-color: $dodger-blue
