@import ../../../../assets/stylesheets/base/_variables

.DDForm
  .ddform-fieldset
    padding-top: 1rem
    padding-left: 1rem
    padding-right: 1rem
    padding-bottom: .5rem
    font-family: Roboto, Helvetica, Arial, sans-serif
    border-radius: pxToRem(4)
    border: pxToRem(1) solid #ccc
    margin-bottom: pxToRem(8)
    > div
      margin-left: 0

  .bottom-modal-row
    button
      min-width: pxToRem(100)

  fieldset.error
    color: #d95a00
    border: 1px solid #d95a00
