#slurp_type
  padding-top: pxToRem(5)
  padding-bottom: pxToRem(11)

  select
    margin-top: pxToRem(4)

#slurp_summary
  display: inline-block
  margin-bottom: pxToRem(0)

#mapper-registrationType
  .actions
    display: inline-block
    button
      margin-left: pxToRem(10)
      float: none



.disabled #mapper-picker
  display: none
