.ui-dialog.ui-widget.pick-list-editor
  padding: 0
  border: none

  .ui-dialog-titlebar-close
    display: none

  .ui-dialog-titlebar
    margin: 0 pxToRem(20) 0
    padding: pxToRem(20) 0 pxToRem(10)
    border: none
    border-bottom: pxToRem(1) solid $mercury
    font-weight: normal
    font-size: pxToRem(16)
    background: none
    color: $color

    .ui-button
      margin: -pxToRem(15) 0 0
      border: none
      background: none

  .ui-dialog-content
    margin: 0
    padding: 0 pxToRem(20)

  .ui-dialog-buttonpane
    margin: pxToRem(20)
    padding: 0
    border: none

    .buttony
      margin-top: pxToRem(4)

    a.cancel
      color: $color_warning

.ui-dialog
  max-width: pxToRem(800)
  background: $white

.ui-dialog-titlebar
  background: $denim
