.dose-response-plot
  margin: 0 0 pxToRem(2) 0
  padding: 0

.dose_response_plot_loading
  width: pxToRem(324)
  height: pxToRem(200)

.dose-response-plot-miniapp-toggle
  float: right

table.dose_response_plot_legend
  border: none
  padding: pxToRem(10)

  .swatch
    margin: 0 0.5rem 0.25rem 0.5rem
    float: left
    width: 1rem
    height: 1rem

  .line
    margin: 0.4rem 0.3rem 0.1rem 0.1rem
    float: left
    width: 1rem
    height: 0.2rem

  tr.run_and_batch
    font-size: pxToRem(17.6)
    font-weight: bold
    white-space: nowrap

  .protocol-condition
    padding-left: pxToRem(15)

  td
    border: none
    padding: pxToRem(2)
    white-space: nowrap
    background: transparent

  td.label
    padding: pxToRem(2) 1rem
    width: pxToRem(100)

  tr.blank
    td
      padding: 0.5rem

  tr.control
    td
      padding: 0.5rem 0 0 0


// Dose-response app
#doseResponse-source
  padding: pxToRem(20) pxToRem(20) 0
  #molecule_image
    .thumbnail
      width: pxToRem(175)
      height: pxToRem(175)
  .structure-image
    width: 100%
    max-height: pxToRem(175)

  #molecule_name
    font-weight: bold
  #protocol_name
    padding: pxToRem(10) 0 0 0
    font-weight: bold

#doseResponse-curveClassification
  margin: 0
  th, td
    padding: pxToRem(5)
  table
    margin: 0
    &.dataTable
      margin: pxToRem(10) 0 0 0
  .curveClass
    white-space: normal
    width: pxToRem(30)
  td.curveClass
    padding: pxToRem(5) pxToRem(10)
