.container--fade
  box-sizing: border-box
  height: 0
  opacity: 0
  transition: height 100ms, opacity 100ms

  &.visible
    height: initial
    opacity: 1
    transition: height 500ms ease, opacity 500ms ease
