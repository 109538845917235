.InventoryFieldDefinitions
  .InventoryFieldDefinitionsList:nth-child(1)
    padding-bottom: pxToRem(40)

  .uniqueness-note
    padding-top: pxToRem(20)

  .single-use-option
    .single-use-checkbox
      label
        font-weight: normal
      input
        width: unset
