.data-filters
  width: 100%

  .left
    float: left
    .item
      margin-right: pxToRem(10)

  .right
    float: right
    .item
      margin-left: pxToRem(10)

  .item
    float: left
    line-height: pxToRem(25)
    .input-radio
      vertical-align: text-bottom
    .input-checkbox
      vertical-align: middle
    // The ? image to the right of add molecule disabled link
    img[title]
      padding-top: pxToRem(4)

  .separator
    padding: 0 pxToRem(10)
    font-size: pxToRem(16)
    font-weight: bold
    line-height: pxToRem(25)

  strong
    padding: 0 pxToRem(5) 0 0

  .hr
    margin: 0 0 pxToRem(20) 0
    padding: pxToRem(25) 0 0 0

  select
    padding: pxToRem(3)
    background: $white
    border: pxToRem(1) solid $alto
    font-size: pxToRem(12)

.data-filters__show

  .left
    font-size: pxToRem(14)
    .item
      .icon-16
        margin: 0
        vertical-align: -pxToRem(2)
      .input-radio
        margin: 0 pxToRem(3) 0 0
        padding: 0
        vertical-align: middle
        position: relative
        top: -pxToRem(1)

  .separator
    padding: 0 pxToRem(6)
    font-size: pxToRem(14)

  strong
    padding-right: pxToRem(7)

  .hr
    padding: pxToRem(20) 0 0 0

.data-filters-flex
  display: flex
  align-items: center
  justify-content: space-between
