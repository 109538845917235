.inline-container
  display: inline-flex
  margin: pxToRem(10) pxToRem(20) pxToRem(10) 0
  position: relative
  justify-content: flex-start
  align-items: flex-start
  flex-direction: column

  a:hover
    text-decoration: none
    color: $ui_hover

    .doctype-icon
      background-color: $ui_hover

    &.disabled
      color: $dusty-gray
      cursor: default
      text-decoration: none
      background: none

  .top-left
    left: 0
    top: 0
    position: absolute
    padding: pxToRem(15)
    padding-top: pxToRem(11)
    white-space: nowrap

  .top-right
    right: 0
    top: 0
    position: absolute
    padding: pxToRem(15)
    padding-top: pxToRem(11)
    white-space: nowrap
    height: pxToRem(14)

  .bottom-left
    left: 0
    bottom: 0
    position: absolute
    padding: pxToRem(15)
    padding-top: pxToRem(11)
    white-space: nowrap
    z-index: 2

  .remove,
  .download,
  .editToggle
    top: 0
    padding: pxToRem(13)
    position: absolute
    text-shadow: pxToRem(1) pxToRem(1) pxToRem(3) $alabaster, pxToRem(1) -pxToRem(1) pxToRem(3) $alabaster, -pxToRem(1) pxToRem(1) pxToRem(3) $alabaster, -pxToRem(1) -pxToRem(1) pxToRem(3) $alabaster
    z-index: 2
    font-size: pxToRem(16)

    .tooltiptext
      text-shadow: none

  .download
    left: 0
    line-height: 1rem

  .showTableLink
    vertical-align: middle
    padding: pxToRem(2)

  .remove
    right: 0
    color: $shuttle-gray

  .hidden
    display: none

.structure.inline-container
  width: pxToRem(270)
  margin-bottom: -pxToRem(27)
  top: pxToRem(2)
  margin-top: pxToRem(8)

  img, .ChemistryImage
    height: pxToRem(250)

.reaction.inline-container
  width: pxToRem(882)
  align-items: stretch
  margin-bottom: -pxToRem(18)

  img, .ChemistryImage
    min-height: pxToRem(250)
    padding: pxToRem(2)

.inline-container--image
  display: flex
  padding: pxToRem(10)
  border-radius: pxToRem(2)
  border: pxToRem(1) solid $border
  box-sizing: border-box
  object-fit: contain
  max-width: 100%
  min-width: pxToRem(270)
  flex-direction: column

  &:hover
    border: pxToRem(1) solid $color_brand

  &.editable:hover
    cursor: pointer

  & .stoichiometry
    position: relative

    & .edit__control
      display: none

    &:hover .edit__control, & .edit__control.active
      display: block

.comment__editor > div
  border-color: $gallery
  border-style: solid
  border-width: pxToRem(1)
  border-radius: pxToRem(2)
  padding: pxToRem(5)
  line-height: 1.5

.inline-container--small
  display: flex
  box-sizing: border-box
  height: pxToRem(42)
  padding: pxToRem(5)
  border: pxToRem(1) solid $border
  border-radius: pxToRem(2)
  background: $alabaster
  align-items: center
  width: pxToRem(270)

.inline-container--tiny
  display: flex
  margin: 0
  box-sizing: border-box
  height: pxToRem(21)
  padding: pxToRem(2)
  border: pxToRem(1) solid $border
  border-radius: pxToRem(2)
  background: $alabaster
  align-items: center
  width: pxToRem(135)

  &:hover
    border: pxToRem(1) solid $color_brand

.inline-container--error
  display: flex
  box-sizing: border-box
  height: pxToRem(42)
  padding: pxToRem(5)
  border: pxToRem(1) solid $red
  border-radius: pxToRem(2)
  align-items: center
  width: pxToRem(270)
  background: $bg_warning
  color: $amaranth

  &:hover
    border: pxToRem(1) solid $color_brand

.inline-container--active .inline-container--image,
.inline-container--active .inline-container--small,
.inline-container--active .inline-container--error
  z-index: 1
  border-color: $color_brand

// isVoid nodes do weird shimmies with the caret position. By setting it to
// inline-block, it seems to work better. Not a problem on Firefox?
span[data-slate-void="true"]
  display: inline-flex

.inline-container__inner-content
  display: flex
  margin: pxToRem(0) pxToRem(20) pxToRem(0) pxToRem(10)
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  flex-direction: column

.pdb_viewer_container
  top: pxToRem(5)

.inline-container__pdb-viewer
  width: pxToRem(248)
  height: pxToRem(248)
  position: relative

.molecule-names__container
  display: inline-flex
  margin: 0 auto
  flex-direction: column
  width: 100%
  align-items: center

  a
    font-size: pxToRem(20)
    padding: pxToRem(5)

    &:hover
      text-decoration: underline

.molecule-status__container
  align-items: center
  background-color: $bg_warning
  color: $amaranth

  .molecule-status__container__text
    display: inline-block
    padding: pxToRem(5)

.molecule-name
  position: relative

  .link__edit
    top: -pxToRem(4)

.office-icon
  display: inline-block
  height: pxToRem(30)
  min-width: pxToRem(30)
  width: pxToRem(30)
  line-height: pxToRem(30)

.doctype-icon
  display: inline-block
  height: pxToRem(30)
  min-width: pxToRem(30)
  width: pxToRem(30)
  line-height: pxToRem(30)
  font-size: pxToRem(24)
  text-align: center
  text-transform: capitalize
  color: $alabaster
  border-radius: pxToRem(2)
  background-color: $denim

.doctype-icon--doc
  background-color: $ui

.doctype-icon--pdf
  background-color: $pink

.doctype-icon--xlsx
  background-color: $turquoise

.doctype-icon--image
  background-color: $denim

.doctype-icon--default
  background-color: $denim
