.radio__label,
.checkbox__label
  display: inline-block
  margin-top: 0 !important // override inline style
  padding: pxToRem(4) 0
  cursor: pointer
  line-height: 1

  input[type="checkbox"],
  input[type="radio"]
    margin: 0 pxToRem(4) 0 0
    cursor: pointer
    top: 0 // override old design
    vertical-align: bottom

.radio__label__secondary,
.checkbox__label__secondary
  opacity: 0.5

  &:hover
    opacity: 1

.radio__text,
.checkbox__text
  display: inline-block
