.molecule-img__structureless
  @extend %molecule-img__structureless

// molecule preview
#molecule-preview
  display: flex
  align-items: center
  flex-direction: column
  height: pxToRem(225)
  justify-content: center
  padding: 0 0 pxToRem(25)

  .ChemistryImage
    height: pxToRem(230) !important
    svg
      height: pxToRem(210) !important
      max-width: pxToRem(210)

  img:not(.thumbnail.no_structure):hover
    box-shadow: 0 pxToRem(2) pxToRem(4) $box-shadow

.molecule-preview-divsvg
  border: pxToRem(1) solid transparent
  &:hover
    border: pxToRem(1) solid $lochmara
    box-shadow: 0 pxToRem(2) pxToRem(4) $box-shadow

// The popup from the preview on the molecule show page
.nucleotide_sequence, .amino_acid_sequence
  .sequence_image_container
    height: calc(100% - pxToRem(32))
  table.simple.form.wideForm
    input
      width: 100%
      text-overflow: ellipsis
    td
      width: 100%
      a
        padding: pxToRem(5) 0 0 pxToRem(5)

.molecule-img__structureless
  margin: pxToRem(25)
  width: pxToRem(119)
  height: pxToRem(119)
  padding: pxToRem(25)

  &:hover
    box-shadow: none


// molecule popup
.molecule-download-copy-popup
  .subcontainer
    min-width: pxToRem(450)
    min-height: pxToRem(200)

  .container,
  .container-content
    padding: 0

  .container-tab
    a
      display: block

  img
    margin: 0
    padding: 0

    &:hover
      box-shadow: none !important

  textarea
    margin-top: pxToRem(6)
    height: pxToRem(16) !important
    padding: pxToRem(6)
    width: calc(100% - pxToRem(57))
    resize: none
  .copy_icon
    display: inline-block
    vertical-align: top
    padding: pxToRem(15) 0 0 pxToRem(4)

// search table
.molecule_column
  .molecule-img__structureless
    margin: pxToRem(20)
    border-radius: 50%
    padding: pxToRem(20)
    width: pxToRem(139)
    height: pxToRem(139)

  .ChemistryImage
    svg
      width: pxToRem(219)
      height: pxToRem(219)

// eln link modal
.structure-label__label
  .molecule-img__structureless
    margin: 1rem
    width: 7rem
    height: 7rem
    padding: 0.5rem

[title="There is no structure to display"]
  .structure-label__label:hover
    border-color: $catskill-white


// details popup
.details-popup
  .molecule-img__structureless
    margin: pxToRem(10)
    width: pxToRem(84)
    height: pxToRem(84)
    padding: pxToRem(10)
