.SaltAndSolventEditor
  .salt-name, .solvent-name
    position: absolute
    z-index: 10000
    pointer-events: none
    opacity: 0.0001

  .Autocomplete-disabled
    div
      background-color: $alabaster
