.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 h1 {
  font-size: 32px;
  line-height: 1em;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 h2:not(.muiDialog-title) {
  margin: 0 0 1.25em;
  font-size: 16px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 h3 {
  font-size: 14px;
  line-height: 1.25em;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 ul {
  margin: 0 0 1.25em;
  padding: 0 0 0 1.25em;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 input[type=text]:not(.material-input):not(.excludeInputStyle) {
  margin: 0;
  font-size: 1em;
  border: 1px solid #e6e6e6;
  background: #fff;
  color: #545d66;
  border-radius: 2px;
  padding: 4px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 input[type=text]:not(.material-input):not(.excludeInputStyle).input-text.error {
  border: thin #c00 solid;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 input[type=text]:not(.material-input):not(.excludeInputStyle):focus {
  outline: 2px solid #97d4ff;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 input[type=text]:not(.material-input):not(.excludeInputStyle):disabled {
  color: #bbb;
  background-color: #f7f7f7 !important;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 textarea:not(.comment__editor textarea):not(.excludeTextareaStyle) {
  padding: 4px;
  background: #fff;
  border: 1px solid #ddd;
  font-size: 12px;
  border-radius: 2px;
  resize: vertical;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 textarea:not(.comment__editor textarea):not(.excludeTextareaStyle)#message_body.error {
  border: thin #c00 solid;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 textarea:not(.comment__editor textarea):not(.excludeTextareaStyle)#reply_body.error {
  border: thin #c00 solid;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 textarea:not(.comment__editor textarea):not(.excludeTextareaStyle)#molecule_smiles.error {
  border: thin #c00 solid;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 textarea:not(.comment__editor textarea):not(.excludeTextareaStyle)#formatted_formula_custom_calculation.error {
  border: thin #c00 solid;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 table,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 thead,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 tbody,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 tr,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 th,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 td {
  font-size: 12px;
  font-family: helvetica,arial,sans-serif;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 ul.MuiList-root.MuiList-padding.MuiMenu-list {
  display: flex;
  flex-direction: column;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters {
  padding: 6px 16px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln {
  font-size: 14px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln input:not(.excludeInputStyle),
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln textarea:not(.excludeTextareaStyle) {
  color: #000;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln input:not(.excludeInputStyle):hover,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln input:not(.excludeInputStyle):active,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln input:not(.excludeInputStyle):focus,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln textarea:not(.excludeTextareaStyle):hover,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln textarea:not(.excludeTextareaStyle):active,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln textarea:not(.excludeTextareaStyle):focus {
  color: #07c !important;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln #mixtureEditor input:not(.excludeInputStyle):hover,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln #mixtureEditor input:not(.excludeInputStyle):active,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln #mixtureEditor input:not(.excludeInputStyle):focus,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln #mixtureEditor textarea:not(.excludeTextareaStyle):hover,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln #mixtureEditor textarea:not(.excludeTextareaStyle):active,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln #mixtureEditor textarea:not(.excludeTextareaStyle):focus,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .assayAnnotationDialog-content input:not(.excludeInputStyle):hover,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .assayAnnotationDialog-content input:not(.excludeInputStyle):active,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .assayAnnotationDialog-content input:not(.excludeInputStyle):focus,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .assayAnnotationDialog-content textarea:not(.excludeTextareaStyle):hover,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .assayAnnotationDialog-content textarea:not(.excludeTextareaStyle):active,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .assayAnnotationDialog-content textarea:not(.excludeTextareaStyle):focus,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .comment__editor input:not(.excludeInputStyle):hover,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .comment__editor input:not(.excludeInputStyle):active,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .comment__editor input:not(.excludeInputStyle):focus,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .comment__editor textarea:not(.excludeTextareaStyle):hover,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .comment__editor textarea:not(.excludeTextareaStyle):active,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .comment__editor textarea:not(.excludeTextareaStyle):focus,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .link__href input:not(.excludeInputStyle):hover,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .link__href input:not(.excludeInputStyle):active,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .link__href input:not(.excludeInputStyle):focus,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .link__href textarea:not(.excludeTextareaStyle):hover,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .link__href textarea:not(.excludeTextareaStyle):active,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .link__href textarea:not(.excludeTextareaStyle):focus,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .StructureEditorDialog input:not(.excludeInputStyle):hover,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .StructureEditorDialog input:not(.excludeInputStyle):active,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .StructureEditorDialog input:not(.excludeInputStyle):focus,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .StructureEditorDialog textarea:not(.excludeTextareaStyle):hover,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .StructureEditorDialog textarea:not(.excludeTextareaStyle):active,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .eln .StructureEditorDialog textarea:not(.excludeTextareaStyle):focus {
  color: #000 !important;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container {
  display: inline-flex;
  margin: 10px 20px 10px 0;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container a:hover {
  text-decoration: none;
  color: #07c;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container a:hover.disabled {
  color: #999;
  cursor: default;
  text-decoration: none;
  background: none;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container .hidden {
  display: none;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container--image {
  display: flex;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #e6ecf2;
  box-sizing: border-box;
  object-fit: contain;
  max-width: 100%;
  min-width: 270px;
  flex-direction: column;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container--image:hover {
  border: 1px solid #004fbe;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container--image.editable:hover {
  cursor: pointer;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container--image .stoichiometry {
  position: relative;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container--image .stoichiometry .edit__control {
  display: none;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container--image .stoichiometry:hover .edit__control,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container--image .stoichiometry .edit__control.active {
  display: block;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .comment__editor>div {
  border-color: #eee;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  padding: 5px;
  line-height: 1.5;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container--small {
  display: flex;
  box-sizing: border-box;
  height: 42px;
  padding: 5px;
  border: 1px solid #e6ecf2;
  border-radius: 2px;
  background: #f7f7f7;
  align-items: center;
  width: 270px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container--tiny {
  display: flex;
  margin: 0;
  box-sizing: border-box;
  height: 21px;
  padding: 2px;
  border: 1px solid #e6ecf2;
  border-radius: 2px;
  background: #f7f7f7;
  align-items: center;
  width: 135px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container--tiny:hover {
  border: 1px solid #004fbe;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container--error {
  display: flex;
  box-sizing: border-box;
  height: 42px;
  padding: 5px;
  border: 1px solid red;
  border-radius: 2px;
  align-items: center;
  width: 270px;
  background: #ffe5e5;
  color: #e6364c;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container--error:hover {
  border: 1px solid #004fbe;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container--active .inline-container--image,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container--active .inline-container--small,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container--active .inline-container--error {
  z-index: 1;
  border-color: #004fbe;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container__inner-content {
  display: flex;
  margin: 0px 20px 0px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-direction: column;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .pdb_viewer_container {
  top: 5px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .inline-container__pdb-viewer {
  width: 248px;
  height: 248px;
  position: relative;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .default-label__label {
  font-size: 12px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .structure-label__label {
  border: 1px solid #e6ecf2;
  border-radius: 2px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .link__display-options {
  margin-top: 30px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table {
  margin: 0 2em 1em;
  width: calc(100% - 4em);
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table tbody tr {
  border-top: 1px solid #e6ecf2;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table tbody tr:nth-of-type(1) {
  border-color: #888;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table tbody tr.stoichiometry-table-solvent {
  border-top-width: 0px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table tbody tr.stoichiometry-table-solutionSolvent {
  border-top-width: 0px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table tbody tr.stoichiometry-table-comment {
  border-top-width: 0px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table tbody tr.stoichiometry-table-comment td.comment-view-mode {
  border-top: 1px solid #e6ecf2;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table tbody tr.stoichiometry-table-comment td.comment-view-mode .row-item {
  word-break: break-word;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table.editable:hover {
  cursor: pointer;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table tr.stoichiometry-table-product:nth-of-type(1) {
  border-color: #888;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table th {
  text-align: left;
  padding: .5em;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table td {
  vertical-align: top;
  padding: .5em;
  max-width: 200px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table td td>div {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table td .noneditable {
  opacity: .5;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table td .required {
  margin: 0px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table td .optional {
  margin: 0px;
  color: #593216;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table .editable {
  cursor: pointer;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table .editable label {
  cursor: pointer;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table .row-item.editable {
  cursor: default;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table .row-item.editable span {
  cursor: pointer;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table .row-item.editable input[type=radio] {
  cursor: pointer;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table .row-item.noneditable {
  cursor: default;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table .row-item.noneditable span {
  cursor: pointer;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table .actions {
  text-align: center;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table .limitingReagent {
  color: #1262b3;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table .comment::placeholder {
  color: #593216;
  opacity: 1;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table .comment:focus::placeholder {
  color: rgba(0,0,0,0);
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table .comment {
  font-size: 12px;
  padding: 0px;
  cursor: pointer;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table .comment:focus,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table .comment:active,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table .comment:hover {
  color: #545d66 !important;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__dialog>div {
  overflow: visible;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__dialog .validation_error {
  padding: 8px;
  font-size: 12px;
  color: #e6364c;
  background: #ffe5e5;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__dialog .register_link {
  color: #1e9af1;
  font-weight: 400;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled:focus-within {
  opacity: .5;
  border: none;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled label,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled:focus-within label {
  color: #545d66;
  opacity: .5;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled>div,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled input,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled:focus-within>div,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled:focus-within input {
  color: #545d66;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled>div:before,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled input:before,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled:focus-within>div:before,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled:focus-within input:before {
  border-color: #e6ecf2;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled>div:after,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled input:after,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled:focus-within>div:after,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled:focus-within input:after {
  display: none;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled>div:hover,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled:focus-within>div:hover {
  border: none;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled>div:hover:before,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry__input--disabled:focus-within>div:hover:before {
  border-bottom: 1px solid #e6ecf2 !important;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .ratio-amount__fields {
  display: flex;
  flex-direction: row;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .amount__field {
  display: flex;
  width: 200%;
  flex-direction: column;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .ratio__field {
  display: flex;
  width: 100%;
  margin-left: 1rem;
  flex-direction: column;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .moleculeName__input {
  padding: 1em 0 2em;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .moleculeName__input p {
  margin: .25em 0;
  opacity: .5;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .batchInformation__field {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .batchInformation__radio {
  padding: .5em 0;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .batchInformation__radio .batchName {
  font-size: 14px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .batchInformation__radio label {
  margin: 0 1em 0 0;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .batchInformation__radio .radio {
  height: 2em;
  width: 1.5em;
  margin-right: .5em;
  font-size: 1em;
  color: #8a8d93;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .batchInformation__radio .radio.Mui-checked {
  color: #1e9af1;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .batchInformation__radio .radio svg {
  height: .75em;
  width: .75em;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .batchInformation__label {
  font-weight: 400;
  font-size: 12px;
  min-height: 14px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .batchInformation__label div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .batchInformation__tooltip {
  font-size: 1.2rem;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .solvent-property__fields {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .solvent-cas__field {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .solvent-boiling-point__field {
  display: flex;
  width: 100%;
  margin-left: 1rem;
  flex-direction: column;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .solvent-density__field {
  display: flex;
  width: 100%;
  margin-left: 1rem;
  flex-direction: column;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .molecule-batch__fields {
  display: flex;
  flex-direction: row;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .molecule-name__field {
  display: flex;
  width: 200%;
  flex-direction: column;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .batch-name__field {
  display: flex;
  width: 100%;
  margin-left: 1rem;
  flex-direction: column;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .stoichiometry-table-controls a {
  margin-right: 1em;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-file {
  margin-bottom: -8px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-file .inline-container__inner-content {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-file .office-icon {
  margin-bottom: 14px;
  top: 7px;
  position: relative;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-file:hover .uploaded-file--hover {
  display: flex;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-file:hover .uploaded-file--no-preview {
  border-color: #004fbe;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-file canvas {
  position: relative !important;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-file__link {
  line-height: 0;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-structure-file--preview {
  border-radius: 2px;
  min-width: 880px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-structure-file--structure {
  padding: 10px;
  border-radius: 2px;
  box-sizing: border-box;
  object-fit: contain;
  min-width: 270px;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-structure-file--structure img,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-structure-file--structure svg {
  width: 858px !important;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-structure-file--structure svg {
  max-height: 246px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-structure-file--structure:hover {
  border: 1px solid #004fbe;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-structure-file--border {
  border: 1px solid #e6ecf2;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-structure-file--warning {
  font-weight: bold;
  display: block;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-structure-file--table {
  margin: 10px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .uploaded-structure-file--table td {
  white-space: nowrap;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .header__row {
  border: none;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .header__row td {
  border: none;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-small {
  position: relative;
  top: 2px;
  margin-top: 8px;
  margin-bottom: -6px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-small .inline-container__pdb-viewer,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-small canvas,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-small iframe {
  height: 248px !important;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-small .inline-container__pdb-viewer,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-small canvas,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-small .uploaded-file--image,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-small iframe {
  width: 248px !important;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-half {
  position: relative;
  top: 2px;
  margin-top: 8px;
  margin-bottom: -6px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-half .inline-container__pdb-viewer,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-half canvas,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-half iframe {
  height: 400px !important;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-half .inline-container__pdb-viewer,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-half canvas,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-half .uploaded-file--image,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-half iframe {
  width: 400px !important;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-large {
  position: relative;
  top: 2px;
  margin-top: 8px;
  margin-bottom: -6px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-large .inline-container__pdb-viewer,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-large canvas,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-large iframe {
  height: 878px !important;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-large .inline-container__pdb-viewer,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-large canvas,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-large .uploaded-file--image,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .size-large iframe {
  width: 878px !important;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 #content {
  padding-bottom: 20px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 #content-inner {
  padding: 32px 25px 25px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .notification-container {
  position: sticky;
}

@media print {
  .wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 #content {
    padding-bottom: 0px;
  }

  .wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .export-pdf-customization {
    display: contents;
  }

  .wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 #content-inner {
    padding: 32px 25px 0px;
  }

  .wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .editor-container div {
    min-height: 0px !important;
  }

  .wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .notification-container {
    position: static;
  }
}

@media print {
  .wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .table-print-wrapper {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    gap: 20px !important;
  }

  .wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .table-print-wrapper table {
    display: table !important;
    page-break-inside: avoid !important;
    break-inside: avoid !important;
  }

  .wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .table-print-wrapper tr {
    display: table-row !important;
  }

  .wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .table-print-wrapper td {
    display: table-cell !important;
  }
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .PrivatePickersYear-root button:not(.Mui-selected):hover,
.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .MuiCalendarPicker-root button:not(.Mui-selected):hover {
  background-color: rgba(0,0,0,.04);
  color: #1262b3;
  text-decoration: underline;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .buttony:not(.ketcher-editor-wrapper .buttony):not(.Ketcher-root .buttony) {
  float: left;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  padding: 10px 15px;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .buttony:not(.ketcher-editor-wrapper .buttony):not(.Ketcher-root .buttony):hover {
  color: #fff;
  text-decoration: none;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .buttony:not(.ketcher-editor-wrapper .buttony):not(.Ketcher-root .buttony).buttony-green {
  background-color: #7ed321;
}

.wrapper-class-274a8213be2ee83beee401aa5c391aab4557dc45 .buttony:not(.ketcher-editor-wrapper .buttony):not(.Ketcher-root .buttony).buttony-green:hover {
  background-color: #71c219;
}