.checklist
  list-style: none
  padding: 0 0 0 pxToRem(20)

  li
    margin: pxToRem(8) 0
    padding: 0
    position: relative
    line-height: pxToRem(16)

    .input-checkbox,
    .input-radio
      position: absolute
      top: pxToRem(1)
      left: -pxToRem(20)

    .input-radio
      margin: 0
      padding: 0
      top: pxToRem(2)
      border: none

.checklist__margin-none
  margin: 0
