@import base/variables
@import base/placeholders
@import base/mixins

// Projects

h2#projects-title
  margin-top: pxToRem(12)
  margin-left: pxToRem(10)

#admin-projects, #admin-user
  .subcontainer
    margin: pxToRem(25) 0
    border-radius: pxToRem(2)
    border: pxToRem(1) solid $gallery !important
    background: $alabaster !important
    .floatRight, .floatLeft
      margin: pxToRem(15) pxToRem(10)
  h3
    display: block
    border-bottom: pxToRem(1) solid $gallery
    background: $alabaster
    margin-bottom: 0
    padding: pxToRem(15) pxToRem(20)
  h4
    margin: pxToRem(18) 0 pxToRem(18) pxToRem(10)
    font-size: pxToRem(13)
    color: $shuttle-gray
  table.dataTable
    margin: 0
    top: 0
    border-top: pxToRem(1) solid $gallery
  .hr
    display: none
    margin: pxToRem(15) 0
  table.dataTable tr:first-child th, table.dataTable tr:first-child td
    background: none

#admin-projects
  .message h3
    padding-left: 0
    padding-top: pxToRem(2)
    border-bottom: none
  h3.collapsible-title
    padding-left: pxToRem(30)
  h3.collapsible-title:hover
    cursor: pointer
    text-decoration: underline
  .collapsible-open
    h3.collapsible-title
      background: url("../images/cdd30/layout/collapsible/open-12.gif") no-repeat pxToRem(10) center
  .collapsible-closed
    h3.collapsible-title
      background: url("../images/cdd30/layout/collapsible/closed-12.gif") no-repeat pxToRem(10) center

.admin-settings
  table
    tbody th
      width: pxToRem(100)
    &.admin-settings-edit-table
      input, textarea
        width: 92%
        padding: pxToRem(8)
    .description,
    #parties_project_data_table_name // need class for name
      @extend %break-word
      line-height: 1.5
  .editableData-editLink-save
    margin: 0 pxToRem(10) pxToRem(15) 0

#admin-user .admin-settings table tbody th
  width: pxToRem(110)

.admin-membership
  table.dataTable
    th, td
      vertical-align: middle
  select
    padding: pxToRem(3)
    background: $white
    border: pxToRem(1) solid $alto
    font-size: pxToRem(12)
    width: 10rem

  .userNameColumn, .admin-membership-userSelect,
  .projectNameColumn, .admin-membership-projectSelect
    width: pxToRem(170)
  .canEditColumn, .canManageColumn
    width: pxToRem(60)
    text-align: center
    white-space: normal
  .readOnlyColumn
    text-align: center
  .editableData-editLink-save
    margin: 0 pxToRem(10) pxToRem(15) 0

#dashboard-vaultLingo-show
  p
    text-align: center
    font-size: pxToRem(15)
    font-weight: bold

// Search Defaults
#dashboard-vaultDisplayDefaults-show
  .editableData-editLink
    margin: 0 0 pxToRem(15)
  th
    width: $sidebar-width

// Batch fields

#batchFields, #elnFields, #elnFieldDefinitions, #formDefinitions, #moleculeFields, #protocolFields, #runFields, #inventoryFields, .customizableFields, .EditFieldDefinitionList
  table
    tr
      height: pxToRem(39)

  .centered
    text-align: center
  .fakeDataTable
    border-bottom: none
    margin-bottom: pxToRem(25)
    .floatRight
      margin-top: pxToRem(25)

    .row
      display: flex
      height: pxToRem(39)
      align-items: center

      &.uneditable
        .inner:nth-of-type(1) span
          padding-left: pxToRem(5)

      .removed_notice
        display: flex
        width: 100%
        justify-content: center

      .inner
        display: inline-block
        position: relative

    .muted
      padding: pxToRem(5) 0 0 pxToRem(10)

#batchFields, #moleculeFields, #protocolFields, #runFields, #inventoryFields
  .fakeDataTable
    font-size: pxToRem(12)
    .row
      .inner
        &.name
          min-width: pxToRem(155)
        &.data-type
          min-width: pxToRem(120)
          select
            width: 100%
          .small-edit-icon
            right: pxToRem(20)
            top: pxToRem(3)
        &.unique, &.dropdown-display
          min-width: pxToRem(100)
        &.overwritable
          min-width: pxToRem(100)
        &.requirement
          min-width: pxToRem(230)
          select
            min-width: pxToRem(230)
            max-width: pxToRem(230)
        &.action
          min-width: pxToRem(50)

#protocolFields, #runFields
  .fakeDataTable
    .row
      .inner
        &.requirement
          padding-left: pxToRem(40)

#elnFieldDefinitions
  .fakeDataTable
    .row

      &.greyBackground .inner
        padding: pxToRem(10) 0 pxToRem(5) pxToRem(10)

      .inner
        &:nth-of-type(1)
          width: 35%
          input
            min-width: 82%
        &:nth-of-type(2)
          width: pxToRem(120)
          select
            width: 100%
          .small-edit-icon
            right: pxToRem(20)
            top: pxToRem(3)
        &:nth-of-type(3)
          width: 15%
        &:nth-of-type(4)
          width: 15%
        &:nth-of-type(5)
          width: 15%
          text-align: right

.drag_handle
  cursor: move
  &.locked_handle
    cursor: default
    color: $catskill-white
