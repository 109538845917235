.popup
  position: relative

.popup-inner1
  position: absolute
  top: 0
  left: 0
  width: $sidebar-width
  box-shadow: 0 pxToRem(2) pxToRem(4) $box-shadow
  z-index: 9999

.popup-inner2
  padding: pxToRem(15) pxToRem(15) pxToRem(1) pxToRem(15)
  background: $white

.popup-close
  position: absolute
  top: pxToRem(14)
  right: pxToRem(14)

.popup.dialog
  .popup-inner1
    width: pxToRem(440)
    top: auto
    left: auto
    bottom: auto
    right: auto
  .popup-inner2
    width: pxToRem(410)
    padding: pxToRem(15)
    .input-text
      width: 97%
    h3
      margin-bottom: pxToRem(10)
