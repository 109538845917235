// New form

#protocol-new .subcontainer th
  width: pxToRem(110)

#protocol-new td
  background: transparent

// File formatting

#protocol_files_deprecation_message
  padding: pxToRem(17) pxToRem(20) pxToRem(17) pxToRem(20)

.new_protocol,
#protocol-definition-edit,
.edit_protocol,
.field_file_uploader
  .file
    .filePreview
      float: left

      img
        max-width: pxToRem(220)

      &.noThumb
        padding: pxToRem(30) 0
        height: pxToRem(20)

    .actions
      float: left

      ul
        list-style: none

  .readoutFileUploader
    display: inline-block
    vertical-align: bottom

    &.qq-upload-button-hover a
      text-decoration: underline
      color: $red

.field_file_uploader
  margin-top: 1rem

#protocol-definition-show
  .filePreview
    text-align: left

// Copy protocol

#duplicateProtocol-dialog
  .popup-inner1
    bottom: 0
    left: 0

// Runs

#protocol-runs .editableData-editLink
  padding-right: pxToRem(10)

#protocol-runs .subcontainer
  margin: 0 0 pxToRem(25) 0

// New run form

#protocol-newRun-edit
  th
    width: pxToRem(120)

// Definition

#protocol-definitionAndFiles
  margin: 0 0 pxToRem(25) 0

  th
    width: pxToRem(110)

  td
    @extend %break-word

#protocol_data_table_name
  @extend %break-word

// Readouts and Calculations

#protocol-readoutsAndCalculations
  margin: 0 0 pxToRem(25) 0

#protocol-readouts-show
  tbody th
    @extend %break-word

  tr.readout-definition-main
    td
      padding-bottom: 0

  tr.readout-definition-sub
    th, td
      border-top: none
      padding-top: 0
      padding-bottom: 0
      height: pxToRem(25)

    th
      font-weight: normal
      font-style: italic
      padding-left: pxToRem(20)

  #protocol-readouts-show-table
    .conditionColumn
      text-align: center

    .descriptionColumn
      @extend %break-word

#protocol-readouts-show-rows
  th
    min-width: 10rem


#protocol-readouts-edit
  .actions
    vertical-align: top

    img.drag_handle
      cursor: move

  .orcancel
    padding-top: pxToRem(6)

  select
    margin-right: pxToRem(1)

  .inlineEditForm
    margin: 0 0 pxToRem(15) 0
    border-bottom: pxToRem(1) solid $gallery
    padding-bottom: pxToRem(10)

    th
      width: pxToRem(110) !important

    td, th
      padding: pxToRem(15) pxToRem(10) !important
      text-align: left !important
      vertical-align: middle !important
      border-top: pxToRem(1) solid $gallery
      background: transparent

      &.normalizations-menu-option, &.normalizations-menu-checkbox
        padding: pxToRem(15) 0 !important

      tr
        &:first-child
          td, th
            padding-top: 0 !important

      tr
        &:last-child
          td, th
            border-bottom: none

    tr
      &:first-child
        td, th
          border-top: none

    tr
      &:last-child
        td, th
          border-bottom: pxToRem(1) solid $gallery

    .hr
      margin: pxToRem(15) 0
      border-bottom-color: $mint-julep

    .subcontainer
      width: auto

      .hr
        border-bottom-color: $mercury

    th
      width: auto

    table.protocol-readouts-edit-normalization
      margin: 0

      th
        width: pxToRem(40)
        text-align: left
        padding-left: 0

    table.protocol-readouts-edit-fitParameters, table.protocol-readouts-edit-minimumActivity
      margin: 0
      width: 100%

      tbody th
        width: pxToRem(10)
        text-align: left
        padding: pxToRem(12) 0 pxToRem(5) 0
        white-space: nowrap

      tbody tr.protocol-readouts-edit-fitParameters-minMaxHill th
        width: auto

      .protocol-readouts-edit-fitParameters-options-option, .protocol-readouts-edit-minimumActivity-options-option
        margin: 0 0 pxToRem(5) 0

      .subcontainer
        margin: pxToRem(10) 0 0 pxToRem(24)
        padding: pxToRem(24)
        width: pxToRem(260)

        table
          width: 100%
          border-collapse: collapse

          td
            padding-left: 0 !important
            padding-right: 0 !important

      select, .input-radio
        vertical-align: bottom

      .calculations-intersection
        width: pxToRem(130)

        span
          padding-right: pxToRem(5)

        input
          width: pxToRem(30)

      .calculations-name
        width: pxToRem(170)

        span
          padding-right: pxToRem(5)

        input
          width: pxToRem(100)

      .calculations-remove
        padding: pxToRem(10)

      table.protocol-readouts-edit-fitParameters-LockedFit, table.protocol-readouts-edit-minimumActivity-customizedMinimumActivity
        th
          text-align: right
          width: pxToRem(20)

        td input
          width: pxToRem(40)

    .custom-calculation-form
      table.wideForm
        textarea, .input-text
          width: pxToRem(376)

    .dataType
      width: pxToRem(116)

    .unitLabel
      width: pxToRem(110)

    .conditionCheckbox
      display: inline
      padding-left: pxToRem(23)

      .input-checkbox
        top: -pxToRem(8)
        margin-left: pxToRem(3)


table.protocol-readouts-edit-hitConditions, table.protocol-readouts-edit-calculations
  margin: 0
  border-collapse: collapse
  width: 100% !important

  thead th
    text-align: left !important
    font-weight: normal !important

  tbody td
    vertical-align: middle !important
    white-space: nowrap !important

  tbody td.actions
    padding-right: 0 !important

  .input-text
    width: pxToRem(30) !important

  .hitColor
    display: inline-block
    margin: 0
    padding: 0
    width: pxToRem(21)
    height: pxToRem(21)
    text-align: center

    input
      margin: pxToRem(4) 0 0 0
      padding: 0
      vertical-align: bottom

table.protocol-readouts-edit-hitConditions
  .associated-model
    select:nth-of-type(1)
      width: 20rem

    .input-text
      text-align: right
      vertical-align: middle
      padding: pxToRem(4) pxToRem(4) pxToRem(5)

table.protocol-readouts-edit-calculations
  .input-text
    width: auto !important

  select
    margin-right: pxToRem(10)

.normalizations-scope
  margin: pxToRem(2) 0 pxToRem(5) 0

.normalizations-options
  margin: pxToRem(10) 0 0 0

table.normalizations-menu
  margin: pxToRem(10) 0 0 0
  width: 100%
  border-collapse: collapse
  border-bottom: pxToRem(1) solid $citrine-white

  tbody
    .normalizations-menu-option, .normalizations-menu-checkbox, .normalizations-menu-info
      padding-top: pxToRem(10)
      padding-bottom: pxToRem(10)
      border-top: pxToRem(1) solid $citrine-white

    .normalizations-menu-option
      width: 90%
      text-align: left
      padding-left: pxToRem(4)

      .details
        margin-top: pxToRem(3)

    .normalizations-menu-checkbox
      width: pxToRem(15)
      padding: pxToRem(6) 0 0 0

    .normalizations-menu-info
      text-align: right
      white-space: nowrap

  .subcontainer
    td, th
      background: $concrete
      padding: 0 0 pxToRem(10) pxToRem(10)

table.normalization-customizations
  margin: pxToRem(10) 0 pxToRem(5) 0

// AutoFormula

#protocol-readouts-edit
  .autocomplete
    li
      padding: pxToRem(4) pxToRem(8)
      height: pxToRem(14)
      cursor: pointer

    div
      float: right
      vertical-align: center
      margin-right: pxToRem(5)
      text-indent: -1rem
      padding-left: 1rem

      &.functionNameNarrow, &.functionName
        float: left

      &.descriptionText
        width: 30%
        text-align: right

#custom_calculation_help .monospace
  font-family: "courier new", monospace
  font-size: 95%


// Control layouts

#protocol-controlLayouts

  .editablePlateLayout
    margin: 0 0 pxToRem(15) 0

  .editableData-editLink-save
    margin: 0

// Model

#protocol-rocPlot
  margin: 0 0 pxToRem(25)

#rocPlot
  height: pxToRem(630)

  .asyncLoading
    padding-top: pxToRem(250)

#rocSVG
  background: $white

  rect
    fill: $concrete

  .axis path, .axis line
    fill: none
    stroke: $white

  #rocPath
    fill: none
    stroke: $steel-blue
    stroke-width: pxToRem(2)

  #rocDiagonal
    fill: none
    stroke: $white
    stroke-width: pxToRem(3)

  .label
    font-size: pxToRem(14)

  .overlay
    fill: none
    pointer-events: all

  .focusCrossHair
    stroke: $guardsman-red
    stroke-width: pxToRem(2)

  .focusBackground
    opacity: .85

#protocol-modelTrainingSet
  margin: 0 0 pxToRem(25)

  .icon-16
    vertical-align: -pxToRem(6)

  th
    width: pxToRem(130)

// Files tab
#protocol-details,
#protocol-files,
#resource-projects

  .subcontainer
    margin: 1rem 0 0

// Index
#protocols
  .molecules_link
    width: 12%

.protocols--name
  @extend %break-word
  font-weight: bold


#sortable_readout_definitions
  margin-bottom: pxToRem(30)

  .draggable_row.drag-panel
    @extend %break-word

    .actions
      margin-left: pxToRem(20)

#protocols-index

  #protocols-count
    text-align: right
    font-weight: bold

  #export-table
    text-align: right
    margin-top: 0.3rem

  th
    border-bottom-color: colorrgba(0, 0, 0, 0.54)

  tbody
    tr:hover
      background: $alabaster

  .plain-text
    color: $shuttle-gray

  .section__header
    padding-bottom: 0 !important
    margin-bottom: 0
    border-bottom: none

    .input-text
      margin-right: pxToRem(10)
      min-width: pxToRem(210)

  .noDataMessage
    margin-top: pxToRem(15)

  .search_results
    display: flex
    flex-direction: column

  .search_results__header
    border-bottom: pxToRem(1) solid $th-border !important
    color: $th !important

    .search_results__cell
      padding: pxToRem(11) pxToRem(8) pxToRem(5)


  .search_results__cell
    display: inline-block
    box-sizing: border-box
    overflow: hidden
    text-overflow: ellipsis
    width: 15%
    padding: pxToRem(11) pxToRem(8) pxToRem(8)
    color: $black_87

    em
      font-weight: 600

    a
      overflow: hidden
      text-overflow: ellipsis

      &:hover
        text-decoration: none

    &[name=identifier]
      width: 11%

    &[name=title]
      width: 32%

    &[name=user_full_name]
      width: 20%

    &[name=updated_at]
      width: 13%

    &[name=created_at]
      width: 13%

    &[name=status]
      text-align: right
      width: 11%

  .search_results__highlight
    margin: 0 0 0 pxToRem(8)
    list-style: none
    font-size: pxToRem(12)
    padding: 0
    opacity: 0.75

    li
      display: inline-block
      margin: 0 pxToRem(8) 0 0
      padding: pxToRem(6) pxToRem(9)

  .search_results__key
    display: block
    font-size: pxToRem(12)
    opacity: 0.5

  .search_results__value

    em
      font-weight: 600

    &:not(:last-of-type)::after
      content: ' ... '

  .search_results__value + .search_results__value:before
    content: ','
    margin-right: 0.25rem


  .search_results__structures
    display: flex
    width: calc(#{$container-width} - 1rem)
    // padding is 0.5rem on both sides
    position: sticky
    left: 0.5rem
    flex-wrap: wrap
    margin-top: -0.5rem

  .search_results__molecule
    margin-right: 0.5rem
    margin-top: 0.5rem

  .search_results__molecule_name
    margin: 0.5rem
    padding: 0.25rem
    position: absolute
    line-height: 1
    background: $white_50
    max-width: 12rem
    border-radius: $border-radius


  // .search_results__structure
  .search_results__molecule_structure
    border: pxToRem(1) solid $border
    box-shadow: none
    background: $white
    height: 14rem
    width: 14rem

  .search_results__reaction_structure
    border: pxToRem(1) solid $border
    box-shadow: none
    background: $white
    height: 14rem
    width: 58rem

  .search_results__molecule_structureless
    border: pxToRem(1) solid $border
    box-shadow: none
    background: $white
    height: 14rem
    width: 14rem

    .eln_entries_thumbnail
      transform: scale(0.75)

    // eln search results table
  .search_results__container
    overflow-x: auto
    transform: rotateX(180deg)

  .search_results__table
    transform: rotateX(180deg)
  // .search_results__header

  .search_results__body

    + .search_results__body
      border-top: pxToRem(1) solid $border

    &:hover
      background: $alabaster

  .search_results__row
    height: unset

  .search_results__row__context

    .search_results__item
      padding-top: 0
      padding-bottom: 0

  .search_results__item
    padding: 0.75rem 0.5rem
    font-size: pxToRem(14) !important
    line-height: 1.5
    min-width: 6rem
    vertical-align: bottom
    border: none

    &:nth-last-of-type(1)
      padding-right: 0.5rem
  // material override

  .search_results__item__sort
    align-items: flex-end

    svg
      display: flex
      margin-bottom: pxToRem(2)

  .search_results__item__title
    min-width: 12rem

  .search_results__item__author
    min-width: 10rem

  .search_results__item__witness
    min-width: 10rem

  .search_results__item__edit
    padding-bottom: 0.25rem
    width: 1.5rem
    // material override
    min-width: unset
    vertical-align: bottom

  .search_results__button
    height: 1.75rem
    width: 1.75rem
    vertical-align: initial

    svg
      font-size: pxToRem(12)
