.announcement
  text-align: center
  background: $chilean-heath
  z-index: 10

  .announcement-inner
    margin: 0 auto
    padding: pxToRem(8) 0
    position: relative
    width: pxToRem(940)
    background: url("../images/cdd30/layout/horizontal-rule-90.gif") repeat-x 0 50%

    .inner
      padding: 0 pxToRem(10)
      background: $chilean-heath

    .close
      float: right
      padding: pxToRem(2) 0 pxToRem(2) pxToRem(5)
      background: $chilean-heath
