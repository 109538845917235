@import Projects/ProjectsPage
@import ../shared/components/SimpleDataTable/SimpleDataTable
@import Teams/components/EditTeamDialog
@import CustomNucleotides/components/EditCustomNucleotideDialog
@import CustomAminoAcids/components/EditCustomAminoAcidDialog

.AccountsView
  .counter
    padding: pxToRem(24)!important
  .add-associated-model, .download-associated-model
    text-align: right
    width: 100%
  .top-row
    padding: 2rem 0 1rem 0
  .simple-filter
    padding: 1rem

.edit-account-object-dialog
  font-size: 1rem !important

  .main-dialog-paper
    min-width: pxToRem(800)

  .stepper-page-container
    min-height: pxToRem(300)

  .add-option-select
    margin-bottom: pxToRem(10)

.EditCustomNucleotideDialog, .EditCustomAminoAcidDialog
  .main-dialog-paper
    min-width: pxToRem(550)
  .structureImageContainer
    width: $sidebar-width
    height: pxToRem(200)
    border: pxToRem(1) solid #EEE
    margin: pxToRem(5)
  .editStructureButton
    padding: pxToRem(5)
    width: pxToRem(283)
    text-align: center
  .structureContainerRow
    text-align: center
  .structure
    padding-left: pxToRem(0)

.no-icon
  visibility: hidden
