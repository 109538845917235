.table__container
  margin: pxToRem(10) 0
  position: relative
  overflow-x: auto

.editor__table
  display: table
  table-layout: fixed
  border-collapse: collapse
  border: pxToRem(1) solid $border

.editor__table--selected
  background: $alice-blue

  ::selection
    background-color: $alice-blue

.table__body
  display: table-row-group

.table__column-group
  display: table-column-group

.table__column
  display: table-column

.table__row
  display: table-row
  min-height: pxToRem(24)

.table__cell
  display: table-cell
  background: inherit
  border: pxToRem(1) solid $border
  padding: pxToRem(10)
  box-sizing: border-box
  overflow-x: hidden
  white-space: pre-wrap

.table__vertical-ruler, .table__vertical-ruler-preview
  position: absolute
  caret-color: transparent
  height: 100%
  top: 0

.table__vertical-ruler
  cursor: col-resize
  transition: opacity 0.3s
  transition-timing-function: ease-in-out
  opacity: 0
  padding: 0 pxToRem(5)
  margin-left: -pxToRem(5)

  &:hover
    opacity: 1

.table__vertical-ruler-preview
  border-left: pxToRem(1) solid $color_brand
  margin-left: pxToRem(5)

.table__vertical-ruler__border
  border-left: pxToRem(1) solid $color_brand
  height: 100%
