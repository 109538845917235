.AutoEllipsisTooltip
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

  .text-contents
    display: inline

.AutoEllipsisTooltip-tooltip:hover
  background-color: $material-bg_hover
  .text-contents
    box-shadow: 0px 0px 0px 8px  $material-bg_hover
