.structure_container
  .data
    th
      display: none // hide the label
    td
      padding: 0 0 pxToRem(5) pxToRem(10)

      textarea
        width: 100% // FULL width for text

#nucleotide_sequence_data:read-only
  color: $silver_dark
  background-color: $alabaster !important

.molecule-batch-identifier > div > div > div
  height: unset
