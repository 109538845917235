.text__wrap,
.collapsible-title
  @extend %break-word

.text__nowrap
  white-space: nowrap

.muted
  color: $dusty-gray

.normal
  font-size: pxToRem(12)

.small
  font-size: pxToRem(11)

.critical
  color: $guardsman-red

.warning
  color: $mango-tango

.padded_warning
  color: $mango-tango
  padding: 0 0 0 pxToRem(10)

.success
  color: $limeade

.unbold
  font-weight: normal

.bold
  font-weight: bold

.monospace
  font-size: 110%
  font-family: "courier new", monospace

.nowrap
  white-space: nowrap

.pre
  white-space: pre

// Generic disabled for text
.disabled
  color: $dusty-gray

// Collapsible

h2.collapsible-title
  padding: 0 0 0 pxToRem(20)
  line-height: 1.1rem

h3.collapsible-title,
h4.collapsible-title,
h5.collapsible-title
  padding: 0 0 0 pxToRem(15)

#changePassword
  h4.collapsible-title
    margin: 0
    padding: 0

.collapsible.disabled
  .collapsible-title
    a
      color: $dusty-gray
      &:hover
        color: $dusty-gray
        cursor: default
        text-decoration: none
      &:active
        color: $dusty-gray

.collapsible-open
  .collapsible-header
    span
      background: url("../images/cdd30/layout/collapsible/collapse.svg") no-repeat
      transition: all 0.3s
      transform: rotate(180deg)
  h2.collapsible-title
    background: url("../images/cdd30/layout/collapsible/open-12.gif") no-repeat 0 pxToRem(3)
  h3.collapsible-title
    background: url("../images/cdd30/layout/collapsible/open-8.gif") no-repeat 0 pxToRem(3)
  h5.collapsible-title
    background: url("../images/cdd30/layout/collapsible/open-8.gif") no-repeat 0 pxToRem(2)
  .collapsible-inner
    display: block
  span.collapsible-inner
    display: inline
  .collapsible-alt
    display: none

.collapsible-closed
  .collapsible-header
    span
      background: url("../images/cdd30/layout/collapsible/collapse.svg") no-repeat
      transition: all 0.3s
  h2.collapsible-title
    background: url("../images/cdd30/layout/collapsible/closed-12.gif") no-repeat 0 pxToRem(2)
  h3.collapsible-title
    background: url("../images/cdd30/layout/collapsible/closed-8.gif") no-repeat 0 pxToRem(2)
  h5.collapsible-title
    background: url("../images/cdd30/layout/collapsible/closed-8.gif") no-repeat 0 pxToRem(1)
  .collapsible-inner
    display: none
  .collapsible-alt
    display: block
