.columns
  display: flex
  justify-content: space-between

.columns__item
  flex-basis: 0
  flex-grow: 1

  + .columns__item
    padding-left: pxToRem(20)

.columns__item__x2
  flex-grow: 2

.columns__item__border
  border-left: pxToRem(1) solid $border
  margin-left: pxToRem(20)
