
.sidebar
  .iconList
    margin-bottom: pxToRem(10)
    border-bottom: pxToRem(1) dotted $gallery

#sidebar-delete
  border-bottom: none

// only seen used within .sidebar
#object-data-container
  margin-top: pxToRem(5)
  opacity: 0.5
  th
    padding-left: 0
    width: pxToRem(65)
  th, td
    padding-top: 0
    padding-bottom: pxToRem(10)
  .input-text
    width: pxToRem(134)

  &:hover
    opacity: 1
