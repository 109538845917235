// hacks

// elements

//Remove arrow in IE
select::-ms-expand
  display: none


// components


// partials

// eln
.eln
  input[type='text']::-ms-clear, input[type='number']::-ms-clear
    width: 1rem
    height: 1rem

// added break-all and display block for firefox
h1 .title
  display: block
