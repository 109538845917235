.flaggable-toggle,
.actionIcon.flaggable-toggle
  display: inline-block
  float: none
  width: pxToRem(12)
  height: pxToRem(12)

a:hover .flaggable-toggle img
  top: -pxToRem(12)

.flagged
  font-style: italic
  color: $dusty-gray
  text-decoration: line-through

  a .flaggable-toggle img
    cursor: pointer
    position: relative
    top: -pxToRem(12)

  a:hover .flaggable-toggle img
    top: 0

// Average value
.withFlagged
  border-bottom: pxToRem(1) dotted $dusty-gray
  font-style: italic

.disabled-flag
  color: $red

.superuser-flag
  color: $japanese-laurel_dark

.deleted-flag
  color: $red
