// eln toolbar

// bold, italic, underline, strikethrough, superscript, subscript
.fn-bold
  font-weight: bold

  &:before
    content: "B"


.fn-italic
  font-style: italic

  &:before
    content: "I"


.fn-underline,
.fn.fn-underline.active
  text-decoration: underline

  &:before
    content: "U"


.fn-strikethrough,
.fn.fn-strikethrough.active
  text-decoration: line-through
  text-decoration-thickness: 0.1rem

  &:before
    content: "S"
