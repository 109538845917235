//	removes the vertical space created by this:
//	<span data-key="13">
//		<span data-offset-key="13:0">
//			<span data-slate-zero-width="true">​
//			</span>
//		</span>
//	</span>



figure.inline-container
  [data-slate-zero-width|=true]
    display: none
