.settings-page__header
  display: flex
  border-bottom: pxToRem(1) solid $header-border
  margin-bottom: pxToRem(20)
  justify-content: space-between
  align-items: center

.settings-page__heading
  margin: unset
  flex-grow: 1

.eln-witnessing__period
  margin-right: pxToRem(10)
  min-width: 4rem

.eln-witnessing__list
  max-height: 20rem
  overflow-y: auto

.settings-page__fields
  display: inline-grid
  margin: pxToRem(30) 0
  grid-template-columns: 1fr
  grid-gap: pxToRem(30)

.settings-page__multi-select
  white-space: normal !important

  ~ svg
    top: unset !important
    bottom: pxToRem(12)

.eln-witnessing__admins,
.eln-witnessing__list
  margin-top: 0.25rem !important
  overflow: hidden
