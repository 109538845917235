.section__header
  margin: 0.5rem 0 1rem
  padding: 0 0 1rem
  border-bottom: pxToRem(1) dashed $border

// We want the bottom of the search form section header to be the same color and style as the other hr's on the page
#mine_search_form
  .section__header
    border-bottom: none
    margin-bottom: pxToRem(0)

  .hr.pre-structure-criteria
    margin-top: pxToRem(0)
