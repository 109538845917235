@import ../../assets/stylesheets/base/_variables.sass

.MixtureEditor-twosides
  display: flex
  width: 100%
  justify-content: space-between
  align-items: baseline

.MixtureEditor-extrabuttons
  display: flex
  justify-content: flex-end
  align-items: baseline

.MixtureEditor-edit-hbox
  display: flex
  width: 100%
  flex-direction: row
  justify-content: flex-start
  align-items: center
  gap: 0.5rem

.MixtureEditor-menubutton
  color: $lochmara
  display: inline-block
  margin-bottom: 0.4rem
  margin-right: 0.5rem

.MixtureEditor-clickable
  cursor: pointer
  &:hover
    background-color: $solitude

.MixtureEditor-unclickable
  color: $loblolly !important

.MixtureEditor-hoveronly
  &:hover
    background-color: $solitude

.MixtureEditor-vertbarrier
  border-left: pxToRem(1) solid $silver
  width: pxToRem(1)
  height: pxToRem(20)
  margin-bottom: 0.4rem

.MixtureEditor-dialogmain
  padding: 0 !important
  overflow: hidden

.MixtureEditor-panel-parent
  position: relative
  outline: none

.MixtureEditor-panel-child
  position: absolute

.MixtureEditor-selectedhalo
  background-color: $azure-radiance
  border-radius: pxToRem(2)
  box-shadow: 0 0 pxToRem(5) $azure-radiance

.MixtureEditor-parsingouter
  position: absolute
  display: flex
  flex-direction: column
  align-items: stretch
  justify-content: space-between

.MixtureEditor-parsinginner
  flex-grow: 0
  border: pxToRem(1) solid black
  border-radius: pxToRem(2)
  background: $white
  overflow-y: auto

.MixtureEditor-parsingresult
  padding: 1rem
  &:hover
    background-color: $anakiwa

.MixtureEditor-parsingresult-even
  background: $alabaster

.MixtureEditor-parsingresult-odd
  background: $concrete

.MixtureEditor-parsingresult-selected
  background-color: $link-water

li.MixtureEditor-parsingresult-clickybatch
  padding: 0
  margin: 0

.MixtureEditor-contextmenu-line
  display: flex
  width: 100%
  gap: 1rem
  justify-content: space-between
  align-items: center

.MixtureEditor-contextmenu-shortcut
  color: $astral

.MixtureEditor-detail-maingrid
  display: grid
  width: max(100%, 50rem)
  gap: 0.5rem
  grid-template-columns: [title] auto [content] 1fr [end]

.MixtureEditor-detail-label
  padding-top: 0.4rem
  font-weight: bold
  white-space: nowrap

.MixtureEditor-detail-input
  width: calc(100% - 0.5rem)
  padding: 0
  font: inherit

.MixtureEditor-detail-textarea
  width: calc(100% - 0.5rem)
  padding: 0.25rem
  color: $shuttle-gray
  font: inherit

  &::placeholder
    color: $loblolly

.MixtureEditor-starthere
  display: flex
  align-items: center
  justify-content: center

.MixtureEditor-namelink
  position: absolute
  display: flex
  align-items: center
  justify-content: center
  white-space: nowrap

.MixtureEditor-synonymselect
  cursor: pointer

.MixtureEditor-parsingresult-unpaddedlist
  margin-bottom: 0

.MixtureEditor-parsingresult-thinbutton
  margin-top: 0
  margin-bottom: 0
  padding-top: 0
  padding-bottom: 0

.MixtureEditor-parsingresult-swapsynbtn
  font-size: 1.5rem
  margin: -0.5rem 0 -0.5rem 0
  padding: 0
  min-width: 0
  line-height: normal

.MixtureEditor-flexkeyval
  display: flex
  width: 100%
  gap: 0.5rem
  justify-content: flex-start
  align-items: baseline
  margin-bottom: 0.3rem

.MixtureEditor-thinradio
  height: 1.2rem

.MixtureEditor-openlink
  float: right
  cursor: pointer
  &:hover
    background-color: $viking

.MixtureEditor-openicon
  height: 16px

.MixtureEditor-nowrap
  white-space: nowrap
