.stepProgress
  float: left
  width: 100%
  height: pxToRem(38)
  border: pxToRem(1) solid $mercury
  background: $alabaster
  overflow: hidden
  .inner
    float: left
  .step
    float: left
    padding: pxToRem(11) 0 0 0
    height: pxToRem(27)
    font-size: pxToRem(13)
    line-height: 1rem
    font-weight: bold
    color: $dusty-gray
    text-align: center
  .active
    color: $mine-shaft
    background: $concrete
  .divider
    float: left
    width: 20px
    height: 38px
    background-repeat: no-repeat
    background-position: top left
  .divider-off-off
    background-image: url("../images/cdd30/layout/stepprogress/divider-off-off.png")
  .divider-off-on
    background-image: url("../images/cdd30/layout/stepprogress/divider-off-on.png")
  .divider-on-off
    background-image: url("../images/cdd30/layout/stepprogress/divider-on-off.png")

.stepProgress-1 .inner,
.stepProgress-2 .inner
  width: pxToRem(938)

.stepProgress-3 .inner,
.stepProgress-4 .inner,
.stepProgress-5 .inner,
.stepProgress-6 .inner
  width: 100%

.stepProgress-1 .step
  width: pxToRem(938)

.stepProgress-2 .step
  width: pxToRem(459)

.stepProgress-3 .step
  width: calc(33% - pxToRem(30))

.stepProgress-4 .step
  width: pxToRem(220)

.stepProgress-5 .step
  width: pxToRem(172)

.stepProgress-6 .step
  width: pxToRem(140)
