// dark mode implementation using CSS filters

$dm-brightness: 1.05
$dm-contrast: .9
$dm-saturation: .9

html.dark-filter
  filter: invert(100%) hue-rotate(180deg) brightness($dm-brightness) contrast($dm-contrast) saturate($dm-saturation)

@mixin revert-dark-filter
  filter: invert(100%) hue-rotate(180deg) brightness(1/$dm-brightness) contrast(1/$dm-contrast) saturate(1/$dm-saturation) !important

.dark-filter
  // undo dark mode transformations on some images and videos
  iframe, video
    @include revert-dark-filter

  img:not([src$=".svg"]):not(.ChemistryImage img)
    @include revert-dark-filter

  svg
    image
      @include revert-dark-filter

  // undo dark mode transformations on uploaded file UI in ELN so we don't alter WOPI images
  .eln
    .uploaded-file
      svg
        @include revert-dark-filter
        background-color: #fff !important

  textarea
    // not clear why this is necessary, but without it the text can't be seen in dark mode
    color: black

