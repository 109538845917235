// Used for new molecule view layout
[component_class="SMILESImage"][width="219"][height="219"]
  display: flex
  align-items: center
  justify-content: center
  &.thumbnail
    min-width: pxToRem(219)
    width: pxToRem(219)
    min-height: pxToRem(219)
    height: pxToRem(219)
  svg
    display: block
    margin: auto

.main__molecule
  // we only want this on the Show page or else the index page also gets this width
  .container-toolbar

    .count
      @extend %max-char
      padding: pxToRem(18) pxToRem(10) pxToRem(18) pxToRem(5)
      max-width: 2.75rem

    .label
      padding: pxToRem(18) 0 pxToRem(18) pxToRem(10)
      text-overflow: initial

// Select data set

#moleculeSwitcher
  margin: pxToRem(5) 0 0 0
  position: relative

#moleculeSwitcher-current
  float: none
  font-size: pxToRem(15)
  padding: 0 0 0 0
  line-height: pxToRem(20)
  .actionIcon
    margin: 0
    padding: 0 0 0 pxToRem(7)
    width: pxToRem(19)
    height: pxToRem(19)

a#moleculeSwitcher-current:hover #moleculeSwitcher-current-title
  text-decoration: underline

a#moleculeSwitcher-current:hover .actionIcon img
  top: -pxToRem(19)

#moleculeSwitcher-current-title
  float: left
  color: $mine-shaft
  white-space: nowrap
  font-weight: normal
  color: inherit

#moleculeSwitcher-select
  float: left
  position: relative
  top: -pxToRem(45)
  font-size: pxToRem(12)
  line-height: 1.1rem
  .popup-inner1
    width: pxToRem(500)
    li
      color: $dusty-gray

// Overview

#molecule-overview
  .contained
    word-wrap: break-word
    width: pxToRem(500)
  .dataTable th
    padding-right: 0
  .main
    width: pxToRem(640)
  .sidebar
    padding: 0

// Properties

#molecule-properties

  .twoColumn
    margin: 0 0 pxToRem(10) 0
    .column1,
    .column2
      width: 46%
  .dataTable tbody th
    width: 50%
    text-align: left

// Definition

#molecule-definition
  tbody th
    @extend %break-word
    width: pxToRem(120)
    text-align: left

#molecule-definition-edit
  #molecule_smiles
    width: pxToRem(550)

.molecule-definition-edit-synonym
  clear: both
  .input-text[type="text"]
    margin: 0 0 pxToRem(5) 0
    float: left
    width: $sidebar-width !important
  .actions
    margin: pxToRem(4) 0 0 pxToRem(10)
    float: left
  .formFieldInvalidInput
    display: none
  .error
    border: pxToRem(1) solid $mercury !important

#molecule_data_table_structure
  h4
    font-weight: normal
    font-size: pxToRem(11)
    a.active
      font-weight: bold
      color: $dusty-gray
    a.active:hover
      text-decoration: none
  input
    width: 80%

// Batches tab
#molecule-batches-table

  .inventory
    padding: pxToRem(10) 0 0
    border-top: pxToRem(1) solid $border

    .new-feature
      background: url("../images/cdd30/layout/new-badge.svg") no-repeat 0 pxToRem(3)
      text-indent: 2.5rem
      margin-left: -2.5rem
      font-size: pxToRem(20)

    .actions
      float: right
      line-height: pxToRem(20)
      .seperator
        padding: 0 pxToRem(3)
        color: $silver

  .batch-name
    font-size: pxToRem(16)
    .eln_entry
      font-weight: normal

  .seperator
    background: none
    height: pxToRem(24)
    &:last-child
      display: none

  .inventory-header
    font-size: pxToRem(16)
    font-weight: bold

  .filePreview
    text-align: left

// Sidebar info
#moleculeSwitcher-link
  padding-top: pxToRem(5)
  text-align: center
  font-size: pxToRem(11)
  color: $dim-gray

// Links
#molecule-linksLink
  .loading
    img
      height: pxToRem(12)
#molecule-links
  .loading
    img
      height: pxToRem(14)
      vertical-align: middle
  .subcontainer
    padding-bottom: pxToRem(24)
    h3
      margin: 0.75rem 0 0 0
    .collapsible-inner
      margin: 0.5rem 0 0 0
  .entries,.batches
    span + span::before
      content: "· "
    span + span.first::before
      content: "  "

// Protocols

#molecule-protocols
  .subcontainer
    padding: pxToRem(15) pxToRem(15) pxToRem(0) pxToRem(15)

// Batches

#molecule-new-batch
  td
    background: transparent

#molecule-newBatch-edit
  margin: 0 0 pxToRem(25) 0
  .simple th
    width: pxToRem(50)
  td
    background: transparent
    &.salt
      vertical-align: middle
      .field_with_errors
        display: inline-block
  #create-new-sample-label
    width: pxToRem(129)

.molecule-batch-edit
  th
    @extend %break-word
    width: pxToRem(120)
    text-align: left
  td
    padding-right: 0
    background: transparent
    &.notEditable
      padding-top: pxToRem(10)
    &.salt
      width: pxToRem(400)
      vertical-align: middle
      .field_with_errors
        display: inline-block
      .details a
        float: right

// Molecule Registration

.subcontainer .registration-structure, .non-registration-structure
  #molecule_smiles
    width: pxToRem(635)
  .details
    margin: pxToRem(5) 0 0 0
    float: right
    white-space: normal
    font-size: pxToRem(11)
    line-height: 1.3rem
    font-weight: normal
    color: $dusty-gray
    position: relative
  .SaltAndSolventEditor_text
    font-size: pxToRem(12)
    text-align: left

.subcontainer .non-registration-structure
  margin-bottom: pxToRem(15)

// Files

#molecule-files
  .subcontainer
    margin: 0 0 pxToRem(25) 0

#molecule_files_deprecation_message
  padding: pxToRem(17) pxToRem(20) pxToRem(17) pxToRem(20)

// New molecule

#molecule-new
  ul
    list-style: none
    padding-left: 0

#molecule-new .subcontainer table th
  width: pxToRem(129)

#create-new-sample-checkbox th
  padding-left: pxToRem(0)
  width: pxToRem(0)

// Discard field-specific error feedback for molecule forms until all
// fields (including batch fields) can display error feedback
#molecule-new
  .formFieldInvalidInput
    display: none
  .error
    border: pxToRem(1) solid $mercury !important

// Alerts

#molecule-alerts
  .preview
    width: $sidebar-width
  .alert
    padding: pxToRem(25) pxToRem(25) pxToRem(25) pxToRem(15)

// Deletion warning

.deleteBatch-dialog
  .popup-inner1
    right: pxToRem(0) !important
    text-align: left
    white-space: normal
    p
      margin-bottom: pxToRem(15)

.delete-molecule-or-batch-message
  span
    color: $color_warning

// single molecule > protocols > expand protocol
.protocol__readouts

  td:nth-of-type(3)
    @extend %break-word

  .filePreview img
    max-width: 100%


#run-newReadout-edit,
.run-readout-edit,
#molecule-newBatch-edit,
.molecule-batch-edit,
#molecule-new-batch,
#molecule-new,
#molecule-definition-edit

  .file
    .filePreview
      float: left

      img
        max-width: pxToRem(220)

      &.noThumb
        padding: pxToRem(30) 0
        height: pxToRem(20)

    .actions
      float: left
      ul
        list-style: none

  .readoutFileUploader
    display: inline-block
    vertical-align: bottom

    &.qq-upload-button-hover a
      text-decoration: underline
      color: $red

#molecule-definition-show
  .filePreview
    text-align: left

.molecule__show

  h2
    margin: 0
    text-transform: capitalize

  .container-toolbar
    margin-bottom: 1rem

  .container-content
    padding: 0

  .subcontainer
    margin: 1rem 0 0

// Amino Acid Sequence Registration helper popup
#valid-amino-acid-codes-table
  margin-top: pxToRem(5)
  margin-bottom: pxToRem(0)
  width: auto
  th, td
    padding: pxToRem(5) pxToRem(15)

// Amino Acid Sequence Overview Properties helper popup
#amino-acid-molecular-weights-table
  margin-top: pxToRem(5)
  margin-bottom: pxToRem(0)
  width: auto
  th, td
    padding: pxToRem(3) pxToRem(13)
