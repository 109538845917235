#balloon
  font-size: 14px
  max-width: 600px
  .muted
    color: $sandal

  .infoPopup p
    margin-top: 2px
    margin-bottom: 2px
    padding-right: 15px

  dl
    dt, dd
      line-height: 1.4em
    dt
      font-weight: bold
      margin-top: 10px

  .flaggable
    margin-right: 10px
