.eln
  width: pxToRem(898)
  margin: 0 auto

.eln header
  margin-top: 0

  .title
    font-size: pxToRem(24) !important
    width: 100% !important

.entry--actions
  display: none

#elnEntryFields
  margin: 0
  width: 103%
  margin-top: pxToRem(20)

  label
    max-width: pxToRem(190)

  > div
    display: block
    margin-top: -pxToRem(10) !important
    margin-right: 2.75% !important
    width: 22.25%

  .eln-fields__pick-list > div,
  #project-field,
  .upload-file-text,
  input
    font-size: pxToRem(12) !important

  .input-container-file a
    padding: pxToRem(2) 0 pxToRem(5)

#elnEntryFields #project-field
  width: calc(100% - pxToRem(7)) !important

#elnEntryFields .eln-fields__pick-list
  width: 100% !important

.editor
  padding: 0
  border: none
  border-radius: 0
  box-shadow: none

.editor__toolbar
  display: none
  page-break-after: avoid

// want to fit on 1 page if we can and there is more in the fields than the body
.editor__body
  min-height: 0

.table__container
  overflow-x: hidden
  page-break-inside: avoid

.table__row
  page-break-inside: avoid

.container--fade, .cancel, #elnEntryFields button, .datepicker img
  display: none !important
