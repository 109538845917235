.ui-datepicker.ui-widget.ui-widget-content
  padding: 0

  .ui-datepicker-calendar
    margin: 0

  .ui-datepicker-prev, .ui-datepicker-next
    cursor: pointer

.ui-datepicker-trigger
  vertical-align: -pxToRem(3)
  border: none
  margin-left: 0.5ex
  cursor: pointer
  width: pxToRem(16)
