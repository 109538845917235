@import ../../../../assets/stylesheets/base/variables

.SimpleDataTable
  .additional-header-row
    text-align: left

  td, th
    font-size: pxToRem(12)!important

  .show-hover
    tr:hover
      background: $bg !important

  .show-hover-gray
    tr:hover
      background: $silver-chalice !important

  .no-hover
    tr:hover
      background: unset !important
    tr
      td
        cursor: default !important

  .read-only-row
    background: unset !important
    td
      cursor: default !important

  tr
    td
      border: none
      cursor: pointer

  .alternate-row-colors
    tr:nth-child(even)
      background-color: $aqua-haze

    tr:nth-child(even):hover
      background-color: adjust-color($bg, $lightness: -5%)

  tr .cell-anchor
    color: inherit
    display: block
    padding: 0
    text-decoration: inherit
